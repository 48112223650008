import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import c from 'classnames';
import './index.scss';
import { Button } from '@material-ui/core';
import { ArrowBack, ArrowForward } from '@material-ui/icons';
import BannerImg from '../assets/bannerImg.svg';
import { getStaticTextAnalytics } from '../constants';
import AuthStore from '../../../common/AuthStore';
import WarningIntroModal from './WarningIntroModal';
import DisclaimerWarning from '../assets/DisclaimerWarning1.svg';
import WarningModal from './WarningModal';

function Intro({ updateTab, defaultYear, showWarningIntroModal, setWarningIntroModal, setWarningModal, warningModal }) {
    const staticTextAnalytics = getStaticTextAnalytics();
    const { cardData, analyticsPageInfo, SITE_TEXT } = staticTextAnalytics;
    const {
        INTRO_BTN_TXT,
        INTRO_HEAD,
        INTRO_TXT,
        GSOD_OHI_STATS,
        GSOD_LEARN_TXT,
        YEAR,
        BM_EXPLORER,
        VIEW_ONLINE,
        LEARN_MORE,
        LEARN_MORE_LINK,
        INTRO_BMX,
    } = SITE_TEXT;
    const [page, setPage] = useState(1);
    const { tabNo, tabName, tabDesc, preview } = analyticsPageInfo.filter(({ tabNo: tab }) => tab === page)[0];
    const changePage = newPage => {
        if (newPage > 0 && newPage <= analyticsPageInfo.length) {
            setPage(newPage);
        }
    };

    const { mainImg } = preview;
    const [gsodLoginCountNum, setGsodLoginCountNum] = useState('1');

    useEffect(() => {
        if (gsodLoginCountNum === '2') {
            AuthStore.gsodloginNumber = gsodLoginCountNum || '1';
        }
    }, [gsodLoginCountNum]);

    const newLoginHere = AuthStore.gsodloginNumber === null;

    const handleWarningModal = () => {
        setWarningModal(true);
    };

    return (
        <>
            <div className="introWrapper">
                <div className="rightScorecardHeader">
                    <div className="scorecardHeading">
                        <div className="mainHeading">{BM_EXPLORER}</div>
                        <div className="introRes nRes">
                            {YEAR} : {defaultYear}
                        </div>
                    </div>
                </div>
                <div className="disclaimerContent">
                    <img src={DisclaimerWarning} alt="" />
                    <div className="note scoreCardNote">
                        <span style={{ marginRight: '1vh' }}>Disclaimer : </span>
                        <div>
                            The median health scores shown are protected Firm IP and should not be published in
                            internal/external publications, nor shared with clients.
                        </div>
                        <div style={{ cursor: 'pointer' }} className="warningContent" onClick={handleWarningModal}>
                            know more
                        </div>
                    </div>
                </div>
                <div className="topContent">
                    <div className="bannerSection">
                        <div className="textContent">
                            <div className="headingText">
                                <h2>{INTRO_HEAD}</h2>
                                <h2>{INTRO_BMX}</h2>
                            </div>
                            <div>{INTRO_TXT}</div>
                        </div>
                        <div className="imageContainer">
                            <img src={BannerImg} alt="" />
                        </div>
                    </div>
                    <div className="benchmarkContent">
                        <h3>{GSOD_OHI_STATS}</h3>
                        <ul className="cardContent">
                            {cardData.map(({ title, heading }) => {
                                return (
                                    <li key={title}>
                                        <div className="description">
                                            <span>{heading}</span>
                                            <div className="ellipsisPro">{title}</div>
                                        </div>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </div>
                <div className="middleContent">
                    <h3>{GSOD_LEARN_TXT}</h3>
                    <div className="contentPagination">
                        <div className="tabDescription">
                            <div className="currentTabName">
                                <div className="pageNo">
                                    <strong>
                                        {page < 10 ? '0' : ''}
                                        {page}
                                    </strong>{' '}
                                    / {analyticsPageInfo.length < 10 ? 0 : ''}
                                    {analyticsPageInfo.length}
                                </div>
                                <div className="tabName">{tabName}</div>
                            </div>
                            <div className="currentTabDesc">
                                <div>{tabDesc}</div>
                                <Button classes={{ root: 'activeButton' }} onClick={() => updateTab(tabNo)}>
                                    {INTRO_BTN_TXT}
                                    <ArrowForward />
                                </Button>
                            </div>
                            <div className="buttonContainer">
                                <div
                                    className={c({ inactive: page === 1 }, 'prev')}
                                    onClick={() => changePage(page - 1)}
                                >
                                    <ArrowBack />
                                </div>
                                <div
                                    className={c({ inactive: page === analyticsPageInfo.length }, 'next')}
                                    onClick={() => changePage(page + 1)}
                                >
                                    <ArrowForward />
                                </div>
                            </div>
                        </div>
                        <div className="tabImage">
                            <img src={mainImg} alt="" />
                        </div>
                    </div>
                </div>
                <div className="bottomCard">
                    <span>
                        {LEARN_MORE}
                        <a className="arrowIcon" href={LEARN_MORE_LINK}>
                            {VIEW_ONLINE}
                            <ArrowForward />
                        </a>
                    </span>
                </div>
                {/* <div className="bottomContent">
                <h3>{MORE_TXT}</h3>
                <ul className="upcomingCards">
                    {upcomingFeatures.map(({ description, imgSrc }) => {
                        return (
                            <li key={description}>
                                <div className="imageContainer">
                                    <img src={imgSrc} alt="" />
                                </div>
                                <div className="description">{description}</div>
                            </li>
                        );
                    })}
                </ul>
            </div> */}
            </div>
            {showWarningIntroModal && newLoginHere ? (
                <WarningIntroModal
                    setWarningIntroModal={setWarningIntroModal}
                    setGsodLoginCountNum={setGsodLoginCountNum}
                />
            ) : null}

            {warningModal ? <WarningModal setWarningModal={setWarningModal} /> : null}
        </>
    );
}

Intro.propTypes = {
    updateTab: PropTypes.func.isRequired,
    defaultYear: PropTypes.number.isRequired,
    showWarningIntroModal: PropTypes.bool.isRequired,
    setWarningIntroModal: PropTypes.func.isRequired,
    // new
    setWarningModal: PropTypes.func.isRequired,
    warningModal: PropTypes.bool.isRequired,
};

export default Intro;
