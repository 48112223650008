import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import SettingsContent from './Settings';
import settings from './reducer';
import settingsSagas from './sagas';

import { getSettings, updateSettings, resetError, getResetSettings } from './actions';
import {
    selectApiCount,
    selectSettingsData,
    selectSettingsFetched,
    getErrorInfo,
    selectResetSettings,
} from './selector';

import { selectApiParams } from '../Diagnose/selector';
import { getDefaultSettings } from '../Login/selector';

const stateToProps = createStructuredSelector({
    apiLoadingCount: selectApiCount(),
    settingsData: selectSettingsData(),
    settingsDataFetched: selectSettingsFetched(),
    apiParams: selectApiParams(),
    errorInfo: getErrorInfo(),
    defaultSettings: getDefaultSettings(),
    resetSettings: selectResetSettings(),
    resetSettingsFetched: selectSettingsFetched(),
});

const dispatchToProps = { getSettings, updateSettings, resetError, getResetSettings };

export { settingsSagas, settings };

export default connect(stateToProps, dispatchToProps)(SettingsContent);
