import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import c from 'classnames';
import { ArrowForward } from '@material-ui/icons';
import ActiveFilter from '../../Diagnose/assets/ActiveFilters1.svg';
import colors from '../../../sass/colors';
import FilterSvg from '../../Diagnose/assets/Filter.svg';
import { getStaticText } from '../../../utils/constants';
import './index.scss';
import BlankPage from '../BlankPage/BlankPage';
import QuickHelp from '../QuickHelp/QuickHelp';
import { getStaticTextAnalytics } from '../constants';
import DisclaimerWarning from '../assets/DisclaimerWarning1.svg';
import WarningModal from '../Intro/WarningModal';
import { matchFilterRegex } from '../../../utils/functions';

function Scorecard({
    toggleFilterView,
    getScorecard,
    outcomeData,
    scorecardDataFetched,
    defaultSettings,
    filterParam,
    renderfilterApplied,
    scorecardError,
    dataAtGlance,
    filtersApplied,
    filters,
    warningModal,
    setWarningModal,
}) {
    const { lang } = defaultSettings;
    const staticTextAnalytics = getStaticTextAnalytics(lang);
    const { ANALYTICS_QUARTILE_COLORS } = staticTextAnalytics;
    const staticText = getStaticText(lang);
    const { SITE_TEXT } = staticText;
    const {
        FILTERS,
        BENCHMARK_HEAD,
        BENCHMARK_NA,
        OUTCOMES_TEXT,
        SCORECARD_PAGE_TEXT,
        SCORECARD_GROUP_TEXTS,
        SCORECARD_GROUP_HEAD,
        NUMBER_CAP_SURVEY_TEXT,
        NUMBER_TEXT,
        BENCHMARK,
        SCORECARD_NOTE,
        DISCLAIMER,
        LEARN_MORE,
        LEARN_MORE_LINK,
        VIEW_ONLINE,
        BOTTOM_DECILE,
        NO_BENCHMARK,
    } = SITE_TEXT;
    const [helpView, helpMode] = useState(false);
    const quartileColors = ANALYTICS_QUARTILE_COLORS;

    useEffect(() => {
        if (!scorecardDataFetched) {
            getScorecard({ filters: filterParam });
        }
        // eslint-disable-next-line
    }, [scorecardDataFetched]);

    const handleWarningModal = () => {
        setWarningModal(true);
    };

    const activePageNo = filtersApplied > 0 ? '2' : '6';

    const selectedItems = filters
        .filter(item => item.filter_id === 'year')
        .flatMap(item => item.filter_items)
        .filter(item => item.isSelected)
        .map(item => item.filter_item_name);

    const scoreCardYearSelected = () => {
        if (selectedItems[0] === '2024') {
            return <div>(n = 682 orgs, n = 2,619,975 respondents)</div>;
        }
        if (selectedItems[0] === '2023') {
            return <div>(n = 768 orgs, n = 2,965,672 respondents)</div>;
        }
    };
    const { records = [] } = outcomeData || {};
    const { total_survey: n_survey = '' } = dataAtGlance;
    const { display_name: displayName = '', score: ovOhiScore = '', quartile: ovQuatile = '' } = records[0] || {};
    const { background: ovBg, color: ovColor, border: ovBorder = '' } = quartileColors[ovQuatile] || {};
    const keys = matchFilterRegex(filterParam);
    const keyCount = keys ? keys.length : 0;

    const getHeading = (dataSet, dataSetIndex) => {
        const { display_name, score = '', quartile = '' } = dataSet;
        const { background, color, border: borderColor = colors.$grey200 } = quartileColors[quartile] || {};

        return (
            <Fragment key={display_name + dataSetIndex}>
                <h4 className={c('ellipsisPro')}>
                    <span style={{ background, color, borderColor }}>{score}</span>
                    {display_name}
                </h4>
            </Fragment>
        );
    };

    const getListItems = (childSet, childInd) => {
        const { display_name, score, score_percentile = '', quartile } = childSet;
        const { background, color, border: borderColor = colors.$grey200 } = quartileColors[quartile] || {};
        const border = quartile === 999;
        return (
            <Fragment key={display_name + childInd}>
                <li
                    style={{
                        background,
                        color,
                        borderColor,
                        borderWidth: quartile === '' || border ? 1 : 2,
                    }}
                    stye={{
                        background: !border
                            ? `linear-gradient(135deg, ${borderColor} 25%, ${background} 25%)`
                            : background,
                        color,
                        borderColor: !border ? 'transparent' : borderColor,
                        borderWidth: quartile === '' || border ? 1 : 2,
                        borderStyle: !border ? 'none' : 'solid',
                    }}
                    className={c('ellipsisPro')}
                >
                    {display_name}
                    <span>{score_percentile || score}</span>
                </li>
            </Fragment>
        );
    };

    return (
        <Fragment>
            <div className="demographicWrapper">
                <Helmet>
                    <title>
                        {BENCHMARK} {SCORECARD_PAGE_TEXT['1']}
                    </title>
                </Helmet>
                <div className="rightScorecardHeader">
                    <div className="scorecardHeading">
                        <div className="mainHeading">{SCORECARD_PAGE_TEXT['2']}</div>
                        <div className="nRes">{`${NUMBER_CAP_SURVEY_TEXT} (${NUMBER_TEXT}) : ${n_survey}`}</div>
                    </div>
                    <ul className="navScoreList">
                        <li onClick={() => toggleFilterView(true)} data-testid="filterButton">
                            <img src={!filtersApplied ? FilterSvg : ActiveFilter} alt="" />{' '}
                            <span className="filter">{FILTERS}</span>
                        </li>
                    </ul>
                </div>
                <div className="benchmarkInfo">
                    {SCORECARD_PAGE_TEXT['3']}
                    <div className="selectOrgsRes">{scoreCardYearSelected()}</div>
                </div>

                <div className="disclaimerContent">
                    <img src={DisclaimerWarning} alt="" />
                    <div className="note scoreCardNote">
                        <span>{DISCLAIMER} : </span>
                        <div>{SCORECARD_NOTE}</div>
                        <div className="warningContent" onClick={handleWarningModal}>
                            Know more
                        </div>
                    </div>
                </div>
                {renderfilterApplied}
                {Object.keys(outcomeData).length ? (
                    <Fragment>
                        <ul className="headContent cardsList">
                            <li
                                style={{
                                    background:
                                        ovQuatile !== 999
                                            ? `linear-gradient(135deg, ${ovBorder} 5%, ${ovBg} 5%)`
                                            : ovBg,
                                    color: ovColor,
                                    borderColor: ovQuatile !== 999 ? 'transparent' : ovBorder,
                                    borderWidth: ovQuatile === '' ? 1 : 2,
                                    borderStyle: 'none',
                                }}
                                className="cardList"
                            >
                                <h3>{ovOhiScore}</h3>
                                <div className="ellipsisPro">{displayName}</div>
                            </li>
                        </ul>
                        <div className="outcomeProfileContent">
                            <h3 className="outcomeText">{OUTCOMES_TEXT}</h3>
                        </div>
                        {SCORECARD_GROUP_TEXTS.map((text, index) => {
                            const obj = index === 0 ? records.slice(0, 4) : records.slice(4, 8);
                            const objToUse = index === 2 ? records.slice(8) : obj;

                            return (
                                <div className="scoreDatacard" key={text + index}>
                                    <h3>
                                        <strong>{SCORECARD_GROUP_HEAD[index]}</strong> {text}
                                    </h3>
                                    <section className="scoreData clearfix">
                                        {objToUse.map((dataSet, dataSetIndex) => {
                                            const { children = [] } = dataSet;
                                            if (!index && !dataSetIndex) return null;
                                            return (
                                                <div className="scoreTile clearfix" key={`scoreData${dataSetIndex}`}>
                                                    {getHeading(dataSet, dataSetIndex)}
                                                    <ul>
                                                        {children.map((childSet, childInd) =>
                                                            getListItems(childSet, childInd)
                                                        )}
                                                    </ul>
                                                </div>
                                            );
                                        })}
                                    </section>
                                </div>
                            );
                        })}
                        <div className="bottomCard">
                            <span>
                                {LEARN_MORE}
                                <a className="arrowIcon" href={LEARN_MORE_LINK}>
                                    {VIEW_ONLINE}
                                    <ArrowForward />
                                </a>
                            </span>
                        </div>
                    </Fragment>
                ) : (
                    <section className="sectionWrapper">
                        <BlankPage error={scorecardError} count={keyCount} />
                    </section>
                )}
                <QuickHelp helpMode={helpMode} helpView={helpView} activePage={activePageNo} />
            </div>

            <ul className="benchmark clearfix fixedScoreCard">
                <li>{BENCHMARK_HEAD} : </li>
                {ANALYTICS_QUARTILE_COLORS.slice()
                    .reverse()
                    .map(({ title, background, border: borderColor }) => {
                        const bottomDecileVar = title === BOTTOM_DECILE;
                        const BenchmarkTitle = title === NO_BENCHMARK;
                        return (
                            <li
                                key={title}
                                style={{ color: background }}
                                className={c({
                                    hide: bottomDecileVar || BenchmarkTitle,
                                })}
                            >
                                <span
                                    className="benchmarkTriangle"
                                    style={{
                                        background,
                                        borderWidth: '13px 13px 0 0',
                                        borderColor: bottomDecileVar
                                            ? `transparent ${borderColor} transparent transparent`
                                            : `${borderColor} transparent transparent transparent`,
                                        borderStyle: 'solid',
                                    }}
                                />
                                <div style={{ color: 'black' }}>{title}</div>
                            </li>
                        );
                    })}
                <li>
                    <span style={{ background: colors.$white, border: '1.5px solid #e6e6e6' }} />
                    {BENCHMARK_NA}
                </li>

                {/* <div className="note scoreCardNote">
                    <span>{NOTE} :</span>
                    <div>{SCORECARD_NOTE}</div>
                </div> */}
            </ul>
            {warningModal ? <WarningModal setWarningModal={setWarningModal} /> : null}
        </Fragment>
    );
}

Scorecard.propTypes = {
    toggleFilterView: PropTypes.bool.isRequired,
    getScorecard: PropTypes.func.isRequired,
    outcomeData: PropTypes.object.isRequired,
    scorecardDataFetched: PropTypes.bool.isRequired,
    defaultSettings: PropTypes.object.isRequired,
    filterParam: PropTypes.string.isRequired,
    renderfilterApplied: PropTypes.string.isRequired,
    scorecardError: PropTypes.array.isRequired,
    dataAtGlance: PropTypes.object.isRequired,
    filtersApplied: PropTypes.bool.isRequired,
    filters: PropTypes.array.isRequired,
    // new
    warningModal: PropTypes.bool.isRequired,
    setWarningModal: PropTypes.func.isRequired,
};

export default Scorecard;
