import React from 'react';
import PropTypes from 'prop-types';
import XLSX from 'xlsx-color';
import { assignGraphColor, assignGraphColorOhi4 } from '../../../utils/functions';
import { getStaticText } from '../../../utils/constants';

const Excel = ({ res_BU, cors, defaultSettings }) => {
    const { Nsize = {}, Header = {} } = res_BU;
    const { survey_version, lang } = defaultSettings;
    const isOhi4 = survey_version === '4' || survey_version === '3_2';
    const nObj = {};
    const staticText = getStaticText(lang);
    const { SITE_TEXT } = staticText;
    const { N_SIZE_MAIN } = SITE_TEXT || {};
    Object.entries(Header).forEach(([key, val]) => {
        nObj[val] = Nsize[key];
    });

    return (
        <table className="cohesiontable" id="cohesiontable">
            <thead>
                <tr>
                    <th />
                    <th>header$$${N_SIZE_MAIN}</th>
                    {Object.keys(cors).map((key, i) => (
                        <td key={`header_${i}`}>header$$${key}</td>
                    ))}
                </tr>
            </thead>
            <tbody>
                {Object.keys(cors).map((key, i) => (
                    <tr key={`row_${i}`}>
                        <td key={`practice_${i}`}>bold$$${key}</td>
                        <td key={`practice_n_${i}`}>header$$${nObj[key]}</td>
                        {Object.keys(cors[key]).map((subkey, subindex) => {
                            const score = cors[key][subkey];
                            const { lineGraphColor, textColor } = !isOhi4
                                ? assignGraphColor(score)
                                : assignGraphColorOhi4(score);
                            return (
                                <td key={`collumn_${subindex}`}>{`#ff${lineGraphColor.replace(
                                    '#',
                                    ''
                                )}$$$${score}$$$#ff${textColor.replace('#', '')}`}</td>
                            );
                        })}
                    </tr>
                ))}
            </tbody>
        </table>
    );
};
export const getExport = () => {
    const table = document.getElementById('cohesiontable');
    if (table) {
        const wb = XLSX.utils.table_to_book(table, { sheet: 'Report' });
        Object.keys(wb.Sheets.Report).forEach(val => {
            if (typeof wb.Sheets.Report[val] === 'object') {
                let split = [];
                if (wb.Sheets.Report[val].v.toString().includes('$$$')) {
                    split = wb.Sheets.Report[val].v.split('$$$');
                    const { length, [length - (length - 1)]: last } = split;
                    wb.Sheets.Report[val].v = last;
                } else {
                    /* split = [wb.Sheets.Report[val].v];
                    wb.Sheets.Report[val].v = split[0]; */
                }
                if (split.length > 0) {
                    let align = 'left';
                    if (split.length === 3) {
                        align = 'center';
                    } else {
                        align = split[0] === 'bold' ? 'left' : 'center';
                    }
                    wb.Sheets.Report[val].s = {
                        font: {
                            color: { rgb: split.length > 2 ? split[2].replace('#', '') : 'ff000000' },
                            bold: split[0] === 'bold' || split[0] === 'header',
                            sz: split[0] === 'bold' ? 14 : 12,
                        },
                        alignment: {
                            vertical: align,
                            horizontal: align,
                        },
                        border: {
                            top: { style: 'thin', color: { auto: 1 } },
                            right: { style: 'thin', color: { auto: 1 } },
                            bottom: { style: 'thin', color: { auto: 1 } },
                            left: { style: 'thin', color: { auto: 1 } },
                        },
                        fill: {
                            patternType: 'solid',
                            fgColor: { rgb: split.length > 2 ? split[0].replace('#', '') : 'ffffffff' },
                        },
                    };
                }
            }
        });
        let today = new Date();
        let dd = today.getDate();
        let mm = today.getMonth() + 1;
        const yyyy = today.getFullYear();
        if (dd < 10) {
            dd = `0${dd}`;
        }
        if (mm < 10) {
            mm = `0${mm}`;
        }
        today = `${mm}_${dd}_${yyyy}`;
        return XLSX.writeFile(wb, `cohesion_${today}.xlsx`);
    }
    return null;
};
Excel.propTypes = {
    res_BU: PropTypes.object.isRequired,
    cors: PropTypes.object.isRequired,
    defaultSettings: PropTypes.object.isRequired,
};
export default Excel;
