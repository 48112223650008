import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import c from 'classnames';
import { ArrowBack } from '@material-ui/icons';
import { Helmet } from 'react-helmet';

import AuthStore from '../../../common/AuthStore';
import ActiveFilters1 from '../assets/ActiveFilters1.svg';
import FilterSvg from '../assets/Filter.svg';
import LeftBuActive from '../assets/LeftBuActive.svg';
import RightBu from '../assets/RightBu.svg';
import DownloadIcon from '../assets/Download.svg';
import { getStaticText } from '../../../utils/constants';
import { getBenchmarkName, getPptValues } from '../../../utils/functions';
import colors from '../../../sass/colors';
import ErrorToast from '../../../components/InformationToast/ErrorToast';

function BUDetailedReport({
    setReport,
    scoreData,
    errorInfo,
    updateFilterView,
    activeResetFilter,
    getPptData,
    benchmarks,
    defaultSettings,
    isPercentile,
    appliedFiltersInfo,
    localPractice,
}) {
    const {
        quartileColors,
        report_name = '',
        report_name_or_client_name: rName = '',
        template_name: template_file_name,
        lang,
        survey_version,
        report_type = '',
        report_id = '',
    } = defaultSettings;
    const staticText = getStaticText(lang);
    const { SITE_TEXT, ING_REPORT_ID } = staticText;
    const isOhi4 = survey_version === '4' || survey_version === '3_2';
    const { $white, $black, $siteTextColor } = colors;
    const {
        BU_HEAD,
        SCORECARD,
        FILTERS,
        BENCHMARK_HEAD,
        // COMPANY_RESULTS_TEXT,
        // BU_RESULTS,
        DIFF_HEAD,
        DOWNLOAD,
        PPT_RES_TEXT,
        PPT_RES_TEXT2,
        PPT_RES_TEXT3,
        BU_HEAD_TITLE,
        SOURCE_TEXT,
        NUMBER_TEXT,
        NUMBER_SURVEY_TEXT,
        ALL_PPT_TEXT,
        SIGNIFICANCE,
        PPT_VALUES,
        RES_PPT_TEXT,
        SIGNIFICANCES,
        HEALTH_BU_DETAILED_BOARD,
        PERCENT_FAVORABLE,
        PERCENTILES,
        COMPARISON_GROUP,
        MAIN_GROUP,
        BOTTOM_DECILE,
        NO_BENCHMARK,
    } = SITE_TEXT || {};
    const { pptAccessToken = '', buName = '' } = AuthStore;
    const { records = {}, sizes = {} } = scoreData;
    const { nSize = [0], n_respondents, n_survey } = sizes;
    const { ohi_score = {}, ...dataToIterate } = records;
    const {
        display_name: ohiName = '',
        score: ohiScore = [],
        difference: ohiDiff = [],
        quartile: ohiQuartile = [],
        significance: ohiSign = [],
    } = ohi_score;
    const headerContent = [...ohiScore, ohiDiff[1]];
    const surveyV = survey_version === '4' || survey_version === '3_2';
    const isIngClient = String(report_id) === ING_REPORT_ID;
    const getPpt = (outcomeArray = [], prtArray = []) => {
        const output = {
            output_file_name: 'data.pptx',
            template_file_name,
            slides: [
                {
                    identify_shape_name: 'Checkme',
                    identify_shape_value: 'Cpeerbenchmark_bu',
                    data: outcomeArray,
                },
                {
                    identify_shape_name: 'Checkme',
                    identify_shape_value: 'Cpeerbenchmarkpractice_BU',
                    data: prtArray,
                },
            ],
        };
        getPptData(output, pptAccessToken, `${report_name} BU Profile`);
    };
    const practiceBuName = AuthStore.practiceBU || COMPARISON_GROUP;
    const practiceReportName = AuthStore.practiceReportBU || MAIN_GROUP;

    const getDataForPpt = () => {
        const label = getBenchmarkName(benchmarks);
        const { GLOBAL_VALS } = PPT_VALUES();
        const footNote = isIngClient ? [{ name: 'footnote', value: localPractice }] : [];
        const pptArray = [
            {
                name: 'source',
                value: `${SOURCE_TEXT}: ${rName} (${NUMBER_TEXT}=${nSize[0]}); ${BENCHMARK_HEAD}: ${label} (${NUMBER_TEXT}=${n_respondents}, ${NUMBER_SURVEY_TEXT}=${n_survey})`,
            },
            {
                name: 'Checkme',
                is_delete: 'true',
            },
            {
                name: 'report_title',
                value: `${rName} ${RES_PPT_TEXT}`,
            },
            {
                name: 'comparison_title',
                value: buName,
            },
            {
                name: 'difference_title',
                value: `${ALL_PPT_TEXT[6]} ${buName}`,
            },
            {
                name: '4. Footnote',
                value: ALL_PPT_TEXT[11],
            },
            ...footNote,
            ...getPptValues(GLOBAL_VALS),
        ];

        const outcomeArray = [
            ...pptArray,
            {
                name: 'Title_text',
                value: `${rName} ${ALL_PPT_TEXT[4]} ${buName}`,
            },
            {
                name: '3. Subtitle',
                value: isPercentile ? PPT_RES_TEXT3 : PPT_RES_TEXT,
            },
        ];
        const prtArray = [
            ...pptArray,
            {
                name: 'Title_text',
                value: `${rName} ${ALL_PPT_TEXT[5]} ${buName}`,
            },
            {
                name: '3. Subtitle',
                value: isPercentile ? PPT_RES_TEXT3 : PPT_RES_TEXT2,
            },
        ];

        const { background: background0 = '', border: border0 = '' } = quartileColors[ohiQuartile[0]] || {};
        const { background: background1 = '', border: border1 = '' } = quartileColors[ohiQuartile[1]] || {};
        const parentChart = [
            {
                row_index: 1,
                column_index: 1,
                index: 0,
                value: ohiScore[0],
                bgcolor: ohiQuartile[0] === '' ? $white : background0,
                border_color: ohiQuartile[0] === '' ? $white : border0,
            },
            {
                row_index: 1,
                column_index: 0,
                index: 0,
                value: ohiName,
                cat: ohiName,
            },
        ];
        const diffParentChart1 = [
            {
                row_index: 1,
                column_index: 0,
                index: 0,
                value: ohiScore[1],
                bgcolor: ohiQuartile[1] === '' ? $white : background1,
                border_color: ohiQuartile[1] === '' ? $white : border1,
            },
        ];
        const diffParentChart2 = [
            {
                row_index: 1,
                column_index: 0,
                index: 0,
                value: ohiDiff[1],
                bgcolor: ohiSign[1] === '' ? $white : SIGNIFICANCE[ohiSign[1]].bg,
                border_color: ohiSign[1] === '' ? $white : SIGNIFICANCE[ohiSign[1]].bg,
            },
        ];

        const table_data = [];
        const childChart = [];
        const diffChildChart1 = [];
        const diffChildChart2 = [];
        let indexInArr = 0;

        Object.keys(dataToIterate).forEach((innerContent, innerContentIndex) => {
            const { display_name, score, children = {}, quartile, difference = [], significance } = dataToIterate[
                innerContent
            ];
            const { background: bg0 = '', border: br0 = '' } = quartileColors[quartile[0]] || {};
            const { background: bg1 = '', border: br1 = '' } = quartileColors[quartile[1]] || {};
            const index = innerContentIndex + 1;
            parentChart.push(
                {
                    row_index: index + 1,
                    column_index: 1,
                    index,
                    value: score[0],
                    bgcolor: quartile[0] === '' ? $white : bg0,
                    border_color: quartile[0] === '' ? $white : br0,
                },
                {
                    row_index: index + 1,
                    column_index: 0,
                    index,
                    value: display_name,
                    cat: display_name,
                }
            );
            table_data.push({
                column_index: 0,
                row_index: innerContentIndex,
                value: display_name,
            });
            diffParentChart1.push({
                row_index: index + 1,
                column_index: 0,
                index,
                value: score[1],
                bgcolor: quartile[1] === '' ? $white : bg1,
                border_color: quartile[1] === '' ? $white : br1,
            });
            const { bg = $white } = surveyV
                ? SIGNIFICANCES[significance[1]] || {}
                : SIGNIFICANCE[significance[1]] || {};
            diffParentChart2.push({
                row_index: index + 1,
                column_index: 0,
                index,
                value: difference[1],
                bgcolor: bg,
                border_color: bg,
            });

            Object.keys(children).forEach(childVal => {
                const {
                    display_name: cDisplayName,
                    score: childScoreArr,
                    quartile: childQT,
                    difference: childDiff = [],
                    significance: childSign,
                } = children[childVal];

                const { background: bgC = $white, border: bdC = $white } = quartileColors[childQT[0]] || {};
                const { background: bgC1 = $white, border: bdC2 = $white } = quartileColors[childQT[1]] || {};

                childChart.push(
                    {
                        row_index: indexInArr + 1,
                        column_index: 1,
                        index: indexInArr,
                        value: childScoreArr[0],
                        bgcolor: bgC,
                        border_color: bdC,
                    },
                    {
                        row_index: indexInArr + 1,
                        column_index: 0,
                        index: indexInArr,
                        value: cDisplayName,
                        cat: cDisplayName,
                    }
                );
                diffChildChart1.push({
                    row_index: indexInArr + 1,
                    column_index: 0,
                    index: indexInArr,
                    value: childScoreArr[1],
                    bgcolor: bgC1,
                    border_color: bdC2,
                });
                const { bg: cbg = $white } = surveyV
                    ? SIGNIFICANCES[childSign[1]] || {}
                    : SIGNIFICANCE[childSign[1]] || {};
                diffChildChart2.push({
                    row_index: indexInArr + 1,
                    column_index: 0,
                    index: indexInArr,
                    value: childDiff[1],
                    bgcolor: cbg,
                    border_color: cbg,
                });
                indexInArr += 1;
            });
        });

        outcomeArray.push(
            { name: 'outcomes_chart', chart_data: parentChart },
            { name: 'benchmark1_chart', chart_data: diffParentChart1 },
            { name: 'benchmark2_chart', chart_data: diffParentChart2 }
        );
        prtArray.push(
            { name: 'outcomes_chart', chart_data: childChart },
            { name: 'benchmark1_chart', chart_data: diffChildChart1 },
            { name: 'benchmark2_chart', chart_data: diffChildChart2 },
            { name: 'TableHeader', is_delete: false, table_data }
        );
        getPpt(outcomeArray, prtArray);
    };

    return (
        <>
            <div className="scoreBoard reSurveyReportBoard buDetailBoard clearfix" data-testid="buDetailBoardContent">
                <Helmet>
                    <title>{HEALTH_BU_DETAILED_BOARD}</title>
                </Helmet>
                <div className="breadCrumb">
                    <span data-testid="setScoreFromBuDetailBtn" onClick={() => setReport(0)}>
                        {SCORECARD}
                    </span>{' '}
                    / {BU_HEAD}
                </div>
                <h2 className="clearfix">
                    <span data-testid="setBackFromBuDetailBtn" onClick={() => setReport(0)}>
                        <ArrowBack />
                        {BU_HEAD}
                    </span>
                    <ul className="rightSideNav">
                        <li>
                            <span className="active">
                                <img src={LeftBuActive} alt="" />
                            </span>
                            <span data-testid="setBuNormalReportBtn" onClick={() => setReport(3)}>
                                <img src={RightBu} alt="" />
                            </span>
                        </li>
                        {!errorInfo &&
                            (!isOhi4 ? (
                                <li data-testid="getPptButton" onClick={getDataForPpt}>
                                    <img src={DownloadIcon} alt="" /> {DOWNLOAD}
                                </li>
                            ) : null)}
                        <li data-testid="buDetailedFilterBtn" onClick={() => updateFilterView(true)}>
                            <img src={activeResetFilter ? ActiveFilters1 : FilterSvg} alt="" />
                            {FILTERS}
                        </li>
                    </ul>
                </h2>
                {errorInfo && <ErrorToast message={errorInfo} />}
                {Object.keys(scoreData).length ? (
                    <Fragment>
                        {appliedFiltersInfo()}
                        <ul className="headContent buHead">
                            {headerContent.map((headData, indexHead) => {
                                const { background = $white, color = $siteTextColor, borderColor = $white } =
                                    quartileColors[ohiQuartile[indexHead]] || {};
                                const { bg: secondBg = $white } = surveyV
                                    ? SIGNIFICANCES[ohiSign[1]] || {}
                                    : SIGNIFICANCE[ohiSign[1]] || {};
                                const headBg = indexHead === 2 ? secondBg : 'none';

                                return (
                                    <li
                                        style={{
                                            background,
                                            color,
                                            borderColor,
                                        }}
                                        key={indexHead}
                                    >
                                        <h3 style={{ background: headBg }}>{headData}</h3>
                                        {BU_HEAD_TITLE[indexHead]}
                                    </li>
                                );
                            })}
                        </ul>
                        <div className="detailReportHeader clearfix">
                            <div>{practiceReportName}</div>
                            <div>{practiceBuName}</div>
                            <div>{DIFF_HEAD}</div>
                        </div>
                        <div className="detailReport clearfix">
                            {Object.keys(dataToIterate).map(dataSet => {
                                const {
                                    display_name = '',
                                    score = [],
                                    children = {},
                                    quartile = [],
                                    difference = [],
                                    significance = [],
                                } = dataToIterate[dataSet];
                                const { bg = $white, borderWidth = 0 } = surveyV
                                    ? SIGNIFICANCES[significance[1]] || {}
                                    : SIGNIFICANCE[significance[1]] || {};
                                return (
                                    <section key={dataSet} className="reportTable clearfix">
                                        <div className="reportRow clearfix">
                                            <h4 className="ellipsisPro">{display_name}</h4>
                                            {score.map((scoreCont, indexScore) => {
                                                const { background = $white, border: borderColor = $white } =
                                                    quartileColors[quartile[indexScore]] || {};
                                                return (
                                                    <div className="scoreContent" key={indexScore}>
                                                        <div className="rank">
                                                            <div
                                                                className="textBorder"
                                                                style={{
                                                                    background,
                                                                    borderColor,
                                                                    borderWidth:
                                                                        quartile[indexScore] !== '' ||
                                                                        quartile[indexScore] === 999
                                                                            ? 2
                                                                            : 0,
                                                                    width: `${Math.abs(scoreCont)}%`,
                                                                }}
                                                            >
                                                                <div className="textRank">{scoreCont}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                            <div className="rankContent">
                                                <div className={c('rank', { negative: difference[1] < 0 })}>
                                                    <div
                                                        className="textBorder"
                                                        style={{
                                                            borderWidth,
                                                            background: bg,
                                                            width: `${Math.abs(difference[1])}%`,
                                                        }}
                                                    >
                                                        <div className="textRank">{difference[1]}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {Object.keys(children).map(childSet => {
                                            const {
                                                display_name: childName = '',
                                                score: childScore = [],
                                                quartile: childQuat = [],
                                                difference: childDiff = [],
                                                significance: childSign = [],
                                            } = children[childSet];
                                            const { bg: cbg = $white, borderWidth: cbw = 0 } = surveyV
                                                ? SIGNIFICANCES[childSign[1]] || {}
                                                : SIGNIFICANCE[childSign[1]] || {};
                                            return (
                                                <div key={childName} className="reportRow clearfix">
                                                    <h5 className="ellipsisPro">{childName}</h5>
                                                    {childScore.map((childScData, cSIn) => {
                                                        const { background: bgCh = $white, border: bdCh = $black } =
                                                            quartileColors[childQuat[cSIn]] || {};
                                                        return (
                                                            <div className="scoreContent" key={cSIn}>
                                                                <div className="rank">
                                                                    <div
                                                                        className="textBorder"
                                                                        style={{
                                                                            background: bgCh,
                                                                            borderColor: bdCh,
                                                                            borderWidth: childQuat[cSIn] !== '' ? 2 : 0,
                                                                            width: `${Math.abs(childScData)}%`,
                                                                        }}
                                                                    >
                                                                        <div className="textRank">{childScData}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                                    <div className="rankContent">
                                                        <div className={c('rank', { negative: childDiff[1] < 0 })}>
                                                            <div
                                                                className="textBorder"
                                                                style={{
                                                                    background: cbg,
                                                                    borderWidth: cbw,
                                                                    width: `${Math.abs(childDiff[1])}%`,
                                                                }}
                                                            >
                                                                <div className="textRank">{childDiff[1]}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </section>
                                );
                            })}
                        </div>
                        {isIngClient ? (
                            <div>
                                <div>{localPractice}</div>
                            </div>
                        ) : null}
                        {/* <ul className="benchmark clearfix fixedScoreCard">
                        <li>{BENCHMARK_HEAD} : </li>
                        {quartileColors.map(({ title, background, border: borderColor }) => (
                            <li key={title} style={{ color: background }}>
                                <span style={{ background, borderColor }} />
                                {title}
                            </li>
                        ))}
                    </ul> */}

                        {surveyV && (
                            <div>
                                {report_type === 'percent favorable' && (
                                    <div>
                                        <b>Note : </b> {PERCENT_FAVORABLE}
                                    </div>
                                )}
                                {report_type === 'percentile' && (
                                    <div>
                                        <b>Note : </b> {PERCENTILES}
                                    </div>
                                )}
                            </div>
                        )}
                    </Fragment>
                ) : null}
            </div>

            {surveyV ? (
                <ul className="fixedScoreCardOhi">
                    <div className="benchmark clearfix">
                        <li>{BENCHMARK_HEAD} : </li>
                        {quartileColors
                            .slice()
                            .reverse()
                            .map(({ title, background, border: borderColor }) => {
                                const bottomDecileVar = title === BOTTOM_DECILE;
                                const BenchmarkTitle = title === NO_BENCHMARK;
                                return (
                                    <li
                                        key={title}
                                        className={c({
                                            hide: bottomDecileVar || BenchmarkTitle,
                                        })}
                                    >
                                        <span
                                            className="benchmarkTriangle"
                                            style={{
                                                background,
                                                borderWidth: '13px 13px 0 0',
                                                borderColor: bottomDecileVar
                                                    ? `transparent ${borderColor} transparent transparent`
                                                    : `${borderColor} transparent transparent transparent`,
                                                borderStyle: 'solid',
                                            }}
                                        />
                                        <div style={{ color: 'black' }}>{title}</div>
                                    </li>
                                );
                            })}
                    </div>
                    <div className="bottomDetailContent">
                        <ul className="bottomList clearfix">
                            <li>{DIFF_HEAD} : </li>
                            {SIGNIFICANCES.map(({ title, bg: background }) => (
                                <li key={title}>
                                    <span style={{ background }} />
                                    {title}
                                </li>
                            ))}
                        </ul>
                    </div>
                </ul>
            ) : (
                <ul className="fixedScoreCardOhi">
                    <div className="benchmark clearfix">
                        <li>{BENCHMARK_HEAD} : </li>
                        {quartileColors.map(({ title, background, border: borderColor }) => (
                            <li key={title} style={{ color: background }}>
                                <span style={{ background, borderColor }} />
                                {title}
                            </li>
                        ))}
                    </div>
                    <div className="bottomDetailContent">
                        <ul className="bottomList clearfix">
                            <li>{DIFF_HEAD} : </li>
                            {SIGNIFICANCES.map(({ title, bg: background }) => (
                                <li key={title}>
                                    <span style={{ background }} />
                                    {title}
                                </li>
                            ))}
                        </ul>
                    </div>
                </ul>
            )}
        </>
    );
}

BUDetailedReport.propTypes = {
    errorInfo: PropTypes.string.isRequired,
    isPercentile: PropTypes.bool.isRequired,
    scoreData: PropTypes.object.isRequired,
    defaultSettings: PropTypes.object.isRequired,
    benchmarks: PropTypes.array.isRequired,
    setReport: PropTypes.func.isRequired,
    activeResetFilter: PropTypes.bool.isRequired,
    updateFilterView: PropTypes.func.isRequired,
    getPptData: PropTypes.func.isRequired,
    appliedFiltersInfo: PropTypes.func.isRequired,
    localPractice: PropTypes.string.isRequired,
};

export default BUDetailedReport;
