import { fromJS } from 'immutable';
import { GET_SETTINGS, UPDATE_SETTINGS, RESET_ERROR, CHANGE_USER_VIEW, GET_RESET_SETTINGS } from './constants';
import { REMOVE_SAVED_TOKEN, GET_DEFAULT_SETTINGS } from '../Login/constants';

export const INITIAL_STATE = fromJS({
    count: 0,
    error: '',
    settings: fromJS({}),
    settingsFetched: false,
    resetSettings: fromJS({}),
    resetSettingsFetched: false,
});

const reducer = (state = INITIAL_STATE, { type = '', payload = {} }) => {
    switch (type) {
        case `${CHANGE_USER_VIEW}_PENDING`:
        case `${UPDATE_SETTINGS}_PENDING`:
        case `${GET_SETTINGS}_PENDING`: {
            return state.set('error', '').update('count', count => count + 1);
        }
        case `${GET_SETTINGS}_SUCCESS`: {
            return state
                .update('count', count => count - 1)
                .set('settings', fromJS(payload))
                .set('settingsFetched', true);
        }
        case `${GET_SETTINGS}_FAILED`: {
            return state
                .update('count', count => count - 1)
                .set('error', payload.error)
                .set('settings', fromJS({}))
                .set('settingsFetched', true);
        }

        case `${GET_RESET_SETTINGS}_PENDING`: {
            return state.set('error', '').update('count', count => count + 1);
        }
        case `${GET_RESET_SETTINGS}_SUCCESS`: {
            return state
                .update('count', count => count - 1)
                .set('resetSettings', fromJS(payload))
                .set('resetSettingsFetched', true);
        }
        case `${GET_RESET_SETTINGS}_FAILED`: {
            return state
                .update('count', count => count - 1)
                .set('error', payload.error)
                .set('resetSettings', fromJS({}))
                .set('resetSettingsFetched', true);
        }
        case `${UPDATE_SETTINGS}_SUCCESS`: {
            return state.update('count', count => count - 1).set('settingsFetched', false);
        }
        case `${UPDATE_SETTINGS}_FAILED`: {
            return state.update('count', count => count - 1).set('error', payload.message);
        }
        case `${CHANGE_USER_VIEW}_SUCCESS`: {
            return state.set('error', '');
        }
        case `${CHANGE_USER_VIEW}_FAILED`: {
            const { error = '', message = '' } = payload;
            return state.update('count', count => count - 1).set('error', error || message);
        }
        case RESET_ERROR: {
            return state.set('error', '');
        }
        case REMOVE_SAVED_TOKEN: {
            if (!payload) {
                return INITIAL_STATE;
            }
            return state;
        }
        case `${GET_DEFAULT_SETTINGS}_SUCCESS`: {
            return INITIAL_STATE;
        }
        default:
            return state;
    }
};

export default reducer;
