export const GET_SETTINGS = 'GET_SETTINGS';
export const UPDATE_SETTINGS = 'UPDATE_SETTINGS';
export const CHANGE_USER_VIEW = 'CHANGE_USER_VIEW';
export const RESET_ERROR = 'RESET_ERROR';
export const GET_RESET_SETTINGS = 'GET_RESET_SETTINGS';
export const NO_BM_STYLE = {
    background: '#FFFFFF',
    border: '#FFFFFF',
    color: '#000000',
    title: 'No benchmarks',
    top_position: '61000',
};
