import { call, put, takeLatest } from 'redux-saga/effects';
import {
    getSettingsSuccess,
    getSettingsFailed,
    updateSettingsSuccess,
    updateSettingsFailed,
    changeUserSuccess,
    changeUserFailed,
    getResetSettingsSuccess,
    getResetSettingsFailed,
} from './actions';

import { GET_SETTINGS, UPDATE_SETTINGS, CHANGE_USER_VIEW, GET_RESET_SETTINGS } from './constants';
import { updateDefaultSettings } from '../Login/actions';
import { updateApiParams } from '../Diagnose/actions';
import AuthStore from '../../common/AuthStore';
import AUTH0 from '../../auth_config';

import { getSettingsUrl, updateSettingsUrl, updateUserViewUrl, resetSettingsUrl } from './apis';

export function* getSettingSaga({ payload }) {
    try {
        const { data = {} } = yield call(getSettingsUrl, payload);
        const { data: scoreData = {} } = data;
        yield put(getSettingsSuccess(scoreData));
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, error = '' } = errorData;
        yield put(getSettingsFailed({ message, error, status }));
    }
}

export function* updateSettingSaga({ payload }) {
    try {
        const { dataToSend, valueToUpdate, apiParamUpdated } = payload;
        yield call(updateSettingsUrl, dataToSend);
        yield put(updateDefaultSettings(valueToUpdate));
        yield put(updateSettingsSuccess(valueToUpdate));
        yield put(updateApiParams(apiParamUpdated));
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, error = '' } = errorData;
        yield put(updateSettingsFailed({ message, error, status }));
    }
}

export function* updateUserViewSaga({ payload }) {
    try {
        const { data = {} } = yield call(updateUserViewUrl, payload);
        const { data: accessData = {} } = data;
        const { token = '', user = {}, action_access = '' } = accessData;
        const { name = '', role = '' } = user;
        AuthStore.deleteOhId();
        AuthStore.deleteReportId();
        AuthStore.actionAccess = action_access;
        AuthStore.accessToken = `Bearer ${token}`;
        if (name) {
            AuthStore.userRole = role;
            AuthStore.userName = name;
        }
        yield put(changeUserSuccess(accessData));
        window.location.replace(AUTH0.redirectBaseUrl);
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, error = '' } = errorData;
        yield put(changeUserFailed({ message, error, status }));
    }
}

export function* getResetSettingsSaga({ payload }) {
    try {
        const { data = {} } = yield call(resetSettingsUrl, payload);
        const { data: resetSettings = {} } = data;
        yield put(getResetSettingsSuccess(resetSettings));
        yield put(updateSettingsSuccess(resetSettings));
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, error = '' } = errorData;
        yield put(getResetSettingsFailed({ message, error, status }));
    }
}

const settingsSagas = [
    takeLatest(`${GET_SETTINGS}_PENDING`, getSettingSaga),
    takeLatest(`${UPDATE_SETTINGS}_PENDING`, updateSettingSaga),
    takeLatest(`${CHANGE_USER_VIEW}_PENDING`, updateUserViewSaga),
    takeLatest(`${GET_RESET_SETTINGS}_PENDING`, getResetSettingsSaga),
];

export default settingsSagas;
