import React, { useState, useEffect } from 'react';
import c from 'classnames';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import Layout from '../../components/Layout';
import Loader from '../../components/Loader';
import Customization from './Customization';
import Quartiles from './Quartiles';
import { getStaticText } from '../../utils/constants';
import './index.scss';
import ErrorToast from '../../components/InformationToast/ErrorToast';

function Settings({
    apiParams,
    errorInfo,
    apiLoadingCount,
    settingsData,
    settingsDataFetched,
    getSettings,
    updateSettings,
    resetError,
    defaultSettings,
    getResetSettings,
}) {
    const { lang, benchmark } = defaultSettings;
    const staticText = getStaticText(lang);
    const { SITE_TEXT } = staticText;
    const { SETTINGS, SETTINGS_NAV_LINKS, USER_SETTINGS } = SITE_TEXT || {};
    const [activeIndex, updateIndex] = useState(0);
    const { ohid } = apiParams;

    useEffect(() => {
        if (!settingsDataFetched && !apiLoadingCount) {
            getSettings({ ohid });
        }
        // eslint-disable-next-line
    }, [settingsDataFetched]);

    const updateData = (newSettings, valueToUpdate, apiParamUpdated = {}) => {
        updateSettings({
            dataToSend: {
                ...newSettings,
                ohid,
            },
            valueToUpdate: {
                ...valueToUpdate,
                benchmark,
            },
            apiParamUpdated,
        });
    };

    const getPage = () => {
        let page;
        switch (activeIndex) {
            case 0:
            default:
                page = (
                    <Customization
                        defaultSettings={defaultSettings}
                        errorInfo={errorInfo}
                        updateData={updateData}
                        data={settingsData}
                        resetError={resetError}
                    />
                );
                break;
            case 1:
                page = (
                    <Quartiles
                        defaultSettings={defaultSettings}
                        errorInfo={errorInfo}
                        updateData={updateData}
                        data={settingsData}
                        resetError={resetError}
                        getResetSettings={getResetSettings}
                    />
                );
                break;
        }
        return page;
    };

    const changePage = newPageIndex => {
        resetError();
        updateIndex(newPageIndex);
    };

    return (
        <Layout showHeader>
            <div className="settings clearfix">
                <Helmet>
                    <title>
                        {USER_SETTINGS} {activeIndex === 3 ? 'Quartiles' : 'Customization'}
                    </title>
                </Helmet>
                {apiLoadingCount ? <Loader position="absolute" showBackground background="transparent" /> : null}
                <h2>{SETTINGS}</h2>
                <ul className="navLink clearfix">
                    {SETTINGS_NAV_LINKS.map((text, index) => (
                        <li
                            data-testid={`settingsTab${index}`}
                            className={c({ selected: activeIndex === index })}
                            key={text}
                            onClick={() => changePage(index)}
                        >
                            {text}
                        </li>
                    ))}
                </ul>
                {errorInfo && <ErrorToast message={errorInfo} />}
                {settingsDataFetched && getPage()}
            </div>
        </Layout>
    );
}

Settings.propTypes = {
    apiLoadingCount: PropTypes.number.isRequired,
    apiParams: PropTypes.object.isRequired,
    settingsData: PropTypes.object.isRequired,
    defaultSettings: PropTypes.object.isRequired,
    settingsDataFetched: PropTypes.bool.isRequired,
    errorInfo: PropTypes.string.isRequired,
    getSettings: PropTypes.func.isRequired,
    updateSettings: PropTypes.func.isRequired,
    resetError: PropTypes.func.isRequired,
    getResetSettings: PropTypes.func.isRequired,
};

export default Settings;
