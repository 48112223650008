import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import c from 'classnames';

import './index.scss';
import {
    Button,
    FormControlLabel,
    FormGroup,
    Input,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    TextField,
} from '@material-ui/core';
import KeyboardArrowDownSharpIcon from '@material-ui/icons/KeyboardArrowDownSharp';
import CloudUpload from '../../../assets/Cloud-upload.svg';
import RemoveImage from '../../../assets/C-remove.svg';
import Warning from '../assets/T-warning.svg';
import SuccessDialog from './SuccessDialog';
import getStaticTextDiagnose from '../constants';
import { getStaticText } from '../../../utils/constants';

function ReportFeedback({
    sendFeedback,
    defaultSettings,
    handleClose,
    setView,
    tab,
    feedback,
    feedbackSuccess,
    resetFeedback,
    setTicketId,
    setOptions,
    options,
    resetFeedbackOptions,
}) {
    const { lang } = defaultSettings;
    const staticTextDiagnose = getStaticTextDiagnose(lang);
    const { FEEDBACK_ISSUES_OPTIONS, REPORT_FEEDBACK_LIST, REPORT_FEEDBACK_SITE_TEXT } = staticTextDiagnose;
    const [multipleFile, setMultipleFile] = useState([]);
    const [isUnique, setIsUnique] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const [openConfirm, toggleConfirmModal] = useState(false);
    const [error, setError] = useState({ imageErr: '' });
    const staticText = getStaticText(lang);
    const { SITE_TEXT } = staticText;
    const {
        REPORT_ISSUE,
        REPORT_FEEDBACK_LIST_TEXT,
        TYPE_BUG,
        FEEDBACK_TEXT_ISSUE,
        DESCRIBE_ISSUE,
        FEEDBACK_CATEGORY_TEXT,
        PROBLEM_TEXT,
        ADD_IMAGE,
        UPLOAD_IMAGE,
        IMG_INSERT,
        TITLE_ISSUE_TEXT,
        BUG_TYPE_TEXT,
        CLIENT_TEXT,
        WORKAROUND_TEXT,
        SEND_FEEDBACK,
        CANCEL,
        ENTER_FEEDBACK,
        TITLE,
        IMMEDIATE_IMPACT,
        IS_WORKAROUND_OPT,
    } = SITE_TEXT;

    const { CONFIRM_TXT } = REPORT_FEEDBACK_SITE_TEXT;

    const uploadFile = ({ target }) => {
        const { files } = target;
        if (multipleFile.length <= 4 && files.length <= 4) {
            setMultipleFile([...multipleFile, ...files]);
            setError({
                imageErr: '',
            });
        } else {
            setError({
                imageErr: 'Include relevant screenshots (jpeg, png), up to 4 images or 5 mb.',
            });
        }
    };

    const { summary = '', description = '', type_of_bug = '', is_workaround, immediate_impact } = options || {};

    const validateInputs = () => {
        if (!summary || !description || !isUnique || !type_of_bug) {
            return setIsActive(false);
        }
        setIsActive(true);
        return null;
    };

    const errorMessage = message => {
        return (
            <Fragment>
                <span style={{ display: 'flex', alignItems: 'center', width: 'fit-content' }}>
                    <img src={Warning} alt="" />
                </span>
                <span style={{ color: 'red' }}>{message}</span>
            </Fragment>
        );
    };

    const { imageErr = '' } = error || {};

    const handleChange = ({ target }) => {
        const { value, name } = target || {};
        const valToUse = name === 'immediate_impact' || name === 'is_workaround' ? value === 'yes' : value;
        setOptions({
            ...options,
            [name]: valToUse,
        });
    };

    const handleCheckbox = ({ target }) => {
        const { checked } = target;
        setIsUnique(checked);
    };

    const handleSubmit = () => {
        const os = window.navigator.userAgentData.platform;
        const formData = new FormData();
        Object.keys(options).forEach(key => {
            formData.append(key, options[key]);
        });
        formData.append('other_detail', os);
        multipleFile.forEach(file => {
            formData.append('files', file);
        });
        sendFeedback(formData);
    };

    useEffect(() => {
        validateInputs();
        // eslint-disable-next-line
    }, [isUnique, options]);

    useEffect(() => {
        if (feedbackSuccess) {
            toggleConfirmModal(true);
        }
        // eslint-disable-next-line
    }, [feedbackSuccess]);

    const handleModalClose = () => {
        toggleConfirmModal(false);
        handleClose();
        resetFeedbackOptions();
    };

    return (
        <Fragment>
            <div className="optionWrapper">
                <div>
                    <ul className="leftNav clearfix">
                        {REPORT_FEEDBACK_LIST.map((tabName, tabIndex) => {
                            return (
                                <li
                                    key={tabName + tabIndex}
                                    className={c({ activeLink: tabIndex === tab })}
                                    onClick={() => setView(tabIndex)}
                                >
                                    {tabName}
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </div>
            <div className="scrollSection">
                <div className="sectionHead">
                    <h3>{REPORT_ISSUE}</h3>
                    <div>{REPORT_FEEDBACK_LIST_TEXT}</div>
                </div>
                <form>
                    <div>
                        <div>
                            <div className="title">
                                <span>
                                    {TITLE}
                                    <sup>*</sup>
                                </span>
                            </div>
                            <Input
                                placeholder={TITLE_ISSUE_TEXT}
                                type="text"
                                name="summary"
                                onChange={handleChange}
                                value={summary}
                                maxLength="50"
                            />
                            <div className="subtext">{FEEDBACK_TEXT_ISSUE}</div>
                        </div>
                        <div>
                            <div className="title">
                                <span>
                                    {TYPE_BUG}
                                    <sup>*</sup>
                                </span>
                            </div>
                            {/* <select>
							<option>Data Issue</option>
						</select> */}
                            <Select
                                IconComponent={KeyboardArrowDownSharpIcon}
                                value={type_of_bug}
                                variant="outlined"
                                displayEmpty
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    },
                                    transformOrigin: {
                                        vertical: 'top',
                                        horizontal: 'left',
                                    },
                                    getContentAnchorEl: null,
                                }}
                                name="type_of_bug"
                                onChange={handleChange}
                            >
                                <MenuItem classes={{ root: 'demographicOptions' }} value="" disabled>
                                    <em>{BUG_TYPE_TEXT}</em>
                                </MenuItem>
                                {FEEDBACK_ISSUES_OPTIONS.map((label, code) => {
                                    return (
                                        <MenuItem
                                            classes={{ root: 'demographicOptions' }}
                                            key={code + label}
                                            value={label}
                                        >
                                            {label}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                            <div className="subtext">{FEEDBACK_CATEGORY_TEXT}</div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div className="title">
                                <span>
                                    {DESCRIBE_ISSUE}
                                    <sup>*</sup>
                                </span>
                            </div>
                            <TextField
                                fullWidth
                                multiline
                                rows={4}
                                variant="outlined"
                                inputProps={{
                                    'data-testid': 'description',
                                }}
                                name="description"
                                value={description}
                                onChange={handleChange}
                                placeholder={ENTER_FEEDBACK}
                            />
                            <div className="subtext">{PROBLEM_TEXT}</div>
                        </div>
                        <div>
                            <div className="title">
                                {ADD_IMAGE}
                                {multipleFile.length ? (
                                    <label htmlFor="file-upload" className="custom-file-upload">
                                        <input
                                            id="file-upload"
                                            type="file"
                                            className="imageIp"
                                            name="images"
                                            accept="image/*"
                                            multiple
                                            onChange={uploadFile}
                                        />
                                        <img src={CloudUpload} alt="" />
                                        {UPLOAD_IMAGE}
                                    </label>
                                ) : null}
                            </div>
                            <div className="imagePreview">
                                {multipleFile.map((file, i) => {
                                    return (
                                        <span>
                                            {' '}
                                            <img src={URL.createObjectURL(file)} alt="" />
                                            <img
                                                className="closeImage"
                                                src={RemoveImage}
                                                alt=""
                                                onClick={() =>
                                                    setMultipleFile(multipleFile.filter((_, index) => index !== i))
                                                }
                                            />
                                        </span>
                                    );
                                })}
                                {!multipleFile.length ? (
                                    <label htmlFor="file-upload" className="custom-file-upload-btn">
                                        <input
                                            id="file-upload"
                                            type="file"
                                            className="imageIp"
                                            name="images"
                                            accept="image/*"
                                            multiple
                                            onChange={uploadFile}
                                        />
                                        <img src={CloudUpload} alt="" />
                                        {UPLOAD_IMAGE}
                                    </label>
                                ) : null}
                            </div>
                            <div className="subtext">
                                {!imageErr ? <Fragment> {IMG_INSERT}</Fragment> : errorMessage(imageErr)}
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div className="title">
                                <span>
                                    {CLIENT_TEXT}
                                    <sup>*</sup>
                                </span>
                            </div>
                            <FormGroup classes={{ root: 'radioGroup' }}>
                                <RadioGroup
                                    aria-label="filterOption"
                                    name="immediate_impact"
                                    value={immediate_impact ? 'yes' : 'no'}
                                    onChange={handleChange}
                                >
                                    {IMMEDIATE_IMPACT.map(({ label, key }, i) => (
                                        <FormControlLabel
                                            key={`${label}${i}`}
                                            value={key}
                                            control={<Radio disableRipple />}
                                            label={label}
                                        />
                                    ))}
                                </RadioGroup>
                            </FormGroup>
                            <div />
                        </div>
                        <div>
                            <div className="title">
                                <span>
                                    {WORKAROUND_TEXT}
                                    <sup>*</sup>
                                </span>
                            </div>
                            <FormGroup classes={{ root: 'radioGroup' }}>
                                <RadioGroup
                                    aria-label="filterOption"
                                    name="is_workaround"
                                    value={is_workaround ? 'yes' : 'no'}
                                    onChange={handleChange}
                                >
                                    {IS_WORKAROUND_OPT.map(({ label, key }, i) => (
                                        <FormControlLabel
                                            key={`${key}${i}`}
                                            value={key}
                                            control={<Radio disableRipple />}
                                            label={label}
                                        />
                                    ))}
                                </RadioGroup>
                            </FormGroup>
                        </div>
                    </div>
                </form>
            </div>
            <div className="buttonContent clearfix">
                <span>
                    <input type="checkbox" value={isUnique} onChange={handleCheckbox} />
                    <span>{CONFIRM_TXT}</span>
                </span>
                <Button onClick={handleClose} className="active">
                    {CANCEL}
                </Button>
                <Button onClick={handleSubmit} className={c({ active: isActive })}>
                    {SEND_FEEDBACK}
                </Button>
            </div>
            {openConfirm && (
                <SuccessDialog
                    setView={setView}
                    feedback={feedback}
                    toggleConfirmModal={handleModalClose}
                    resetFeedback={resetFeedback}
                    setTicketId={setTicketId}
                />
            )}
        </Fragment>
    );
}

ReportFeedback.propTypes = {
    handleClose: PropTypes.func.isRequired,
    sendFeedback: PropTypes.func.isRequired,
    defaultSettings: PropTypes.func.isRequired,
    setView: PropTypes.func.isRequired,
    tab: PropTypes.number.isRequired,
    feedback: PropTypes.number.isRequired,
    feedbackSuccess: PropTypes.bool.isRequired,
    resetFeedback: PropTypes.func.isRequired,
    setTicketId: PropTypes.func.isRequired,
    setOptions: PropTypes.object.isRequired,
    options: PropTypes.object.isRequired,
    resetFeedbackOptions: PropTypes.func.isRequired,
};

export default ReportFeedback;
