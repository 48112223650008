import React, { useState, useEffect, Fragment, useRef } from 'react';
import PropTypes from 'prop-types';
import c from 'classnames';
import { Helmet } from 'react-helmet';

import './index.scss';
import { Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { cloneDeep } from 'lodash';
import { getBenchmarkName, getFiltersAppliedText, getOutputFilter, updateFilterParams } from '../../../utils/functions';
import FocusArea from '../assets/FocusArea.svg';
import viewIcon from '../../Reports/assets/viewIcon.svg';
import { getStaticText } from '../../../utils/constants';
// import FilterBar from '../../../components/FilterBar';
import AuthStore from '../../../common/AuthStore';

function ResurveyBoard({
    practiceDataCompYr,
    getComparisonYrPracticeData,
    compOhid,
    apiParams,
    apiLoadingCount,
    practiceData,
    defaultSettings,
    filters,
    setFilters,
}) {
    const { survey_version = '', year = '', lang = '', ohid: currOhid = '' } = defaultSettings;
    const staticText = getStaticText(lang);
    const { SITE_TEXT } = staticText;
    const {
        VIEW_MORE,
        VIEW_LESS,
        BENCHMARK_HEAD,
        HEALTH_PRACTICE_RANKING,
        BOTTOM_RANKING,
        MIDDLE_RANKING,
        TOP_RANKING,
        RANK,
        PRACTICES,
        RANKING,
        NUMBER_OF_SURVEYS,
        YEAR,
        ORGANIZATION,
        COMPARISON_SURVEY,
    } = SITE_TEXT || {};
    const { ohid = '', year: compYear = '' } = compOhid || {};
    const getFilterParams = filterItem => {
        const { demographics = [], benchmarks = [] } = filterItem[1] || {};
        return updateFilterParams(demographics, benchmarks);
    };
    useEffect(() => {
        const practiceDataCompYrFetched = ohid in practiceDataCompYr;
        if (!practiceDataCompYrFetched && !apiLoadingCount) {
            const { filters: filtersApplied = [], benchmarks = '' } = getFilterParams(filters);
            getComparisonYrPracticeData({ ...apiParams, ...compOhid, filters: filtersApplied, benchmarks });
        }
        // eslint-disable-next-line
    }, [apiParams, practiceDataCompYr, compOhid]);
    const [isSticky, setIsSticky] = useState(false);
    const scrollIndex = useRef(null);
    const { ranking: recipeArrCurr = [], nSize = '', n_respondents, n_survey } = practiceData || {};
    const {
        ranking: recipeArrComp = [],
        nSize: compareSize = '',
        n_respondents: compareRespondents = '',
        n_survey: compareSurvey,
    } = practiceDataCompYr[ohid] || {};
    const { items: mainRanks = [] } = recipeArrCurr[0] || {};
    const { items: ranks = [] } = recipeArrComp[0] || {};
    const isV4 = survey_version === '4';
    const [scroll, setScroll] = useState(-1);
    const [activeBtn, setActiveBtn] = useState(-1);
    const [showAll, setShowAll] = useState(false);
    const [showCompAll, setShowCompAll] = useState(false);

    const LightTooltip = withStyles(theme => ({
        arrow: {
            color: theme.palette.common.black,
        },
        tooltip: {
            backgroundColor: '#333333',
            fontSize: 14,
            fontStyle: 'italic',
            fontWeight: 400,
        },
    }))(Tooltip);

    const toggleShowAll = () => {
        setShowAll(!showAll);
    };

    const toggleCompShowAll = () => {
        setShowCompAll(!showCompAll);
    };

    const handleAllFilterData = (
        parKey,
        childKey,
        demographic,
        filtersHere,
        updateRedux = true,
        activeFilter = true
    ) => {
        const newDemographic = cloneDeep(demographic);
        const newParDemoIndex = newDemographic.findIndex(({ code }) => code === parKey);
        const selectedItemIndex = newDemographic[newParDemoIndex].options.findIndex(({ code }) => code === childKey);
        newDemographic[newParDemoIndex].options[selectedItemIndex].isSelected = false;
        const { benchmarks = [] } = filtersHere[0];
        const allList = { demographics: newDemographic, benchmarks };
        const newList = [...filters.slice(0, 0), allList, ...filters.slice(0 + 1)];
        if (updateRedux === true) {
            setFilters(newList, true, activeFilter, 0);
        }
    };

    const handleAllCompFilter = (
        parKey,
        childKey,
        compDemographic,
        buFilterHere,
        updateRedux = true,
        activeFilter = true
    ) => {
        const newDemographic = cloneDeep(compDemographic);
        const newParDemoIndex = newDemographic.findIndex(({ code }) => code === parKey);
        const selectedItemIndex = newDemographic[newParDemoIndex].options.findIndex(({ code }) => code === childKey);
        newDemographic[newParDemoIndex].options[selectedItemIndex].isSelected = false;
        const { benchmarks: compBenchmark = [] } = buFilterHere[1];
        const allList = { demographics: newDemographic, compBenchmark };
        const newList = [...filters.slice(0, 1), allList];
        if (updateRedux === true) {
            setFilters(newList, true, activeFilter, 1);
        }
    };

    const maxItemsToShow = 6;

    useEffect(() => {
        // eslint-disable-next-line no-unused-expressions
        scrollIndex.current?.scrollIntoView({ behavior: 'instant' });
    }, [scroll]);

    const switchBtnHighlight = () => {
        const mid = document.getElementById('mid');
        const bottom = document.getElementById('bottom');
        const midOffSet = mid.offsetTop + 200;
        const bottomOffSet = bottom.offsetTop + 200;
        if (window.scrollY > midOffSet && window.scrollY < bottomOffSet) {
            setActiveBtn(11);
        } else if (window.scrollY > bottomOffSet) {
            setActiveBtn(mainRanks.length - 13);
        } else {
            setActiveBtn(-1);
        }
    };

    const listenToScroll = () => {
        const handleScroll = () => {
            const offset = window.scrollY;
            setIsSticky(offset > 200);
        };
        handleScroll();
        switchBtnHighlight();
    };

    useEffect(() => {
        window.addEventListener('scroll', listenToScroll);
        return () => {
            window.removeEventListener('scroll', listenToScroll);
        };
        // eslint-disable-next-line
    }, []);

    const renderComparisonRows = ({ rank }) => {
        const currItemLength = mainRanks.length;
        const compItemLength = ranks.length;
        const lastMidRankComp = compItemLength - 12;
        const currBottomRank = currItemLength - 12;
        const delta = currItemLength - compItemLength;
        const skipPosition = delta > 0 && rank > lastMidRankComp && rank <= currBottomRank;
        const rankToUse = rank > lastMidRankComp ? rank - delta : rank;

        const { text2: practice = '' } = ranks[rankToUse - 1] || {};
        if (!ranks.length) return null;
        return (
            <Fragment>
                <div
                    className="practiceIndex"
                    style={{
                        width: '5%',
                        textAlign: 'center',
                    }}
                >
                    <div className="orgRanks">
                        <span
                            className={c('compRound comp', {
                                topRank: rankToUse < 13,
                            })}
                        >
                            {skipPosition ? '' : rankToUse}
                        </span>
                    </div>
                </div>
                <div style={{ width: '45%' }} className={c('practiceText', {})}>
                    {skipPosition ? '' : practice}
                </div>
            </Fragment>
        );
    };

    const renderRows = ({ rank, practice }) => {
        if (!ranks.length) return null;
        return (
            <Fragment>
                <div
                    className="practiceIndex"
                    style={{
                        width: '5%',
                        textAlign: 'center',
                    }}
                >
                    <div className="orgRanks">
                        <span
                            className={c('compRound comp', {
                                topRank: rank < 13,
                            })}
                        >
                            {!practice ? '' : rank}
                        </span>
                    </div>
                </div>
                <div style={{ width: '45%' }} className={c('practiceText', {})}>
                    {practice}
                </div>
            </Fragment>
        );
    };

    const handleClicks = index => {
        setScroll(index);
        setActiveBtn(index);
    };

    const resurveyDemoName1 = AuthStore.resurveyFilter1 || ORGANIZATION;
    const resurveyDemoName2 = AuthStore.resurveyFilter2 || COMPARISON_SURVEY;

    const appliedBuFiltersContent = () => {
        const { demographics: budemographics = [], benchmarks: compBenchmarks = [] } = filters[1] || {};
        const compDemographic = budemographics || [];
        const { benchmarks: bm = [], demographics = [] } = filters[0] || {};
        const { filters: filterApplied = [] } = apiParams;
        const label = getBenchmarkName(bm);
        const { filters: compFilter = [] } = updateFilterParams(compDemographic, compBenchmarks) || {};

        const arrItem = getFiltersAppliedText(filterApplied, demographics);
        const newItem = getOutputFilter(filterApplied, demographics);
        const arrItemComp = getFiltersAppliedText(compFilter, compDemographic);
        const newItemComp = getOutputFilter(compFilter, compDemographic);

        return (
            <div className="filterAppliedContent">
                {arrItem.length > 0 && newItem !== null && (
                    <div className="filterDemo">
                        {newItem !== null && (
                            <div className="heading">
                                {resurveyDemoName1} {`(${year} - ${currOhid})`}:
                            </div>
                        )}
                        <div className="nsizeContent">
                            <div>n : </div>
                            <div>{nSize}</div>
                        </div>
                        {newItem !== null &&
                            newItem
                                .slice(0, showAll ? newItem.length : maxItemsToShow)
                                .map(({ parentK, childKey, parentCode, childCode }) => {
                                    return (
                                        <div className="filterContent">
                                            <LightTooltip title={`${parentK} - ${childKey}`} arrow>
                                                <div
                                                    key={parentCode}
                                                    className="filterHighlight filterKey"
                                                >{`${childKey}`}</div>
                                            </LightTooltip>
                                            <img
                                                src={FocusArea}
                                                alt=""
                                                className="filterImg"
                                                onClick={() => {
                                                    handleAllFilterData(parentCode, childCode, demographics, filters);
                                                }}
                                            />
                                        </div>
                                    );
                                })}
                        {(newItem.length >= maxItemsToShow || showAll) && (
                            <div onClick={toggleShowAll}>
                                {showAll ? (
                                    <div className="viewIconContent">
                                        <img src={viewIcon} alt="" className="viewImg" />
                                        <div className="viewText">{VIEW_LESS}</div>
                                    </div>
                                ) : (
                                    <div className="viewIconContent">
                                        <img src={viewIcon} alt="" className="viewImg" />
                                        <div className="viewText">{VIEW_MORE}</div>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                )}

                {arrItemComp.length > 0 && newItemComp !== null && (
                    <div className="filterDemo">
                        {newItemComp !== null && (
                            <div className="heading">
                                {resurveyDemoName2} {`(${compYear} - ${ohid})`}:
                            </div>
                        )}
                        <div className="nsizeContent">
                            <div>n : </div>
                            <div>{compareSize}</div>
                        </div>
                        {newItemComp !== null &&
                            newItemComp
                                .slice(0, showCompAll ? newItemComp.length : maxItemsToShow)
                                .map(({ parentK, childKey, parentCode, childCode }) => {
                                    return (
                                        <div className="filterContent">
                                            <LightTooltip title={`${parentK} - ${childKey}`} arrow>
                                                <div
                                                    key={parentCode}
                                                    className="filterHighlight filterKey"
                                                >{`${childKey}`}</div>
                                            </LightTooltip>
                                            <img
                                                src={FocusArea}
                                                alt=""
                                                className="filterImg"
                                                onClick={() => {
                                                    handleAllCompFilter(
                                                        parentCode,
                                                        childCode,
                                                        compDemographic,
                                                        filters
                                                    );
                                                }}
                                            />
                                        </div>
                                    );
                                })}
                        {(newItemComp.length >= maxItemsToShow || showCompAll) && (
                            <div onClick={toggleCompShowAll}>
                                {showCompAll ? (
                                    <div className="viewIconContent">
                                        <img src={viewIcon} alt="" className="viewImg" />
                                        <div className="viewText">{VIEW_LESS}</div>
                                    </div>
                                ) : (
                                    <div className="viewIconContent">
                                        <img src={viewIcon} alt="" className="viewImg" />
                                        <div className="viewText">{VIEW_MORE}</div>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                )}
                <div className="filterDemo">
                    <div className="heading">{BENCHMARK_HEAD + `:`}</div>
                    <div className="nsizeContent">
                        <div>{YEAR} : </div>
                        <div>{year}</div>
                    </div>
                    <div className="nsizeContent">
                        <div>n :</div>
                        <div>{n_respondents}</div>
                    </div>
                    <div className="nsizeContent">
                        <div>{NUMBER_OF_SURVEYS} :</div>
                        <div>{n_survey}</div>
                    </div>
                    <div className="filterContent">
                        <div className="filterHighlight filterKey filterLab">{label}</div>
                    </div>

                    <div className="heading">{BENCHMARK_HEAD + `:`}</div>
                    <div className="nsizeContent">
                        <div>{YEAR} : </div>
                        <div>{compYear}</div>
                    </div>
                    <div className="nsizeContent">
                        <div>n :</div>
                        <div>{compareRespondents}</div>
                    </div>
                    <div className="nsizeContent">
                        <div>{NUMBER_OF_SURVEYS} :</div>
                        <div>{compareSurvey}</div>
                    </div>
                    <div className="filterContent">
                        <div className="filterHighlight filterKey filterLab">{getBenchmarkName(compBenchmarks)}</div>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <Fragment>
            <Helmet>
                <title>{HEALTH_PRACTICE_RANKING}</title>
            </Helmet>
            <div className="newContentForBu">{appliedBuFiltersContent()}</div>
            {mainRanks.length && ranks.length && isV4 ? (
                <div className="graphContent buGraphContent">
                    <div className="groupSection">
                        <div className={c('sideScrolling resurvey', { stickyBtn: isSticky })}>
                            <div
                                className={c('rankingScroll', { active: activeBtn === -1 })}
                                onClick={() => handleClicks(-1)}
                            >
                                <div className="ranking">{TOP_RANKING}</div>
                            </div>
                            <div
                                className={c('rankingScroll', { active: activeBtn === 11 })}
                                onClick={() => handleClicks(11)}
                            >
                                <div className="ranking">{MIDDLE_RANKING}</div>
                            </div>
                            <div
                                className={c('rankingScroll', {
                                    active: activeBtn === Object.keys(mainRanks).length - 13,
                                })}
                                onClick={() => handleClicks(mainRanks.length - 13)}
                            >
                                <div className="ranking">{BOTTOM_RANKING}</div>
                            </div>
                        </div>

                        <div className="rankingBuSection resurveyBoard" ref={scroll === -1 ? scrollIndex : null}>
                            <div className="headerNav top">
                                <div className="headingContent rankHead">
                                    {resurveyDemoName1} {`(${year} - ${currOhid})`}
                                </div>
                                <div className="headingContent practice">{}</div>
                                <div className="headingContent rankHead">
                                    {resurveyDemoName2} {`(${compYear} - ${ohid})`}
                                </div>
                                <div className="headingContent practice">{}</div>
                            </div>
                            <div className="headerNav bottom">
                                <div className="headingContent rankHead">{RANK}</div>
                                <div className="headingContent practice">{PRACTICES}</div>
                                <div className="headingContent rankHead">{RANK}</div>
                                <div className="headingContent practice">{PRACTICES}</div>
                            </div>
                            <div className="mainContent resurveyBoard">
                                {mainRanks.map(({ text2: practiceName = '', index: rank }) => {
                                    const topRanking = rank === 13;
                                    const bottomRanking = rank === mainRanks.length - 11;
                                    const styleArr = {};
                                    const id = rank === 12 ? 'mid' : '';
                                    const idToUse = rank === mainRanks.length - 12 ? 'bottom' : id;
                                    if (rank < 13) {
                                        styleArr.color = '#0679C3';
                                        styleArr.fontWeight = 500;
                                    }
                                    if (rank > mainRanks.length - 12) {
                                        styleArr.color = '#FF0000';
                                    }
                                    if (topRanking || bottomRanking) {
                                        styleArr.borderTop = '1px solid #CCCCCC';
                                    }
                                    return (
                                        <div
                                            className="practiceContent"
                                            key={rank}
                                            style={styleArr}
                                            ref={scroll === rank - 1 ? scrollIndex : null}
                                            id={idToUse}
                                        >
                                            {renderRows({
                                                rank,
                                                practice: practiceName,
                                            })}
                                            {renderComparisonRows({
                                                rank,
                                            })}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}
            {/* {reSurveyFilter && (
                <FilterBar
                    activeResetFilter={activeFilter}
                    size={[nSize, 0]}
                    surveyRespondents={[n_respondents, 0]}
                    surveys={[n_survey, 0]}
                    filters={[filters[0], { demographics: practiceFilters }]}
                    projectInFilter={BU_PROJECTS}
                    currentView={6}
                    showBenchmarks={false}
                    closeFilters={updateFilterView}
                    setFilters={setFilters}
                    defaultBenchmark={benchmark}
                    defaultSettings={defaultSettings}
                />
            )} */}
        </Fragment>
    );
}

ResurveyBoard.propTypes = {
    apiParams: PropTypes.object.isRequired,
    apiLoadingCount: PropTypes.number.isRequired,
    practiceData: PropTypes.object.isRequired,
    defaultSettings: PropTypes.object.isRequired,
    practiceDataCompYr: PropTypes.object.isRequired,
    getComparisonYrPracticeData: PropTypes.func.isRequired,
    compOhid: PropTypes.object.isRequired,
    filters: PropTypes.array.isRequired,
    setFilters: PropTypes.func.isRequired,
};

export default ResurveyBoard;
