import { postWebApi, putWebApi } from '../../webapis/dashboard';
import endpoints from '../../Endpoints';

export const getSettingsUrl = options => {
    const { apiBasePath, settingsPath } = endpoints;
    const url = `${apiBasePath}${settingsPath}`;
    return postWebApi(url, options).request;
};

export const updateSettingsUrl = options => {
    const { apiBasePath, updateSettingsPath } = endpoints;
    const url = `${apiBasePath}${updateSettingsPath}`;
    return postWebApi(url, options).request;
};

export const updateUserViewUrl = options => {
    const { apiBasePath, changeUserViewUrl } = endpoints;
    const url = `${apiBasePath}${changeUserViewUrl}`;
    return postWebApi(url, options).request;
};

export const resetSettingsUrl = options => {
    const { apiBasePath, resetCustomization } = endpoints;
    const url = `${apiBasePath}${resetCustomization}`;
    return putWebApi(url, options).request;
};
