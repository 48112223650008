import React, { Fragment, useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
    Radio,
    FormGroup,
    RadioGroup,
    FormControlLabel,
    Switch,
    TextField,
    Tooltip,
    Dialog,
    DialogContent,
    IconButton,
    Checkbox,
    List,
    ListItem,
    DialogActions,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import CloseIcon from '@material-ui/icons/Close';
import { cloneDeep } from 'lodash';
import c from 'classnames';

import WarningIcon from '@material-ui/icons/Warning';
import AuthStore from '../../../common/AuthStore';
import DemographicFilter from './DemographicFilter';
import Demographics from '../../../components/FilterBar/Demographics';
import ConfirmationModal from '../../../components/ConfirmationModal';
import { getStaticText } from '../../../utils/constants';
import {
    DEMO_IMAGES,
    LEVEL_TYPE,
    DOWNLOD_TYPE,
    OUTCOME_IMAGES_VIEW,
    PRACTICE_IMAGES_VIEW,
    // DELTA_HEATMAP_IMAGES,
    SAMPLE_IMAGES,
    SCORE_HEATMAP_IMAGES,
    COHESION_HEATMAP_IMAGES,
    EMPLOYEE_EXPERIENCE_IMAGES,
    RESPONSE_RATE_IMAGES,
    OLD_OUTCOME_IMAGES_VIEW,
    OLD_PRACTICE_IMAGES_VIEW,
} from '../constants';
import { CRH_CLIENT_OHI12922 } from '../../Inclusion/constants';
import { checkChildrenArrLength, checkCompareArrLength, keepReportOption } from '../../../utils/functions';
import ImageModal from '../../../components/ImageModal';
// import BulkPastReport from './BulkPastReport';
import viewIcon from '../assets/viewIcon.svg';
import BulkPastReport from './BulkPastReport';

function DemographicOption({
    goBack,
    demoGraphicState,
    submitReport,
    reportApiParams,
    currentOhid,
    buDemoState,
    setBuDemoState,
    handleDemograhicState,
    reportIndex,
    defaultSettings,
    showBulk,
    setBulkToggle,
    bulkDemographics,
    updateBulkFilters,
    isCfgClient,
    isIngClient,
    isAmmegaRpt,
    isThomsonRpt,
    isNonProfit,
    resurveyMapping,
    resurveyDemographics,
    showAvgGap,
    setAvgGapToggle,
    currentBulkDemo,
    updateBulkDemo,
    getBulkScoreData,
    scoreBulkData,
    apiParams,
    setLastMenu,
    setIsDemoChecked,
    isDemoChecked,
    updateNsizeDemo,
    setUpdateNsizeDemo,
    resetBulkAssign,
    scoreBulkDataFetched,
    handleOhi4DemograhicState,
    ohi4DemoState,
    setOrgLevelToggle,
    orgLevelBu,
    setCurrentMenu,
    // setDemoState,
    // new
    ingReportContent,
    handleTRResDemograhicState,
    TRResDemoState,
}) {
    const {
        full_inclusion_access = false,
        threshold: backThreshold = 10,
        inclusion_threshold = 10,
        lang,
        exclude_inclusion_gap: defaultExcludeGaps = '',
        rr_survey_type = 0,
        inclusion_survey_type: surveyTypeIa = '',
        ohi_text,
        ohi4_incModules = [],
        year = '',
        survey_version,
        ohid = '',
        ee_threshold: backEE_threshold = 10,
        report_type = '',
        engagement = '',
        role,
    } = defaultSettings;
    const staticText = getStaticText(lang);
    const {
        SITE_TEXT,
        USER_ROLES,
        OHI4_IND_EXP,
        NONE,
        CUSTOM,
        NON_PROFIT_SURVEY,
        TR_OHID_2024,
        ING_RECENT_OHID,
    } = staticText;
    const {
        DEMOGRAPHIC,
        DEMOGRAPHIC_OPTIONS,
        DEMOGRAPHIC_FILTERS,
        DEMOGRAPHIC_THRESHOLD,
        CHANGE_DEMOGRAPHIC_THRESHOLD,
        BUBBLE_CHART_CUSTOMISATION,
        INSIGHT_INFO,
        VIEW_SAMPLE_SLIDE,
        ALL_DEMOGRAPHICS,
        DEMOGRAPHIC_INSIGHTS,
        PRACTICE_SCORE_BY_DEMOGRAPHIC,
        BACK,
        NEXT,
        HEAT_MAP_INCLUSION,
        HEAT_MAP_INCLUSION_2,
        HEAT_MAP_INCLUSION_RES,
        HEAT_MAP_INCLUSION_RES_2,
        DEMOGRAPHIC_OPTIONS_SUBINFO,
        DEMOGRAPHIC_OPTIONS_SUBINFO_2,
        DEMOGRAPHIC_OPTIONS_EX,
        DATACUBE_OPTIONS_SUBINFO,
        DATACUBE_OPTIONS_SUBINFO_2,
        ENTER_COMPANY_NAME,
        REPORT_NAME_PLACEHOLDER,
        DATACUBE_NAME_PLACEHOLDER,
        DEMO_OPT_NOTE,
        INCLUSION_DEMOOPTION_SUB_HEADING,
        INCLUSION_DEMOOPTION_SUB_HEADING_RESURVEY,
        INCLUSION_DEMOFILTER_SUB_HEADING,
        DATACUBE_DEMOOPTION_SUB_HEADING,
        INCLUSION_PRACTICEBY_DEMO_SUB_HEADING,
        BUBBLE_CHART_SUBHEADING,
        CREATE_REPORT,
        DATACUBE_LEVEL,
        SCORE_DEPTH,
        DYNAMIC_TEXT_INFO,
        EXCLUDE_DEMO_GROUP_SUB_HEADING,
        EXCLUDE_DEMO_GROUP_HEADING,
        DEMOGRAPHIC_SUMMARY_SUBHEADING,
        DEMOGRAPHIC_SUMMARY,
        SCORE_HEAT_MAP,
        SCORE_MAPS,
        INCLUSION_HEATMAP,
        BULK_REPORT_NOTE,
        NOTE,
        demoOptionsValue,
        // CANCEL,
        // CONFIRM,
        BULK_REPORT,
        // COMPARISON_HEATMAP,
        CHOOSE_BULK_UNITS,
        PULSE,
        BULK_RESURVEY_COMP_ERROR,
        SELECT_OUT_VER,
        SELECT_PRTC_VER,
        VERSION_TEXT,
        GAP_TYPE,
        OHI4_OPTIONS_HEAD,
        OHI4_OPTIONS_META,
        INCLUDE_TEXT,
        // VIEW_SAMPLE,
        OVERALL_INCLUSION,
        OHI_4_RPT_TEXT,
        OHI_4_HEADER,
        SCORE_NEW_HEATMAP,
        SCORE_NEW_HEATMAP_2,
        DEMOGRAPHIC_EE_THRESHOLD,
        MAIN_GROUP_TEXT,
        COMPARISION_GROUP,
        COMPARISION_BU_TEXT,
        SAMPLE_SLIDE,
        DOWNLOAD_FORMAT,
        ENABLE_TEXT,
        BU_BUSINESS_TEXT,
        BU_ORGANIZATION_TEXT,
        BU_GROUPS,
        DATACUBE_VERSION,
        PRACTICE_HEAD,
        PRACTICE_SCREEN,
        CATEGORY_BASED,
        CANCEL_TEXT,
        CONFIRM_TEXT,
        INCLUDE_TEXT_1,
        OUTCOME_PLACEMENT_TEXT,
        // new
        HORIZONTAL_TEXT,
        PRACTICE_TABLE_TEXT,
        VERTICAL_AXIS,
        HORIZONTAL_AXIS,
        CARELESS_TEXT,
        COMPARISON_BU_PLACEHOLDER,
    } = SITE_TEXT || {};
    const { REPORT_TYPE } = DYNAMIC_TEXT_INFO({ ohi_text });
    const {
        report_name: rprt_name,
        fill_threshold_with: fill_with,
        sort_by: sort,
        sort_by_ee: sort_ee,
        sort_by_rr: sort_rr,
        heatmap_demographic: heatMapVal,
        ohi4_incDemographics: ohi4_incDemographicsOld,
        options: { ReportType },
        build_your_report = null,
        heatMapOption: heatMapOp,
        selectAll: all,
        report_name: r_name,
        others: othrs,
        demographicFilters: demoFilters,
        ingDemographicFilters: ingDemoFilters,
        additionalSettings,
        level: levelName,
        // ohid,
        exclude_inclusion_gap,
        // non_profit_report_full,
        outcomePptVersion: oldOutPptVersion,
        practicePptVersion: oldPrcPptVersion,
        outcome_heatmap_demo_placement: oldOutcomePlacement,
        ee_heatmap_demo_placement: oldEEPlacement,
        prac_heatmap_deep_dive: oldPracticePlacement,
        comparison_heatmap: old_comparison_heatmap,
        unmapped_demos: unmappedHeatmapVal,
        includeOhi4Demo,
        resource,
        fill_ee_threshold_with: eefill_with,
        ee_unmapped_demos: unmappedHeatmapValEE,
        employee_experience_demographic: empExpHeatmapVal,
        empExpDemographics: eeDemoOption = NONE,
    } = reportApiParams;
    const individualExpAdded = ohi4_incModules.some(({ IsSelected, Name }) => Name === OHI4_IND_EXP && IsSelected);
    const showOhi4Demo = includeOhi4Demo && individualExpAdded;
    const excludeOption = ['demo_StraightLiners', 'demo_StraightLinersIA'];
    const thresholdToUse = ReportType === '4' || ReportType === '6' ? inclusion_threshold : backThreshold;
    const demoThreshold = ReportType === '5' ? 10 : thresholdToUse;
    const demoEEThreshold = ReportType === '5' ? 10 : backEE_threshold;
    const defaultExcludeGapsArr = ['demo_StraightLiners', 'demo_StraightLinersIA'];
    const hideHeatMap = ReportType === '5';

    if (defaultExcludeGaps) {
        const excludeFromList = defaultExcludeGaps.includes(',') ? defaultExcludeGaps.split(',') : [defaultExcludeGaps];
        excludeFromList.forEach(elem => defaultExcludeGapsArr.push(elem));
    }

    const bulkDemoSelected = useMemo(() => {
        return currentBulkDemo.filter(({ options = [] }) => options.some(({ isSelected }) => isSelected)).length;
    }, [currentBulkDemo]);

    const {
        option,
        adminOption,
        exOption,
        heatMap,
        inclusionDemoOptions,
        dataCubeDemoOptions,
        filterArr,
        bubbleChartOptions,
        summaryOptions,
        inclusionGapType,
        rrDemographicOptions,
        empExpDemoOptions,
        cohesionDemoOptions,
        practiceRankingOptions,
        thomsonResFilters,
        opModuleArr,
    } = demoOptionsValue(demoThreshold);
    const { option: eeOption, adminOption: eeAdminOption } = demoOptionsValue(demoEEThreshold);
    const CRHohid = ohid === CRH_CLIENT_OHI12922;
    const overallKeys = !CRHohid;
    const [demographicOption, setDemographicOption] = useState({
        threshold: '',
        n_size: '',
        fill_threshold_with: '',
        sort_by: '',
        sort_by_ee: '',
        sort_by_rr: '',
        heatmap_demographic: new Map(),
        ohi4_incDemographics: new Map(),
        heatMapOption: '',
        options: {
            BU_FilterSummary: '',
            FilterSummary: '',
        },
        selectAll: false,
        others: '',
        bubbleSort: 'score',
        demographicFilters: [
            {
                report_name: '',
                filterOption: 'All',
            },
        ],
        ingDemographicFilters: [],
        level: 1,
        score_depth: 3,
        summaryOpt: NONE,
        summaryValue: NONE,
        bubbleChartValue: NONE,
        deepDiveValue: NONE,
        rrDemographics: NONE,
        empExpDemographics: eeDemoOption,
        cohesionDemographics: NONE,
        resource: 'ohi_influencer_excel',
        summary_demographic: new Map(),
        exclude_demographic: new Map(),
        employee_experience_demographic: new Map(),
        response_rate_demographic: new Map(),
        comparison_heatmap: false,
        outcomePptVersion: oldOutPptVersion,
        practicePptVersion: oldPrcPptVersion,
        outcome_heatmap_demo_placement: 'vertical',
        ee_heatmap_demo_placement: 'vertical',
        prac_heatmap_deep_dive: '0',
        includeDelta: false,
        unmapped_demos: new Map(),
        overall_inclusion_added: overallKeys,
        ee_threshold: demoEEThreshold.toString(),
        fill_ee_threshold_with: '',
        datacube_version: '1',
        practice_rank_score_flag: false,
        TRresDemographics: NONE,
        ee_unmapped_demos: new Map(),
        only_op_module_data: false,
        op_module_without_cr: true,
    });
    const [openConfirm, toggleConfirmModal] = useState(false);
    const [ohidArr, setOhidArr] = useState([]);
    const [levelDepth, setLevels] = useState(LEVEL_TYPE.length);
    const [modal, setModal] = useState(0);
    const [downloadType, setDownloadType] = useState('Excel');
    const [showImageVnNo, setImageVersionSrc] = useState(0);
    const [outcomeValue, setOutComeValue] = useState(null);
    const [showPractVnNo, setImgPractVersion] = useState(0);
    const [practiceValue, setPracticeValue] = useState(null);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [showSamplePage, setShowSamplePage] = useState(false);
    const [isEEModal, setIsEEModal] = useState(false);
    const {
        threshold,
        n_size,
        sort_by,
        heatmap_demographic,
        ohi4_incDemographics,
        heatMapOption,
        options,
        selectAll,
        demographicFilters,
        ingDemographicFilters,
        bubbleSort,
        level,
        score_depth,
        summaryOpt,
        exclude_demographic,
        comparison_heatmap,
        outcomePptVersion,
        practicePptVersion,
        summaryValue,
        bubbleChartValue,
        deepDiveValue,
        includeDelta,
        overall_inclusion_added,
        rrDemographics,
        empExpDemographics,
        employee_experience_demographic,
        response_rate_demographic,
        cohesionDemographics,
        unmapped_demos,
        ee_threshold,
        sort_by_ee,
        sort_by_rr,
        outcome_heatmap_demo_placement,
        // ee_heatmap_demo_placement,
        prac_heatmap_deep_dive,
        datacube_version,
        practice_rank_score_flag,
        TRresDemographics,
        ee_unmapped_demos,
    } = demographicOption;
    const isInclusionResurvey = ReportType === '6';
    const isResurveyCompHeatMap =
        ReportType === '3' && !(isIngClient || isAmmegaRpt || isThomsonRpt || ingReportContent);
    const oldDemoOption = ReportType > 3 ? demographicFilters.slice(0, 1) : demographicFilters;
    const showDemoInclusion = ohidArr.length < 3 ? demographicFilters.slice(0, 2) : demographicFilters.slice(0, 3);
    const showDemoOption = isInclusionResurvey ? showDemoInclusion : oldDemoOption;
    const demoFilterArray = demoGraphicState[0] || [];
    const [showBu, setShowBu] = useState(false);
    const [showOutcome, setShowOutcome] = useState(false);
    const [showPractice, setShowPractice] = useState(false);
    const [showScoreHeatmap, setShowScoreHeatmap] = useState(false);
    const [showCohesionHeatmap, setShowCohesionHeatmap] = useState(false);
    const [showEmployeeExperience, setShowEmployeeExperience] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [showResponseRate, setShowReponseRate] = useState(false);
    const [showValue, setShowValue] = useState('');
    const isV4 = survey_version === '4' || survey_version === '3_2';
    const isNonProfitSurvey = engagement === NON_PROFIT_SURVEY;
    const demoHideCustomArr = isNonProfitSurvey ? filterArr.splice(0, 1) : filterArr;
    const isV4Survey = survey_version === '4';
    const isThomsonOhid2024 = ohid === TR_OHID_2024 && ReportType === '1';
    // const isCohesionCheck = ReportType !== '1' && !isThomsonOhid2024;
    const thomsonPracticeRanking = !isThomsonOhid2024 && isV4Survey;
    const showIngRecentDemo = ohid === ING_RECENT_OHID;
    const showohiResponse = !isThomsonOhid2024 && !showIngRecentDemo;

    const [showOldPract, setShowOldPract] = useState(false);
    const [showScoreDialog, setShowScoreDialog] = useState(false);
    const [selectedValueOp, setSelectedValueOp] = useState(opModuleArr[0].key);

    useEffect(() => {
        if (showOutcome || showPractice || showOldPract) {
            setShowScoreDialog(true);
        }
    }, [showOutcome, showPractice, showOldPract]);

    useEffect(() => {
        if (showScoreHeatmap || showCohesionHeatmap || showEmployeeExperience || showResponseRate) {
            setOpenDialog(true);
        }
    }, [showCohesionHeatmap, showScoreHeatmap, showEmployeeExperience, showResponseRate]);

    useEffect(() => {
        if (showScoreHeatmap) {
            setShowValue(`${currentIndex + 1} / ${SCORE_HEATMAP_IMAGES.length}`);
        }
        if (showCohesionHeatmap) {
            setShowValue(`${currentIndex + 1} / ${COHESION_HEATMAP_IMAGES.length}`);
        }
        if (showEmployeeExperience) {
            setShowValue(`${currentIndex + 1} / ${EMPLOYEE_EXPERIENCE_IMAGES.length}`);
        }
        if (showResponseRate) {
            setShowValue(`${currentIndex + 1} / ${RESPONSE_RATE_IMAGES.length}`);
        }
    }, [showScoreHeatmap, showCohesionHeatmap, showEmployeeExperience, showResponseRate, currentIndex]);

    const datacubeLevelShow = role === USER_ROLES.SUPER_ADMIN;
    const demoResurveyMapped = resurveyMapping || {};

    const handleDeltaChecked = e => {
        const isCheckedVa = e.target.checked;
        if (ReportType === '3') {
            setDemographicOption({
                ...demographicOption,
                includeDelta: isCheckedVa,
            });
        }
    };
    const handleInclusionSwitch = e => {
        const isOverallChecked = e.target.checked;
        setDemographicOption({
            ...demographicOption,
            overall_inclusion_added: isOverallChecked,
        });
    };

    const opBackBtn = () => {
        setCurrentMenu(0);
    };

    const resureyMappedDemos = useMemo(() => {
        if (!!resurveyMapping && isInclusionResurvey) {
            return Object.keys(resurveyMapping).filter(item => !!resurveyMapping[item].previous_demographic);
        }
        return demoFilterArray.filter(({ code }) => !excludeOption.includes(code)).map(({ code }) => code);
    }, [resurveyMapping, isInclusionResurvey, demoFilterArray, excludeOption]);

    const filterForInclusion = useMemo(() => {
        if (ReportType === '3' && comparison_heatmap) return demoFilterArray;
        if (ReportType !== '4' && ReportType !== '6') {
            return demoFilterArray;
        }
        return demoFilterArray.filter(({ code }) => resureyMappedDemos.includes(code));
    }, [ReportType, comparison_heatmap, demoFilterArray, resureyMappedDemos]);

    const handleOhiVersion = outcomeVer => {
        setImageVersionSrc(outcomeVer);
        setOutComeValue(outcomeVer);
    };

    const handlePractVersion = practVer => {
        setImgPractVersion(practVer);
        setPracticeValue(practVer);
    };

    const handleShowOutcome = () => {
        setShowOutcome(true);
        setImageVersionSrc(1);
        setOutComeValue(1);
    };

    const handleOldPract = () => {
        setShowOldPract(true);
        setImgPractVersion(1);
        setPracticeValue(1);
    };

    const handleShowPractice = () => {
        setShowPractice(true);
        setCurrentIndex(0);
    };

    const handleScoreHeatmap = () => {
        setShowScoreHeatmap(true);
        setCurrentIndex(0);
    };

    const handleEmployeeExperience = () => {
        setShowEmployeeExperience(true);
        setCurrentIndex(0);
    };

    const handleCohesionHeatmap = () => {
        setShowCohesionHeatmap(true);
        setCurrentIndex(0);
    };

    const handleResponseRate = () => {
        setShowReponseRate(true);
        setCurrentIndex(0);
    };

    const handleImageVer = () => {
        setImageVersionSrc(0);
        setShowSamplePage(false);
        setShowOutcome(false);
        setShowPractice(false);
        setShowScoreDialog(false);
        setShowOldPract(false);
    };

    const handleNewImageVer = () => {
        setShowScoreHeatmap(false);
        setShowCohesionHeatmap(false);
        setShowEmployeeExperience(false);
        setShowReponseRate(false);
        setOpenDialog(false);
    };

    const handleImagesVer = () => {
        if (showImageVnNo === outcomeValue && isV4) {
            return OUTCOME_IMAGES_VIEW[showImageVnNo - 1];
        }
        if (showImageVnNo === outcomeValue && !isV4) {
            return OLD_OUTCOME_IMAGES_VIEW[showImageVnNo - 1];
        }
        if (showOldPract) {
            return OLD_PRACTICE_IMAGES_VIEW[showPractVnNo - 1];
        }
        if (showPractice) {
            return PRACTICE_IMAGES_VIEW[currentIndex];
        }
        if (showSamplePage) {
            return SAMPLE_IMAGES[0];
        }
        if (showScoreHeatmap) {
            return SCORE_HEATMAP_IMAGES[currentIndex];
        }
        if (showCohesionHeatmap) {
            return COHESION_HEATMAP_IMAGES[currentIndex];
        }
        if (showEmployeeExperience) {
            return EMPLOYEE_EXPERIENCE_IMAGES[currentIndex];
        }
        if (showResponseRate) {
            return RESPONSE_RATE_IMAGES[currentIndex];
        }
        return null;
    };

    const handleDisableClick = () => {
        if (showScoreHeatmap) {
            return currentIndex === SCORE_HEATMAP_IMAGES.length - 1;
        }
        if (showCohesionHeatmap) {
            return currentIndex === COHESION_HEATMAP_IMAGES.length - 1;
        }
        if (showEmployeeExperience) {
            return currentIndex === EMPLOYEE_EXPERIENCE_IMAGES.length - 1;
        }

        if (showResponseRate) {
            return currentIndex === RESPONSE_RATE_IMAGES.length - 1;
        }
        return null;
    };

    const handleBackClick = () => {
        setCurrentIndex(currentIndex - 1);
    };

    const handleNextClick = () => {
        setCurrentIndex(currentIndex + 1);
    };

    // const resetDemoState = () => {
    //     const cloneDemoState = cloneDeep(demoGraphicState[0]);
    //     cloneDemoState.forEach(demoVal => {
    //         const { options: op = [] } = demoVal;
    //         op.map(val => {
    //             val.isSelected = false;
    //             return val;
    //         });
    //         return demoVal;
    //     });
    //     return cloneDemoState;
    // };

    const handleBulkToggle = val => {
        setBulkToggle(val);
        // setDemoState([resetDemoState(), ...demoGraphicState.slice(1)]);
    };

    const validateEnterpriseReport = () => {
        return (
            (demographicFilters[0].filterOption === 'All' ||
                (demographicFilters[0].filterOption === CUSTOM && !!checkCompareArrLength(demoGraphicState[0]))) &&
            (!showOhi4Demo || ohi4_incDemographics.size !== 0)
        );
    };

    const validateExEnterpriseReport = () => {
        const filtersSelected =
            demographicFilters[0].filterOption === 'All' ||
            (demographicFilters[0].filterOption === CUSTOM && !!checkCompareArrLength(demoGraphicState[0]));
        if (!filtersSelected) return false;
        return true;
        // return checkCompareArrLength(demoGraphicState[1]) >= 2;
    };

    const validateBUReport = () => {
        const validInfo = !demographicFilters.filter(
            ({ report_name, filterOption }, index) =>
                (!index ? !showBulk && !report_name.trim() : !report_name.trim()) ||
                !filterOption ||
                (filterOption === CUSTOM && !index && !showBulk && !checkCompareArrLength(demoGraphicState[index])) ||
                (filterOption === CUSTOM && index === 1 && !checkCompareArrLength(buDemoState))
        ).length;
        if (showBulk) {
            return bulkDemoSelected && validInfo;
        }
        return validInfo;
    };

    const validateResurveyReport = () => {
        const mainDemoLength = !demographicFilters.filter(
            ({ filterOption }, index) =>
                !filterOption || (filterOption === CUSTOM && !checkCompareArrLength(demoGraphicState[index]))
        ).length;

        if (!isIngClient && !isAmmegaRpt && !ingReportContent) return mainDemoLength;

        const ingDemoLength = !ingDemographicFilters.filter(
            ({ filterOption, report_name }) =>
                !report_name.trim() || !filterOption || (filterOption === CUSTOM && !checkCompareArrLength(buDemoState))
        ).length;

        return mainDemoLength && ingDemoLength;
    };

    const validateInclusionReport = () => {
        const arr = [];
        // eslint-disable-next-line no-unused-vars
        const sliceFilter = !demographicFilters.slice(0, 3).filter(({ filterOption }, index) => {
            if (index === 0) {
                arr.push(
                    filterOption === 'All' ||
                        (filterOption === CUSTOM && checkCompareArrLength(demoGraphicState[index])) > 0
                );
            } else if (index === 2) {
                arr.push(checkChildrenArrLength(demoGraphicState[index], true));
            } else {
                arr.push(
                    filterOption === 'All' ||
                        (filterOption === CUSTOM && checkChildrenArrLength(demoGraphicState[index]))
                );
            }
            return filterOption;
        });

        demographicFilters.slice(0, 2).forEach(({ filterOption }, index) => {
            arr.push(
                filterOption === 'All' ||
                    (filterOption === CUSTOM && checkCompareArrLength(demoGraphicState[index])) > 0
            );
        });

        return !arr.includes(false);
    };

    const validateInclusionResurveyReport = () => {
        const arr = [];
        demographicFilters.slice(0, 2).forEach(({ filterOption }, index) => {
            arr.push(
                filterOption === 'All' ||
                    (filterOption === CUSTOM && checkCompareArrLength(demoGraphicState[index])) > 0
            );
        });

        return !arr.includes(false);
    };

    const validateDataCubeReport = () => {
        if (threshold === 'custom' && n_size === '') return false;
        const filtersSelected =
            demographicFilters[0].filterOption === 'All' ||
            (demographicFilters[0].filterOption === CUSTOM && !!checkCompareArrLength(demoGraphicState[0]));
        if (!filtersSelected) return false;
        return checkCompareArrLength(demoGraphicState[1]) >= 2;
    };

    const validateOpReport = () => {
        // const filtersSelected =
        //     demographicFilters[0].filterOption === 'All' ||
        //     (demographicFilters[0].filterOption === CUSTOM && !!checkCompareArrLength(demoGraphicState[0]));
        // if (!filtersSelected) return false;
        return true;
    };

    const newMappingCondition = heatmap_demographic.size !== 0 || unmapped_demos.size !== 0;

    const isActive =
        threshold &&
        (heatMapOption === NONE ||
            (heatMapOption === CUSTOM && newMappingCondition) ||
            ReportType === '5' ||
            ReportType === '9') &&
        ((ReportType === '0' && validateEnterpriseReport()) ||
            (ReportType === '1' && validateBUReport()) ||
            (ReportType === '3' && validateResurveyReport()) ||
            (ReportType === '4' && validateInclusionReport()) ||
            (ReportType === '6' && validateInclusionResurveyReport()) ||
            (ReportType === '7' && validateExEnterpriseReport()) ||
            ReportType === '8' ||
            (ReportType === '5' && validateDataCubeReport()) ||
            (ReportType === '9' && validateOpReport()));

    const getOhid = () => {
        const ohidArray = [];
        ohidArray.push(`${currentOhid} - ${year}`);
        additionalSettings.forEach(({ surveyType }) => {
            const surveyTypArr = surveyType.split('+');
            const additionalOhid =
                surveyTypArr.length >= 2 ? `${surveyTypArr[0]} - ${surveyTypArr[2]}` : surveyTypArr[0];
            ohidArray.push(additionalOhid);
        });
        setOhidArr(ohidArray);
        return ohidArray;
    };

    const handleSelectExcludeOpt = ({ name, value }, index) => {
        const cloneObj = cloneDeep(demographicOption);
        let { exclude_demographic: excludeDemo } = { ...cloneObj };
        if (excludeDemo.has(name)) {
            excludeDemo.delete(name);
        } else {
            excludeDemo.set(name, value);
        }
        if (excludeDemo.size > 0) {
            excludeDemo = new Map([...excludeDemo]);
        }
        cloneObj.selectAll = excludeDemo.size === demoGraphicState[index].length;
        setDemographicOption(cloneObj);
    };

    const handleDownloadType = ({ value }) => {
        setDownloadType(value);
        if (value === 'PPT') {
            setDemographicOption({ ...demographicOption, resource: 'ohi' });
        } else {
            setDemographicOption({ ...demographicOption, resource: 'ohi_influencer_excel' });
        }
    };

    useEffect(() => {
        if (ReportType === '3' || ReportType === '4' || ReportType === '6') {
            getOhid();
        }
        const heatMapOpInUse = heatMapOp;
        const selectAllInUse = all;
        const heatMapValInUse =
            heatMapVal && heatMapVal.size && !old_comparison_heatmap ? new Map(heatMapVal) : new Map();
        const empExpHeatmapValInUse = empExpHeatmapVal && empExpHeatmapVal.size ? new Map(empExpHeatmapVal) : new Map();
        const ohi4_incDemographicsInUse =
            ohi4_incDemographicsOld && ohi4_incDemographicsOld.size ? new Map(ohi4_incDemographicsOld) : new Map();
        const unmappedDemoInUse =
            unmappedHeatmapVal && unmappedHeatmapVal.size ? new Map(unmappedHeatmapVal) : new Map();
        const unmappedDemoEEInUse =
            unmappedHeatmapValEE && unmappedHeatmapValEE.size ? new Map(unmappedHeatmapValEE) : new Map();
        // if (ReportType === '0' && isNonProfit) {
        //     if (non_profit_report_full === '1') {
        //         heatMapOpInUse = CUSTOM;
        //         selectAllInUse = true;
        //         heatMapValInUse = new Map();
        //         demoGraphicState[0].forEach(({ code, label }) => {
        //             heatMapValInUse.set(code, label);
        //         });
        //     } else {
        //         heatMapOpInUse = NONE;
        //         selectAllInUse = false;
        //         heatMapValInUse = new Map();
        //     }
        // }
        const setThreshold = othrs === 1 ? '1' : demoThreshold.toString();
        const setEEThreshold = othrs === 1 ? '1' : demoEEThreshold.toString();
        const heatmapDemoPlacement = oldPracticePlacement || '0';
        const demoPlacementToUse = isNonProfit ? '1' : heatmapDemoPlacement;
        setDemographicOption({
            ...demographicOption,
            threshold: fill_with === '-' ? '-' : setThreshold.toString(),
            fill_threshold_with: fill_with || '',
            sort_by: sort || NONE,
            heatmap_demographic: heatMapValInUse,
            ohi4_incDemographics: new Map(ohi4_incDemographicsInUse),
            unmapped_demos: unmappedDemoInUse,
            heatMapOption: heatMapOpInUse || NONE,
            selectAll: selectAllInUse || false,
            report_name: r_name || '',
            others: othrs,
            demographicFilters: demoFilters || demographicFilters,
            ingDemographicFilters: ingDemoFilters || ingDemographicFilters,
            level: levelName,
            ee_threshold: eefill_with === '-' ? '-' : setEEThreshold.toString(),
            sort_by_ee: sort_ee || NONE,
            sort_by_rr: sort_rr || 'nsize:desc',
            outcome_heatmap_demo_placement: oldOutcomePlacement || 'vertical',
            ee_heatmap_demo_placement: oldEEPlacement || 'vertical',
            prac_heatmap_deep_dive: demoPlacementToUse,
            ee_unmapped_demos: unmappedDemoEEInUse,
            employee_experience_demographic: empExpHeatmapValInUse,
        });
        if (exclude_inclusion_gap) {
            const excludeOptionsArr = [];
            let arr = [];
            if (exclude_inclusion_gap.includes(',')) {
                arr = exclude_inclusion_gap.split(',').map(elem => elem.trim());
                for (let i = 0; i < arr.length; i += 1) {
                    excludeOptionsArr.push(demoGraphicState[0].filter(({ code }) => code === arr[i])[0]);
                }
            } else {
                arr.push(exclude_inclusion_gap);
                excludeOptionsArr.push(demoGraphicState[0].filter(({ code }) => code === exclude_inclusion_gap)[0]);
            }
            for (let i = 0; i < excludeOptionsArr.length; i += 1) {
                const { code = '', label = '' } = excludeOptionsArr[i] || {};
                exclude_demographic.set(code, label);
            }
        }
        if (isActive) {
            setLastMenu(true);
        } else {
            setLastMenu(false);
        }
        updateBulkDemo(bulkDemographics);
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (ReportType === '5' && checkCompareArrLength(demoGraphicState[1]) > 10 && !datacubeLevelShow) {
            if (level > 3) {
                setDemographicOption({
                    ...demographicOption,
                    level: 3,
                });
            }
            setLevels(3);
        } else {
            setLevels(LEVEL_TYPE.length);
        }
        // eslint-disable-next-line
    }, [demoGraphicState]);

    const updateDemoKey = () => {
        const cloneObj = cloneDeep(demographicOption);
        if (isEEModal) {
            cloneObj.ee_threshold = '0';
        } else {
            cloneObj.threshold = '0';
        }
        setIsEEModal(false);
        setDemographicOption(cloneObj);
        toggleConfirmModal(false);
    };

    const handleDemographicsData = ({ name, value }) => {
        if (name === 'threshold' && value === '0') {
            toggleConfirmModal(true);
            return;
        }
        if (name === 'ee_threshold' && value === '0') {
            setIsEEModal(true);
            toggleConfirmModal(true);
            return;
        }
        const cloneObj = cloneDeep(demographicOption);
        if (name === 'heatMapOption' && value === NONE) {
            cloneObj.heatmap_demographic = new Map();
            cloneObj.selectAll = false;
            cloneObj.sort_by = value;
            cloneObj[name] = value;
        } else {
            cloneObj[name] = name === 'level' || name === 'score_depth' ? parseInt(value, 10) : value;
        }
        setDemographicOption(cloneObj);
    };

    const handleExcludeDemoGroup = ({ name, value }) => {
        const cloneDemoObj = cloneDeep(demographicOption);
        if (name === 'heatMapOption' && value === NONE) {
            cloneDemoObj.heatmap_demographic = new Map();
            cloneDemoObj.selectAll = false;
            cloneDemoObj.sort_by = value;
            cloneDemoObj[name] = value;
        } else {
            cloneDemoObj[name] = name === 'level' ? parseInt(value, 10) : value;
        }
        setDemographicOption(cloneDemoObj);
    };

    const handlePracticeRanking = ({ name, value }) => {
        const cloneDemoObj = cloneDeep(demographicOption);
        if (name === 'practiceRanking' && value === 'true') {
            cloneDemoObj.practice_rank_score_flag = true;
        } else {
            cloneDemoObj.practice_rank_score_flag = false;
        }
        setDemographicOption(cloneDemoObj);
    };

    // const handleOhi4EmpExp = ({ name, value }) => {
    //     const cloneDemoObj = cloneDeep(demographicOption);
    //     let { employee_experience_demographic: ohi4_EmpExpState } = cloneDemoObj;
    //     if (ohi4_EmpExpState.has(name)) {
    //         ohi4_EmpExpState.delete(name);
    //     } else {
    //         ohi4_EmpExpState.set(name, value);
    //     }
    //     if (ohi4_EmpExpState.size > 0) {
    //         ohi4_EmpExpState = new Map([...ohi4_EmpExpState]);
    //         setDemographicOption(cloneDemoObj);
    //     }
    // };

    const handleSelectEmployeeExperience = ({ name, value }, index) => {
        const demoMapped = Object.values(demoResurveyMapped);
        const mappingValue = demoMapped.map(item => item.code);
        const mappingContent = mappingValue.includes(name);
        const cloneObj = cloneDeep(demographicOption);
        let { employee_experience_demographic: heatMapDemo } = { ...cloneObj };
        const { ee_unmapped_demos: heatMapUnmapped } = { ...cloneObj };
        if (ReportType === '3') {
            if (!mappingContent) {
                if (heatMapUnmapped.has(name)) {
                    heatMapUnmapped.delete(name);
                } else {
                    heatMapUnmapped.set(name, value);
                }
            }
        }

        if (heatMapDemo.has(name)) {
            heatMapDemo.delete(name);
        } else {
            heatMapDemo.set(name, value);
        }
        if (heatMapDemo.size > 0) {
            heatMapDemo = new Map([...heatMapDemo]);
        }
        cloneObj.selectAll = heatMapDemo.size === demoGraphicState[index].length;
        setDemographicOption(cloneObj);
    };

    const handleOhi4RR = ({ name, value }) => {
        const cloneDemoObj = cloneDeep(demographicOption);
        let { response_rate_demographic: ohi4_RRState } = cloneDemoObj;
        if (ohi4_RRState.has(name)) {
            ohi4_RRState.delete(name);
        } else {
            ohi4_RRState.set(name, value);
        }
        if (ohi4_RRState.size > 0) {
            ohi4_RRState = new Map([...ohi4_RRState]);
            setDemographicOption(cloneDemoObj);
        }
    };

    const handleBubbleDemoGroup = ({ name, value }) => {
        const cloneDemoBubbleObj = cloneDeep(demographicOption);
        if (name === 'bubbleChartValue' && value === NONE) {
            cloneDemoBubbleObj.heatmap_demographic = new Map();
            cloneDemoBubbleObj.selectAll = false;
            cloneDemoBubbleObj.sort_by = value;
            cloneDemoBubbleObj[name] = value;
        } else {
            cloneDemoBubbleObj[name] = name === 'level' ? parseInt(value, 10) : value;
        }
        setDemographicOption(cloneDemoBubbleObj);
    };

    const handleDemoDeepDiveGroup = ({ name, value }) => {
        const cloneDemoDiveObj = cloneDeep(demographicOption);
        if (name === 'demoDeepDiveGroup' && value === NONE) {
            cloneDemoDiveObj.heatmap_demographic = new Map();
            cloneDemoDiveObj.selectAll = false;
            cloneDemoDiveObj.sort_by = value;
            cloneDemoDiveObj[name] = value;
        } else {
            cloneDemoDiveObj.deepDiveValue = value;
        }
        setDemographicOption(cloneDemoDiveObj);
    };

    const handleSelect = ({ name, value }, index) => {
        const demoMapped = Object.values(demoResurveyMapped);
        const mappingValue = demoMapped.map(item => item.code);
        const mappingContent = mappingValue.includes(name);
        const cloneObj = cloneDeep(demographicOption);
        let { heatmap_demographic: heatMapDemo } = { ...cloneObj };
        const { unmapped_demos: heatMapUnmapped } = { ...cloneObj };
        if (ReportType === '3') {
            if (!mappingContent) {
                if (heatMapUnmapped.has(name)) {
                    heatMapUnmapped.delete(name);
                } else {
                    heatMapUnmapped.set(name, value);
                }
            }
        }

        if (heatMapDemo.has(name)) {
            heatMapDemo.delete(name);
        } else {
            heatMapDemo.set(name, value);
        }
        if (heatMapDemo.size > 0) {
            heatMapDemo = new Map([...heatMapDemo]);
        }
        cloneObj.selectAll = heatMapDemo.size === demoGraphicState[index].length;
        setDemographicOption(cloneObj);
    };

    const handleOhi4HeatMap = ({ name, value }) => {
        const cloneObj = cloneDeep(demographicOption);
        let { ohi4_incDemographics: ohi4_incDemographicsState } = cloneObj;
        if (ohi4_incDemographicsState.has(name)) {
            ohi4_incDemographicsState.delete(name);
        } else {
            ohi4_incDemographicsState.set(name, value);
        }
        if (ohi4_incDemographicsState.size > 0) {
            ohi4_incDemographicsState = new Map([...ohi4_incDemographicsState]);
        }
        setDemographicOption(cloneObj);
    };

    const getSortStr = sort_str => {
        if (sort_str === NONE) return '';
        return sort_str;
    };

    const handleNext = () => {
        const { threshold: thresholdState } = demographicOption;
        const fill_threshold = thresholdState === '-' ? '-' : '';
        const fill_threshold_EE = ee_threshold === '-' ? '-' : '';
        const thrshld = thresholdState === '0' ? 1 : demoThreshold;
        const thrshldEE = ee_threshold === '0' ? 0 : backEE_threshold;
        const sortData = getSortStr(sort_by);
        const sortDataRR = getSortStr(sort_by_rr);
        const sortDataEE = getSortStr(sort_by_ee);
        const opt = { ...reportApiParams.options, ...options };
        let comparison_names = '';
        let bu_comparison_names = '';
        let bu_reportname = '';
        let report_names = rprt_name;
        if (demographicFilters.length === 2 && ReportType === '1') {
            comparison_names = demographicFilters[1].report_name;
            report_names = demographicFilters[0].report_name;
        }
        if (isIngClient || isAmmegaRpt || isThomsonRpt || ingReportContent) {
            const ingDemo = ingDemographicFilters[0] || '';
            bu_comparison_names = ingDemo !== '' ? ingDemographicFilters[0].report_name : '';
            bu_reportname = rprt_name;
        }
        const dataCubeVal = threshold === 'custom' ? n_size : threshold;
        const excludeMapObj = Object.fromEntries(exclude_demographic);
        const excludeKeys = Object.keys(excludeMapObj) || [''];
        let excludeGapText = '';
        excludeGapText = excludeKeys.length > 1 ? excludeKeys.join(',') : excludeKeys[0];
        if (excludeGapText) {
            excludeGapText = defaultExcludeGaps ? excludeGapText + ',' + defaultExcludeGaps : excludeGapText;
        } else {
            excludeGapText = defaultExcludeGaps;
        }
        const updatedBenchmarks = cloneDeep(currentBulkDemo);
        const openedBenchmark = updatedBenchmarks.findIndex(({ isOpen: oldOpen }) => oldOpen);
        if (openedBenchmark !== -1) {
            updatedBenchmarks[openedBenchmark].isOpen = false;
        }
        updateBulkFilters(updatedBenchmarks);
        submitReport({
            ...demographicOption,
            options: opt,
            sort_by: sortData,
            sort_by_ee: sortDataEE,
            sort_by_rr: sortDataRR,
            fill_threshold_with: fill_threshold,
            threshold: thrshld,
            others: threshold === '1' ? 1 : 0,
            comparison_names,
            bu_comparison_names,
            bu_reportname,
            report_name: report_names,
            bubbleSort,
            n_size: parseInt(dataCubeVal, 10),
            exclude_inclusion_gap: excludeGapText,
            fill_ee_threshold_with: fill_threshold_EE,
            ee_threshold: thrshldEE,
        });
    };

    const handleBulkReport = () => {
        if (showBulk && !showBu && isDemoChecked) {
            return setShowBu(true);
        }
        return handleNext();
    };

    const handleBulkNext = () => {
        if (ReportType === '1') {
            handleBulkReport();
        }
        if (ReportType !== '1') {
            handleNext();
        }
    };

    const handleSelectAllHeatMap = ({ name }) => {
        const cloneObj = cloneDeep(demographicOption);
        cloneObj[name] = !selectAll;
        if (cloneObj[name]) {
            const demoMapped = Object.values(demoResurveyMapped);
            const mappingValues = demoMapped.map(item => item.code);
            if (mappingValues.length > 0) {
                const resurveyDemoInUse = ReportType === '3' && mappingValues.length > 0 && resurveyDemographics;
                const demosInUse = ReportType !== '4' && ReportType !== '6' && resurveyDemoInUse;
                demosInUse.forEach(({ code, label }) => cloneObj.heatmap_demographic.set(code, label));
            } else {
                cloneObj.heatmap_demographic = new Map();
            }
            if (ReportType === '3') {
                const mappingValue = demoMapped.map(item => item.code);
                const demoUnmappedSelect = demoGraphicState[0].filter(({ code }) => !mappingValue.includes(code));
                demoUnmappedSelect.forEach(({ code, label }) => cloneObj.unmapped_demos.set(code, label));
            }
        } else {
            cloneObj.heatmap_demographic = new Map();
            cloneObj.unmapped_demos = new Map();
        }
        setDemographicOption(cloneObj);
    };

    const handleSelectAllEEHeatMap = ({ name }) => {
        const cloneObj = cloneDeep(demographicOption);
        cloneObj[name] = !selectAll;
        if (cloneObj[name]) {
            const demoMapped = Object.values(demoResurveyMapped);
            const mappingValues = demoMapped.map(item => item.code);
            if (mappingValues.length > 0) {
                const resurveyDemoInUse = ReportType === '3' && mappingValues.length > 0 && resurveyDemographics;
                const demosInUse = ReportType !== '4' && ReportType !== '6' && resurveyDemoInUse;
                demosInUse.forEach(({ code, label }) => cloneObj.employee_experience_demographic.set(code, label));
            } else {
                cloneObj.employee_experience_demographic = new Map();
            }
            if (ReportType === '3') {
                const mappingValue = demoMapped.map(item => item.code);
                const demoUnmappedSelect = demoGraphicState[0].filter(({ code }) => !mappingValue.includes(code));
                demoUnmappedSelect.forEach(({ code, label }) => cloneObj.ee_unmapped_demos.set(code, label));
            }
        } else {
            cloneObj.employee_experience_demographic = new Map();
            cloneObj.ee_unmapped_demos = new Map();
        }
        setDemographicOption(cloneObj);
    };

    const handleIngDemoFilters = ({ name, value }, index) => {
        const demoFilter = [...ingDemographicFilters];
        demoFilter[index][name] = value;
        if (name === 'filterOption' && value === 'All') {
            if (!index) {
                buDemoState.forEach(demoVal => {
                    const { isOpen, options: op } = demoVal;
                    if (isOpen) {
                        demoVal.isOpen = !isOpen;
                    }
                    op.forEach(val => {
                        val.isSelected = false;
                    });
                });
                setBuDemoState(buDemoState);
            }
        }

        setDemographicOption({
            ...demographicOption,
            ingDemographicFilters: demoFilter,
        });
    };

    const handleDemographicFilters = ({ name, value }, index) => {
        const demoFilter = [...demographicFilters];
        demoFilter[index][name] = value;
        if (name === 'filterOption' && value === 'All') {
            if (demoGraphicState[index]) {
                demoGraphicState[index].forEach(demoVal => {
                    const { isOpen, options: op } = demoVal;
                    if (isOpen) {
                        demoVal.isOpen = !isOpen;
                    }
                    op.forEach(val => {
                        val.isSelected = false;
                    });
                });
                handleDemograhicState(demoGraphicState[index], index);
            }
            if (ReportType === '1' && index === 1) {
                buDemoState.forEach(demoVal => {
                    const { isOpen, options: op } = demoVal;
                    if (isOpen) {
                        demoVal.isOpen = !isOpen;
                    }
                    op.forEach(val => {
                        val.isSelected = false;
                    });
                });
                setBuDemoState(buDemoState);
            }
        }

        setDemographicOption({
            ...demographicOption,
            demographicFilters: demoFilter,
        });
    };

    const handleDemographicFiltersModule = ({ name, value }, index) => {
        const demoFilter = [...demographicFilters];
        demoFilter[index][name] = value;
        setSelectedValueOp(value);
        let onlyOpModuleData = false;
        let opModuleWithoutCr1 = false;

        switch (value) {
            case '1':
                onlyOpModuleData = false;
                opModuleWithoutCr1 = true;
                break;
            case '2':
                onlyOpModuleData = false;
                opModuleWithoutCr1 = false;
                break;
            case '3':
                onlyOpModuleData = true;
                opModuleWithoutCr1 = true;
                break;
            case 'Custom':
                onlyOpModuleData = false;
                opModuleWithoutCr1 = true;
                break;
            default:
                onlyOpModuleData = false;
                opModuleWithoutCr1 = false;
                break;
        }
        if (name === 'filterOption' && value === 'All') {
            if (demoGraphicState[index]) {
                demoGraphicState[index].forEach(demoVal => {
                    const { isOpen, options: op } = demoVal;
                    if (isOpen) {
                        demoVal.isOpen = !isOpen;
                    }
                    op.forEach(val => {
                        val.isSelected = false;
                    });
                });
                handleDemograhicState(demoGraphicState[index], index);
            }
            if (ReportType === '1' && index === 1) {
                buDemoState.forEach(demoVal => {
                    const { isOpen, options: op } = demoVal;
                    if (isOpen) {
                        demoVal.isOpen = !isOpen;
                    }
                    op.forEach(val => {
                        val.isSelected = false;
                    });
                });
                setBuDemoState(buDemoState);
            }
        }

        setDemographicOption({
            ...demographicOption,
            demographicFilters: demoFilter,
            only_op_module_data: onlyOpModuleData,
            op_module_without_cr: opModuleWithoutCr1,
        });
    };

    const showDemographic = (filterOption, index) => {
        return (
            filterOption === CUSTOM &&
            demoGraphicState[index] &&
            demoGraphicState[index].length &&
            ((ReportType === '1' && !index) || ReportType !== '1')
        );
    };

    const handleOrgLevelToggle = () => {
        setOrgLevelToggle(orgLevelBu === '1' ? '0' : '1');
    };

    const renderOptionData = () => {
        const { userRole = '' } = AuthStore;
        const optionForMain =
            userRole === USER_ROLES.CLIENT || userRole === USER_ROLES.CST || userRole === USER_ROLES.USER
                ? option
                : adminOption;
        const optionUsed = ReportType === '4' || ReportType === '6' ? inclusionDemoOptions : optionForMain;
        const choseOption = ReportType === '5' ? dataCubeDemoOptions : optionUsed;
        const optionToUse = ReportType === '7' ? exOption : choseOption;
        const optionToUseItem = isNonProfitSurvey || isThomsonOhid2024 ? optionToUse.splice(0, 1) : optionToUse;
        return optionToUseItem.map(({ label, key }, i) => {
            if (key === '0') {
                return (
                    <Tooltip placement="top" arrow title={DEMO_OPT_NOTE(demoThreshold)} key={`${key}${i}`}>
                        <FormControlLabel value={key.toString()} control={<Radio disableRipple />} label={label} />
                    </Tooltip>
                );
            }
            return (
                <FormControlLabel
                    key={`${key}${i}`}
                    value={key.toString()}
                    control={<Radio disableRipple />}
                    label={label}
                />
            );
        });
    };
    // Employe Experiance Threshold Options
    const renderEEOptionData = () => {
        if (!isV4) return null;
        if (!(ReportType === '0' || ReportType === '1' || ReportType === '3')) return null;
        const { userRole = '' } = AuthStore;
        const optionForMain =
            userRole === USER_ROLES.CLIENT || userRole === USER_ROLES.CST || userRole === USER_ROLES.USER
                ? eeOption
                : eeAdminOption;
        // const optionToEEItem = isNonProfitSurvey ? optionForMain.splice(0, 1) : optionForMain;
        // new
        const optionToEEItem = isNonProfitSurvey || isThomsonOhid2024 ? optionForMain.splice(0, 1) : optionForMain;
        return (
            <div>
                <span className="title">{DEMOGRAPHIC_EE_THRESHOLD}</span>

                <div className={c('reportWrapper', { allReportWrap: ReportType === '4' })}>
                    <FormGroup classes={{ root: 'radioGroup' }}>
                        <RadioGroup
                            aria-label="gender"
                            name="ee_threshold"
                            value={`${ee_threshold}`}
                            onChange={({ target }) => handleDemographicsData(target)}
                        >
                            {optionToEEItem.map(({ label, key }, i) => {
                                if (key === '0') {
                                    return (
                                        <Tooltip
                                            placement="top"
                                            arrow
                                            title={DEMO_OPT_NOTE(demoThreshold)}
                                            key={`${key}${i}`}
                                        >
                                            <FormControlLabel
                                                value={key.toString()}
                                                control={<Radio disableRipple />}
                                                label={label}
                                            />
                                        </Tooltip>
                                    );
                                }
                                return (
                                    <FormControlLabel
                                        key={`${key}${i}`}
                                        value={key.toString()}
                                        control={<Radio disableRipple />}
                                        label={label}
                                    />
                                );
                            })}
                        </RadioGroup>
                    </FormGroup>
                </div>
                <div className="subInfo">{`${DEMOGRAPHIC_OPTIONS_SUBINFO} ${backEE_threshold} ${DEMOGRAPHIC_OPTIONS_SUBINFO_2}`}</div>
            </div>
        );
    };
    const renderReportDemographics = () => {
        return demographicFilters.slice(1).map(({ filterOption }, index) => {
            const text = index === 1 ? DEMOGRAPHIC_INSIGHTS : PRACTICE_SCORE_BY_DEMOGRAPHIC;
            const showText = index === 0 ? BUBBLE_CHART_CUSTOMISATION : text;
            const filteredDemo = demoGraphicState[index + 1].filter(({ code }) => !excludeOption.includes(code));
            if (!full_inclusion_access && index < 2) return null;
            if (index === 2 && surveyTypeIa === PULSE) return null;
            return (
                <div className="rightSideFilters heatMapWrapper" key={index + 1}>
                    <div>
                        <div className="samplePageSection">
                            <span className="title">{showText}</span>
                            <span className="sampleText" onClick={() => setModal(index + 1)}>
                                <img src={viewIcon} alt="" />
                                {VIEW_SAMPLE_SLIDE}
                            </span>
                            {/* {index === 0 ? (
                                <span className="sampleText" onClick={() => window.open('/scores', '_blank')}>
                                    {VIEW_DEMOGRAPHIC_SCORES}
                                </span>
                            ) : null} */}
                        </div>
                        {index === 2 ? (
                            <Fragment>
                                <div className="subInfo">{INCLUSION_PRACTICEBY_DEMO_SUB_HEADING}</div>
                            </Fragment>
                        ) : null}
                        {[0, 1].includes(index) ? (
                            <div className="subInfo">{index === 1 ? INSIGHT_INFO : BUBBLE_CHART_SUBHEADING[0]}</div>
                        ) : null}
                        {index === 0 ? <div className="subInfo">{BUBBLE_CHART_SUBHEADING[1]}</div> : null}
                        {index === 2 ? (
                            <div className={c('reportWrapper', { allReportWrap: ReportType === '4' })}>
                                <FormGroup classes={{ root: 'radioGroup' }}>
                                    <RadioGroup
                                        aria-label="filterOption"
                                        name="filterOption"
                                        value={filterOption}
                                        onChange={({ target }) => handleDemographicFilters(target, index + 1)}
                                    >
                                        {bubbleChartOptions.map(({ label, key }, i) => (
                                            <FormControlLabel
                                                key={`${key}${i}`}
                                                value={key}
                                                control={<Radio disableRipple />}
                                                label={index === 2 && i === 0 ? ALL_DEMOGRAPHICS : label}
                                            />
                                        ))}
                                    </RadioGroup>
                                </FormGroup>
                            </div>
                        ) : null}

                        {index === 0 ? (
                            <div className={c('reportWrapper', { allReportWrap: ReportType === '4' })}>
                                <FormGroup classes={{ root: 'radioGroup' }}>
                                    <RadioGroup
                                        aria-label="filterOption"
                                        name="bubbleChartValue"
                                        value={bubbleChartValue}
                                        onChange={({ target }) => handleBubbleDemoGroup(target)}
                                    >
                                        {summaryOptions.map(({ label, key }, i) => (
                                            <FormControlLabel
                                                key={`${key}${i}`}
                                                value={key}
                                                control={<Radio disableRipple />}
                                                label={index === 2 && i === 0 ? ALL_DEMOGRAPHICS : label}
                                            />
                                        ))}
                                    </RadioGroup>
                                </FormGroup>
                            </div>
                        ) : null}

                        {bubbleChartValue === CUSTOM && index === 0 && (
                            <Demographics
                                defaultSettings={defaultSettings}
                                limitParent={index + 1 === 1 ? 6 : 1}
                                limitChild={index + 1 === 1 ? 13 : 2}
                                showOption={index === 1}
                                demographics={filteredDemo}
                                updateDemographics={e => handleDemograhicState(e, index + 1)}
                                hiddenOptions={index + 1 === 1 ? defaultExcludeGapsArr : []}
                                excludeDemographic={index + 1 === 1 ? Object.fromEntries(exclude_demographic) : {}}
                            />
                        )}

                        {index === 1 ? (
                            <div className={c('reportWrapper', { allReportWrap: ReportType === '4' })}>
                                <FormGroup classes={{ root: 'radioGroup' }}>
                                    <RadioGroup
                                        aria-label="demoDeepDive"
                                        name="deepDiveValue"
                                        value={deepDiveValue}
                                        onChange={({ target }) => handleDemoDeepDiveGroup(target)}
                                    >
                                        {summaryOptions.map(({ label, key }, i) => (
                                            <FormControlLabel
                                                key={`${key}${i}`}
                                                value={key}
                                                control={<Radio disableRipple />}
                                                label={label}
                                            />
                                        ))}
                                    </RadioGroup>
                                </FormGroup>
                            </div>
                        ) : null}

                        {deepDiveValue === CUSTOM && ![0, 2].includes(index) && (
                            <Demographics
                                defaultSettings={defaultSettings}
                                limitParent={index + 1 === 1 ? 7 : 1}
                                limitChild={index + 1 === 1 ? 7 : 2}
                                showOption={index === 1}
                                demographics={filteredDemo}
                                updateDemographics={e => handleDemograhicState(e, index + 1)}
                                hiddenOptions={index + 1 === 1 ? defaultExcludeGapsArr : []}
                                excludeDemographic={index + 1 === 1 ? Object.fromEntries(exclude_demographic) : {}}
                            />
                        )}

                        {filterOption === CUSTOM && ![0, 1].includes(index) ? (
                            <Demographics
                                defaultSettings={defaultSettings}
                                noChild={index === 2}
                                demographics={filteredDemo}
                                updateDemographics={e => handleDemograhicState(e, index + 1)}
                                hideHeading={index === 2}
                            />
                        ) : null}
                    </div>
                </div>
            );
        });
    };

    const renderOhi4Option = () => {
        if (hideHeatMap) {
            return null;
        }
        if (isNonProfit) {
            return null;
        }
        const showCohesion =
            ReportType !== '1' && keepReportOption(build_your_report, 'Cohesion_Contents') && !isIngClient;
        return (
            <Fragment>
                {(ReportType === '0' || ReportType === '1' || ReportType === '3') && isV4 ? (
                    <div className="rightSideFilters heatMapWrapper rightFilter">
                        {keepReportOption(build_your_report, 'Practice_rank_score_Contents') && thomsonPracticeRanking && (
                            <div>
                                {!showIngRecentDemo && (
                                    <div className={c('ohiContent', { enterpriseWrap: ReportType !== '4' })}>
                                        <span className="practiceTitle">{PRACTICE_HEAD}</span>
                                        <div className="reportWrapper">
                                            <FormGroup classes={{ root: 'radioGroup' }}>
                                                <RadioGroup
                                                    aria-label="practiceRanking"
                                                    name="practiceRanking"
                                                    value={practice_rank_score_flag}
                                                    onChange={({ target }) => handlePracticeRanking(target)}
                                                >
                                                    {practiceRankingOptions.map(({ label, key }, i) => (
                                                        <FormControlLabel
                                                            key={`${key}${i}`}
                                                            value={key}
                                                            control={<Radio />}
                                                            label={label}
                                                        />
                                                    ))}
                                                </RadioGroup>
                                            </FormGroup>
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                        {/* {keepReportOption(build_your_report, 'Resp_rate_Contents') && ( */}
                        {keepReportOption(build_your_report, 'Resp_rate_Contents') && showohiResponse && (
                            <div className={c('ohiContent', { enterpriseWrap: ReportType !== '4' })}>
                                <div>
                                    <div className="samplePageSection">
                                        <span className="title">{OHI_4_HEADER[0]}</span>
                                        {ReportType === '0' || ReportType === '1' ? (
                                            <span className="sampleText" onClick={handleResponseRate}>
                                                <img src={viewIcon} alt="" />
                                                {VIEW_SAMPLE_SLIDE}
                                            </span>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="reportWrapper">
                                    <FormGroup classes={{ root: 'radioGroup' }}>
                                        <RadioGroup
                                            aria-label="rrDemographics"
                                            name="rrDemographics"
                                            value={rrDemographics}
                                            onChange={({ target }) => handleExcludeDemoGroup(target)}
                                        >
                                            {rrDemographicOptions.map(({ label, key }, i) => (
                                                <FormControlLabel
                                                    key={`${key}${i}`}
                                                    value={key}
                                                    control={<Radio />}
                                                    label={label}
                                                />
                                            ))}
                                        </RadioGroup>
                                    </FormGroup>
                                </div>

                                <div className="subInfo cohesionInfo">
                                    <div>{OHI_4_RPT_TEXT[0][0]}</div>
                                    <div>{OHI_4_RPT_TEXT[0][1]}</div>
                                </div>
                                {rrDemographics === CUSTOM && (
                                    <DemographicFilter
                                        defaultSettings={defaultSettings}
                                        heatMapOption={CUSTOM}
                                        demoGraphicState={ohi4DemoState[0]}
                                        heatmap_demographic={response_rate_demographic}
                                        handleSelect={handleOhi4RR}
                                        showSelectAll={false}
                                        unmapped_demos={unmapped_demos}
                                        showSelect
                                        sort_by={sort_by_rr}
                                        sort_name="sort_by_rr"
                                        handleDemographicsData={handleDemographicsData}
                                    />
                                )}
                            </div>
                        )}

                        {showCohesion && (
                            <div className={c('ohiContent', { enterpriseWrap: ReportType !== '4' })}>
                                <div>
                                    <div className="samplePageSection">
                                        <span className="title">{OHI_4_HEADER[1]}</span>
                                        {ReportType === '0' || ReportType === '1' ? (
                                            <span className="sampleText" onClick={handleCohesionHeatmap}>
                                                <img src={viewIcon} alt="" />
                                                {VIEW_SAMPLE_SLIDE}
                                            </span>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="reportWrapper">
                                    <FormGroup classes={{ root: 'radioGroup' }}>
                                        <RadioGroup
                                            aria-label="cohesionDemographics"
                                            name="cohesionDemographics"
                                            value={cohesionDemographics}
                                            onChange={({ target }) => handleExcludeDemoGroup(target)}
                                        >
                                            {cohesionDemoOptions.map(({ label, key }, i) => (
                                                <FormControlLabel
                                                    key={`${key}${i}`}
                                                    value={key}
                                                    control={<Radio />}
                                                    label={label}
                                                />
                                            ))}
                                        </RadioGroup>
                                    </FormGroup>
                                </div>

                                <div className="subInfo cohesionInfo">
                                    <div>{OHI_4_RPT_TEXT[1][0]}</div>
                                    <div>{OHI_4_RPT_TEXT[1][1]}</div>
                                </div>

                                {cohesionDemographics === CUSTOM && (
                                    <Demographics
                                        defaultSettings={defaultSettings}
                                        showOption={false}
                                        demographics={ohi4DemoState[1]}
                                        updateDemographics={e => handleOhi4DemograhicState(e, 1)}
                                        hiddenOptions={[]}
                                        limitParent={10}
                                        limitChild={10}
                                    />
                                )}
                            </div>
                        )}

                        {keepReportOption(build_your_report, 'Employee_Experience_Contents') && showohiResponse && (
                            <div className={c('ohiContent', { enterpriseWrap: ReportType !== '4' })}>
                                <div>
                                    <div className="samplePageSection">
                                        <span className="title">{OHI_4_HEADER[2]}</span>
                                        {ReportType === '0' || ReportType === '1' ? (
                                            <span className="sampleText" onClick={handleEmployeeExperience}>
                                                <img src={viewIcon} alt="" />
                                                {VIEW_SAMPLE_SLIDE}
                                            </span>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="reportWrapper">
                                    <FormGroup classes={{ root: 'radioGroup' }}>
                                        <RadioGroup
                                            aria-label="empExpDemographics"
                                            name="empExpDemographics"
                                            value={empExpDemographics}
                                            onChange={({ target }) => handleExcludeDemoGroup(target)}
                                        >
                                            {empExpDemoOptions.map(({ label, key }, i) => (
                                                <FormControlLabel
                                                    key={`${key}${i}`}
                                                    value={key}
                                                    control={<Radio />}
                                                    label={label}
                                                />
                                            ))}
                                        </RadioGroup>
                                    </FormGroup>
                                </div>
                                <div className="subInfo">
                                    <div>{OHI_4_RPT_TEXT[2][0]}</div>
                                    <div>{OHI_4_RPT_TEXT[2][1]}</div>
                                </div>
                                {/* {empExpDemographics === CUSTOM && (
                                    <div className="heatmapPlacement">
                                        <div className="headerTitle">
                                            Employee Experience dimensions placement on heatmap table
                                        </div>
                                        <div
                                            className={c('reportWrapper viewContent', {
                                                allReportWrap: ReportType === '4',
                                            })}
                                        >
                                            <FormGroup
                                                data-test-id="comparisonBubble"
                                                classes={{ root: 'radioGroup colRadio' }}
                                            >
                                                <div>
                                                    <RadioGroup
                                                        aria-label="non_profit_report_full"
                                                        name="ee_heatmap_demo_placement"
                                                        value={ee_heatmap_demo_placement}
                                                        onChange={({ target }) => handleDemographicsData(target)}
                                                        className="outComeVersion"
                                                    >
                                                        {[
                                                            { title: 'Vertical axis', key: 'vertical' },
                                                            { title: 'Horizontal axis', key: 'horizontal' },
                                                        ].map(({ title = '', key }) => (
                                                            <div className="outcomeContent" key={key}>
                                                                <FormControlLabel
                                                                    key={key}
                                                                    value={key}
                                                                    control={<Radio disableRipple />}
                                                                    label={title}
                                                                    className="outcomeText"
                                                                />
                                                            </div>
                                                        ))}
                                                    </RadioGroup>
                                                </div>
                                            </FormGroup>
                                        </div>
                                    </div>
                                )} */}
                                {/* {empExpDemographics === CUSTOM && (
                                    <DemographicFilter
                                        defaultSettings={defaultSettings}
                                        heatMapOption={CUSTOM}
                                        demoGraphicState={ohi4DemoState[2]}
                                        heatmap_demographic={employee_experience_demographic}
                                        handleSelect={handleOhi4EmpExp}
                                        showSelectAll={false}
                                        unmapped_demos={unmapped_demos}
                                        showSelect
                                        sort_by={sort_by_ee}
                                        sort_name="sort_by_ee"
                                        handleDemographicsData={handleDemographicsData}
                                    />
                                )} */}
                                {empExpDemographics === CUSTOM && (
                                    <DemographicFilter
                                        defaultSettings={defaultSettings}
                                        heatMapOption={CUSTOM}
                                        sort_by={sort_by_ee}
                                        sort_name="sort_by_ee"
                                        handleDemographicsData={handleDemographicsData}
                                        selectAll={selectAll}
                                        handleSelectAllHeatMap={handleSelectAllEEHeatMap}
                                        demoGraphicState={filterForInclusion}
                                        // heatmap_demographic={heatmap_demographic}
                                        heatmap_demographic={employee_experience_demographic}
                                        // heatmap_demographic={heatmap_demographic}
                                        // handleSelect={handleSelect}
                                        handleSelect={handleSelectEmployeeExperience}
                                        hiddenOptions={[]}
                                        showSelectAll={ReportType < 4}
                                        showSelect={ReportType < 4}
                                        isResurveyCompHeatMap={isResurveyCompHeatMap}
                                        resurveyMapping={demoResurveyMapped}
                                        showResurveyReportOnly={ReportType === '3'}
                                        unmapped_demos={ee_unmapped_demos}
                                        // NEW
                                        showEEReport="true"
                                    />
                                )}
                            </div>
                        )}
                    </div>
                ) : null}
            </Fragment>
        );
    };

    const renderHeatmapRadioBtns = () => {
        if (heatMapOption !== CUSTOM) return null;
        if (!(ReportType === '0' || ReportType === '1')) return null;
        if (isNonProfit) return null;
        return (
            <div className="heatmapPlacement">
                <div className="headerTitle">{OUTCOME_PLACEMENT_TEXT}</div>
                <div className="reportWrapper viewContent">
                    <FormGroup data-test-id="comparisonBubble" classes={{ root: 'radioGroup colRadio' }}>
                        <div>
                            <RadioGroup
                                aria-label="non_profit_report_full"
                                name="outcome_heatmap_demo_placement"
                                value={outcome_heatmap_demo_placement}
                                onChange={({ target }) => handleDemographicsData(target)}
                                className="outComeVersion"
                            >
                                {[
                                    {
                                        title: VERTICAL_AXIS,
                                        key: 'vertical',
                                    },
                                    {
                                        title: HORIZONTAL_AXIS,
                                        key: 'horizontal',
                                    },
                                ].map(({ title = '', key }) => (
                                    <div className="outcomeContent" key={key}>
                                        <FormControlLabel
                                            key={key}
                                            value={key}
                                            control={<Radio disableRipple />}
                                            label={title}
                                            className="outcomeText"
                                        />
                                    </div>
                                ))}
                            </RadioGroup>
                        </div>
                    </FormGroup>
                    {/* <span className="sampleText" onClick={() => handleShowOutcome()}>
                                            <img src={viewIcon} alt="" />
                                            {VIEW_SAMPLE_SLIDE}
                                        </span> */}
                    <div className="subInfo">{HORIZONTAL_TEXT}</div>
                </div>
                <div className="headerTitle">{PRACTICE_TABLE_TEXT}</div>
                <div className="reportWrapper viewContent">
                    <FormGroup data-test-id="comparisonBubble" classes={{ root: 'radioGroup colRadio' }}>
                        <RadioGroup
                            aria-label="non_profit_report_full"
                            name="prac_heatmap_deep_dive"
                            value={prac_heatmap_deep_dive}
                            onChange={({ target }) => handleDemographicsData(target)}
                            className="outComeVersion"
                        >
                            {[
                                {
                                    title: PRACTICE_SCREEN,
                                    key: '0',
                                },
                                {
                                    title: CATEGORY_BASED,
                                    key: '1',
                                },
                            ].map(({ title, key }) => (
                                <div className="outcomeContent" key={key}>
                                    <FormControlLabel
                                        key={key}
                                        value={key}
                                        control={<Radio disableRipple />}
                                        label={title}
                                        className="outcomeText"
                                    />
                                </div>
                            ))}
                        </RadioGroup>
                    </FormGroup>
                </div>
            </div>
        );
    };

    const renderImageDialog = () => {
        return (
            <Fragment>
                <div>
                    {showScoreDialog ? (
                        <div className="outcomeVerSection">
                            <Dialog open={showScoreDialog} maxWidth="md" fullWidth className="dialogOutCome">
                                <div className="imageIcons">
                                    <IconButton onClick={handleImageVer} className="imageIconBtn">
                                        <CloseIcon />
                                    </IconButton>
                                </div>

                                <div className="dialogHeading">
                                    <div>{SAMPLE_SLIDE}</div>
                                </div>

                                {showOutcome && (
                                    <div className="outcomeImages">
                                        {[1, 2, 3].map(outcomeVer => {
                                            return (
                                                <List
                                                    onClick={() => handleOhiVersion(outcomeVer)}
                                                    value={outcomeValue}
                                                    className="outcomeList"
                                                    key={outcomeVer}
                                                >
                                                    <ListItem
                                                        className={c('outComeListItem', {
                                                            active: outcomeVer === outcomeValue,
                                                        })}
                                                    >
                                                        {`${VERSION_TEXT} ${outcomeVer}`}
                                                    </ListItem>
                                                </List>
                                            );
                                        })}
                                    </div>
                                )}
                                {showOldPract && (
                                    <div className="outcomeImages">
                                        {[1, 2].map(practVer => {
                                            return (
                                                <List
                                                    onClick={() => handlePractVersion(practVer)}
                                                    value={practiceValue}
                                                    className="outcomeList"
                                                    key={practVer}
                                                >
                                                    <ListItem
                                                        className={c('outComeListItem', {
                                                            active: practVer === practiceValue,
                                                        })}
                                                    >
                                                        {`${VERSION_TEXT} ${practVer}`}
                                                    </ListItem>
                                                </List>
                                            );
                                        })}
                                    </div>
                                )}

                                <DialogContent className="DialogOutcomeContent">
                                    {showPractice && (
                                        <div className="showVal">
                                            {currentIndex + 1}/ {PRACTICE_IMAGES_VIEW.length}
                                        </div>
                                    )}
                                    <div className="dialogContent">
                                        <img src={handleImagesVer()} alt="" className="dialogImg" />
                                    </div>
                                </DialogContent>

                                {showPractice && (
                                    <DialogActions className="dialogActionIcon">
                                        <div className="showIcons">
                                            <div className="showBtn">
                                                <IconButton
                                                    onClick={handleBackClick}
                                                    disabled={currentIndex === 0}
                                                    className="currentIconBtn"
                                                >
                                                    <ArrowBackIcon />
                                                </IconButton>
                                            </div>
                                            <div className="showBtn">
                                                <IconButton
                                                    onClick={handleNextClick}
                                                    disabled={currentIndex === PRACTICE_IMAGES_VIEW.length - 1}
                                                    className="nextIconBtn"
                                                >
                                                    <ArrowForwardIcon />
                                                </IconButton>
                                            </div>
                                        </div>
                                    </DialogActions>
                                )}
                            </Dialog>
                        </div>
                    ) : null}
                </div>

                <div>
                    {openDialog ? (
                        <div className="outcomeVerSection">
                            <Dialog open={openDialog} maxWidth="md" fullWidth className="dialogOutCome">
                                <div className="imageIcons">
                                    <IconButton onClick={handleNewImageVer} className="imageIconBtn">
                                        <CloseIcon />
                                    </IconButton>
                                </div>

                                <div className="dialogHeading">
                                    <div>{SAMPLE_SLIDE}</div>
                                </div>
                                <DialogContent className="DialogOutcomeContent">
                                    <div className="showVal">{showValue}</div>
                                    <div className="dialogContent">
                                        <img src={handleImagesVer()} alt="" className="dialogImg" />
                                    </div>
                                </DialogContent>

                                <DialogActions className="dialogActionIcon">
                                    <div className="showIcons">
                                        <div className="showBtn">
                                            <IconButton
                                                onClick={handleBackClick}
                                                disabled={currentIndex === 0}
                                                className="currentIconBtn"
                                            >
                                                <ArrowBackIcon />
                                            </IconButton>
                                        </div>
                                        <div className="showBtn">
                                            <IconButton
                                                onClick={handleNextClick}
                                                disabled={handleDisableClick()}
                                                className="nextIconBtn"
                                            >
                                                <ArrowForwardIcon />
                                            </IconButton>
                                        </div>
                                    </div>
                                </DialogActions>
                            </Dialog>
                        </div>
                    ) : null}
                </div>
            </Fragment>
        );
    };

    const renderScoreHeatmap = () => {
        const keepScoreHeatmap =
            keepReportOption(build_your_report, 'Demographic_heatmap_Contents') ||
            keepReportOption(build_your_report, 'Practice_rank_score_Contents');
        if (hideHeatMap) return null;
        if (!keepScoreHeatmap) return null;
        return (
            <div className="heatMapWrapper">
                {isInclusionResurvey && (
                    <Fragment>
                        <span className="title">{GAP_TYPE}</span>
                        <div
                            // className="reportWrapper allReportWrap"
                            className={c('reportWrapper', { allReportWrap: ReportType === '4' })}
                        >
                            <FormGroup classes={{ root: 'radioGroup' }}>
                                <RadioGroup
                                    aria-label="filterOption"
                                    name="filterOption"
                                    value={showAvgGap}
                                    onChange={() => {
                                        setAvgGapToggle(!showAvgGap);
                                    }}
                                >
                                    {inclusionGapType.map(({ label, key }, i) => {
                                        return (
                                            <FormControlLabel
                                                key={`${label}${i}`}
                                                value={key}
                                                control={<Radio disableRipple />}
                                                label={label}
                                            />
                                        );
                                    })}
                                </RadioGroup>
                            </FormGroup>
                        </div>
                        {!CRHohid && (
                            <div className="overallWrapper">
                                <div className="overallHeading">{OVERALL_INCLUSION}</div>
                                <div>
                                    <Switch
                                        checked={overall_inclusion_added}
                                        onClick={e => handleInclusionSwitch(e)}
                                        name="checkedA"
                                        color="primary"
                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                    />
                                </div>
                            </div>
                        )}
                    </Fragment>
                )}

                {ReportType !== '9' && (
                    <div className="samplePageSection">
                        <span className="title">{ReportType === '4' ? SCORE_HEAT_MAP : SCORE_MAPS}</span>
                        {ReportType === '4' ? (
                            <span className="sampleText" onClick={() => setModal(7)}>
                                <img src={viewIcon} alt="" />
                                {VIEW_SAMPLE_SLIDE}
                            </span>
                        ) : null}
                        {(ReportType === '0' || ReportType === '1') && isV4 ? (
                            <span className="sampleText" onClick={handleScoreHeatmap}>
                                <img src={viewIcon} alt="" />
                                {VIEW_SAMPLE_SLIDE}
                            </span>
                        ) : null}
                    </div>
                )}

                {isResurveyCompHeatMap && (
                    <div className="selectVersionPpt">
                        <div className="headerTitle"> {SELECT_OUT_VER}</div>
                        <div className="reportWrapper viewSlides">
                            <FormGroup data-test-id="comparisonBubble" classes={{ root: 'radioGroup' }}>
                                <div>
                                    <RadioGroup
                                        aria-label="non_profit_report_full"
                                        name="outcomePptVersion"
                                        value={outcomePptVersion}
                                        onChange={({ target }) => handleDemographicsData(target)}
                                        className="outComeVersion"
                                    >
                                        {[1, 2, 3].map(outcomeVer => (
                                            <div className="outcomeContent" key={outcomeVer}>
                                                <FormControlLabel
                                                    key={outcomeVer}
                                                    value={`${outcomeVer}`}
                                                    control={<Radio disableRipple />}
                                                    label={`${VERSION_TEXT} ${outcomeVer}`}
                                                    className="outcomeText"
                                                />
                                            </div>
                                        ))}
                                    </RadioGroup>
                                </div>
                            </FormGroup>
                            <span className="sampleText" onClick={() => handleShowOutcome()}>
                                <img src={viewIcon} alt="" />
                                {VIEW_SAMPLE_SLIDE}
                            </span>
                        </div>
                        <div className="headerTitle"> {SELECT_PRTC_VER}</div>
                        <div className="reportWrapper viewSlides">
                            <FormGroup data-test-id="comparisonBubble" classes={{ root: 'radioGroup' }}>
                                <RadioGroup
                                    aria-label="non_profit_report_full"
                                    name="practicePptVersion"
                                    value={practicePptVersion}
                                    onChange={({ target }) => handleDemographicsData(target)}
                                    className="outComeVersion"
                                >
                                    {[1, 2].map(practVer => (
                                        <div className="outcomeContent" key={practVer}>
                                            <FormControlLabel
                                                key={practVer}
                                                value={`${practVer}`}
                                                control={<Radio disableRipple />}
                                                label={`${VERSION_TEXT} ${practVer}`}
                                                className="outcomeText"
                                            />
                                        </div>
                                    ))}
                                </RadioGroup>
                            </FormGroup>
                            {isV4 ? (
                                <span className="sampleText" onClick={() => handleShowPractice()}>
                                    <img src={viewIcon} alt="" />
                                    {VIEW_SAMPLE_SLIDE}
                                </span>
                            ) : null}
                            {!isV4 ? (
                                <span className="sampleText" onClick={() => handleOldPract()}>
                                    <img src={viewIcon} alt="" />
                                    {VIEW_SAMPLE_SLIDE}
                                </span>
                            ) : null}
                        </div>
                    </div>
                )}
                {isResurveyCompHeatMap && (
                    <div className="deltaHeatmap">
                        <Checkbox
                            checked={includeDelta}
                            onClick={e => handleDeltaChecked(e)}
                            className="deltaChecked"
                        />
                        <div className="includeDelta">{INCLUDE_TEXT}</div>
                    </div>
                )}
                {ReportType !== '9' && (
                    <div className={c('ohiContent', { enterpriseWrap: ReportType !== '4' })}>
                        {ReportType !== '4' ? (
                            <div className="subInfo">
                                {survey_version === '4' || survey_version === '3_2' ? (
                                    <>
                                        <span>{SCORE_NEW_HEATMAP}</span>
                                        <br />
                                        <span>{SCORE_NEW_HEATMAP_2}</span>
                                    </>
                                ) : (
                                    <div>
                                        {isInclusionResurvey ? (
                                            <span>
                                                {HEAT_MAP_INCLUSION_RES}
                                                <br />
                                                {HEAT_MAP_INCLUSION_RES_2}
                                            </span>
                                        ) : (
                                            <span>
                                                {HEAT_MAP_INCLUSION}
                                                <br />
                                                {HEAT_MAP_INCLUSION_2}
                                            </span>
                                        )}
                                    </div>
                                )}
                            </div>
                        ) : (
                            <div className="subInfo">
                                <span>{INCLUSION_HEATMAP}</span>
                            </div>
                        )}

                        <div className={c('reportWrapper', { allReportWrap: ReportType === '4' })}>
                            <FormGroup classes={{ root: 'radioGroup' }}>
                                <RadioGroup
                                    aria-label="heatMapOption"
                                    name="heatMapOption"
                                    value={heatMapOption}
                                    onChange={({ target }) => handleDemographicsData(target)}
                                >
                                    {heatMap.map(({ label, key }, i) => (
                                        <FormControlLabel
                                            key={`${key}${i}`}
                                            value={label}
                                            control={<Radio disableRipple />}
                                            label={ReportType === '8' && key === NONE ? 'All' : label}
                                        />
                                    ))}
                                </RadioGroup>
                            </FormGroup>
                        </div>

                        {isV4 ? renderHeatmapRadioBtns() : null}
                        {showDemoOption.length > 2 && isResurveyCompHeatMap && showBulk ? (
                            <div className="noMapDemo">{BULK_RESURVEY_COMP_ERROR}</div>
                        ) : (
                            <DemographicFilter
                                defaultSettings={defaultSettings}
                                heatMapOption={heatMapOption}
                                sort_by={sort_by}
                                handleDemographicsData={handleDemographicsData}
                                selectAll={selectAll}
                                handleSelectAllHeatMap={handleSelectAllHeatMap}
                                demoGraphicState={filterForInclusion}
                                heatmap_demographic={heatmap_demographic}
                                handleSelect={handleSelect}
                                hiddenOptions={[]}
                                showSelectAll={ReportType < 4}
                                showSelect={ReportType < 4}
                                isResurveyCompHeatMap={isResurveyCompHeatMap}
                                resurveyMapping={demoResurveyMapped}
                                showResurveyReportOnly={ReportType === '3'}
                                unmapped_demos={unmapped_demos}
                                // NEW
                                showEEReport="true"
                            />
                        )}
                    </div>
                )}
            </div>
        );
    };
    return (
        <section>
            <div className="demographicWrapper">
                <div className="reportDemoSection">
                    <h2>{REPORT_TYPE[reportIndex]} - </h2>
                    <h2>{DEMOGRAPHIC_OPTIONS}</h2>
                </div>
                {ReportType !== '8' && ReportType !== '9' && (
                    <div className={c('demographicContainer', { notInclusionWrap: ReportType !== '4' })}>
                        <div className="title">{DEMOGRAPHIC_THRESHOLD}</div>
                        {ReportType === '7' && <div className="subInfo">{DEMOGRAPHIC_OPTIONS_EX}</div>}
                        {ReportType === '4' ? (
                            <div className="subInfo">
                                {INCLUSION_DEMOOPTION_SUB_HEADING}
                                {demoThreshold}
                            </div>
                        ) : null}
                        {ReportType === '6' ? (
                            <div className="subInfo">
                                {INCLUSION_DEMOOPTION_SUB_HEADING_RESURVEY}
                                {demoThreshold}
                            </div>
                        ) : null}
                        <div className={c('reportWrapper', { allReportWrap: ReportType === '4' })}>
                            <FormGroup classes={{ root: 'radioGroup' }}>
                                <RadioGroup
                                    aria-label="gender"
                                    name="threshold"
                                    value={threshold}
                                    onChange={({ target }) => handleDemographicsData(target)}
                                >
                                    {renderOptionData()}
                                </RadioGroup>
                            </FormGroup>
                        </div>

                        {ReportType === '5' && threshold === 'custom' ? (
                            <div className="dataCubeThreshold">
                                <TextField
                                    autoComplete="false"
                                    name="n_size"
                                    value={n_size}
                                    onChange={({ target }) => handleDemographicsData(target)}
                                    placeholder={DATACUBE_NAME_PLACEHOLDER}
                                    type="number"
                                />
                            </div>
                        ) : null}
                        {!showIngRecentDemo && (
                            <>
                                {ReportType !== '4' && ReportType !== '7' && ReportType !== '6' && (
                                    <div className="subInfo subThresInfo">
                                        {ReportType === '5'
                                            ? `${DATACUBE_OPTIONS_SUBINFO}${demoThreshold} ${DATACUBE_OPTIONS_SUBINFO_2}`
                                            : `${DEMOGRAPHIC_OPTIONS_SUBINFO} ${demoThreshold} ${DEMOGRAPHIC_OPTIONS_SUBINFO_2}`}
                                    </div>
                                )}
                                {renderEEOptionData()}
                            </>
                        )}
                        {ReportType === '4' && (
                            <div className="rightSideFilters heatMapWrapper">
                                <div>
                                    <div>
                                        <span className="title">{EXCLUDE_DEMO_GROUP_HEADING}</span>
                                    </div>
                                    <div className="subInfo">
                                        <div>{EXCLUDE_DEMO_GROUP_SUB_HEADING[0]}</div>
                                        <div>{EXCLUDE_DEMO_GROUP_SUB_HEADING[1]}</div>
                                    </div>
                                </div>
                                <div className={c('reportWrapper', { allReportWrap: ReportType === '4' })}>
                                    <FormGroup classes={{ root: 'radioGroup' }}>
                                        <RadioGroup
                                            aria-label="summaryValue"
                                            name="summaryValue"
                                            value={summaryValue}
                                            onChange={({ target }) => handleExcludeDemoGroup(target)}
                                        >
                                            {summaryOptions.map(({ label, key }, i) => (
                                                <FormControlLabel
                                                    key={`${key}${i}`}
                                                    value={key}
                                                    control={<Radio />}
                                                    label={label}
                                                />
                                            ))}
                                        </RadioGroup>
                                    </FormGroup>
                                </div>

                                {summaryValue === CUSTOM && (
                                    <div className="rightSideFilters heatMapWrapper">
                                        <DemographicFilter
                                            defaultSettings={defaultSettings}
                                            heatMapOption={CUSTOM}
                                            sort_by={sort_by}
                                            handleDemographicsData={handleDemographicsData}
                                            demoGraphicState={demoFilterArray}
                                            heatmap_demographic={exclude_demographic}
                                            handleSelect={handleSelectExcludeOpt}
                                            hiddenOptions={defaultExcludeGapsArr}
                                            showSelectAll={false}
                                            selectAll={false}
                                            showSelect={ReportType !== '4' && ReportType !== '6'}
                                        />
                                    </div>
                                )}

                                {full_inclusion_access && (
                                    <div>
                                        <div className="samplePageSection">
                                            <span className="title">{DEMOGRAPHIC_SUMMARY}</span>
                                            <span className="sampleText" onClick={() => setModal(6)}>
                                                <img src={viewIcon} alt="" />
                                                {VIEW_SAMPLE_SLIDE}
                                            </span>
                                        </div>
                                        <div className="subInfo">
                                            <div>{DEMOGRAPHIC_SUMMARY_SUBHEADING[0]}</div>
                                            <div>{DEMOGRAPHIC_SUMMARY_SUBHEADING[1]}</div>
                                        </div>
                                        <div className={c('reportWrapper', { allReportWrap: ReportType === '4' })}>
                                            <FormGroup classes={{ root: 'radioGroup' }}>
                                                <RadioGroup
                                                    aria-label="gender"
                                                    name="summaryOpt"
                                                    value={summaryOpt}
                                                    onChange={({ target }) => handleDemographicsData(target)}
                                                >
                                                    {summaryOptions.map(({ label, key }, i) => (
                                                        <FormControlLabel
                                                            key={`${key}${i}`}
                                                            value={key}
                                                            control={<Radio />}
                                                            label={label}
                                                        />
                                                    ))}
                                                </RadioGroup>
                                            </FormGroup>
                                        </div>
                                        {summaryOpt === CUSTOM && (
                                            <div className="rightSideFilters heatMapWrapper" key={99}>
                                                <Demographics
                                                    defaultSettings={defaultSettings}
                                                    limitParent={8}
                                                    limitChild={5}
                                                    showOption={false}
                                                    demographics={demoGraphicState[5] || []}
                                                    updateDemographics={e => handleDemograhicState(e, 5)}
                                                />
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                )}
                <div className="rightSideFilters heatMapWrapper">
                    <div
                        className={c({
                            compareSection: (ReportType > 1 && ReportType < 4) || isInclusionResurvey,
                        })}
                    >
                        {isInclusionResurvey ? (
                            <Fragment>
                                <span className="title">{DEMOGRAPHIC_FILTERS}</span>
                                <div className="subInfo">{INCLUSION_DEMOFILTER_SUB_HEADING[0]}</div>
                                <div className="subInfo">{INCLUSION_DEMOFILTER_SUB_HEADING[1]}</div>
                            </Fragment>
                        ) : null}
                        {showDemoOption.map(({ report_name, filterOption }, index) => {
                            return (
                                <div key={index} className="demographicContainer">
                                    {ReportType === '1' && (
                                        <div className="comparison">
                                            <h3 className="comparisonSection">
                                                {index === 0 ? (
                                                    <>
                                                        <div className="comparisonHeading">{BU_GROUPS}</div>
                                                        <div className="buMainHeading">{MAIN_GROUP_TEXT}</div>
                                                    </>
                                                ) : (
                                                    <>
                                                        <div className="buMainHeading">{COMPARISION_GROUP}</div>
                                                    </>
                                                )}
                                            </h3>
                                            {(index || !showBulk) && (
                                                <Fragment>
                                                    <TextField
                                                        autoComplete="false"
                                                        name="report_name"
                                                        value={report_name}
                                                        onChange={({ target }) =>
                                                            handleDemographicFilters(target, index)
                                                        }
                                                        placeholder={REPORT_NAME_PLACEHOLDER}
                                                        data-testid="reportName"
                                                    />
                                                </Fragment>
                                            )}
                                        </div>
                                    )}
                                    {ReportType === '3' ? <div className="heading">{ohidArr[index]}</div> : null}
                                    {!isInclusionResurvey && ReportType !== '8' && (
                                        <Fragment>
                                            {(index || !showBulk) && (
                                                <div className="buFilterSection">
                                                    <span className="title">{DEMOGRAPHIC_FILTERS}</span>
                                                    {index === 0 && ReportType === '1' && (
                                                        <div className="buParagraph">{BU_BUSINESS_TEXT}</div>
                                                    )}
                                                    {index === 1 && ReportType === '1' && (
                                                        <div className="buParagraph">{BU_ORGANIZATION_TEXT}</div>
                                                    )}
                                                </div>
                                            )}
                                            {ReportType === '4' && (
                                                <Fragment>
                                                    <div className="subInfo">{INCLUSION_DEMOFILTER_SUB_HEADING[0]}</div>
                                                    <div className="subInfo">{INCLUSION_DEMOFILTER_SUB_HEADING[1]}</div>
                                                </Fragment>
                                            )}
                                        </Fragment>
                                    )}
                                    {isInclusionResurvey && (
                                        <Fragment>
                                            <span className="subTitle">{ohidArr[index]}</span>
                                        </Fragment>
                                    )}
                                    {(index || !showBulk) && ReportType !== '8' && ReportType !== '9' && (
                                        <div
                                            className={c('reportWrapper', {
                                                allReportWrap: ReportType === '4',
                                                allDemoWrap: ReportType === '1',
                                            })}
                                        >
                                            <FormGroup classes={{ root: 'radioGroup' }}>
                                                <RadioGroup
                                                    aria-label="filterOption"
                                                    name="filterOption"
                                                    value={filterOption}
                                                    onChange={({ target }) => handleDemographicFilters(target, index)}
                                                >
                                                    {demoHideCustomArr.map(({ label, key }, i) => {
                                                        return (
                                                            <FormControlLabel
                                                                key={`${key}${i}`}
                                                                value={key}
                                                                control={<Radio disableRipple />}
                                                                label={label}
                                                            />
                                                        );
                                                    })}
                                                </RadioGroup>
                                            </FormGroup>
                                        </div>
                                    )}
                                    {ReportType === '9' && (
                                        <div>
                                            <FormGroup classes={{ root: 'radioGroup' }}>
                                                <RadioGroup
                                                    aria-label="filterOption"
                                                    name="filterOption"
                                                    value={selectedValueOp}
                                                    onChange={({ target }) =>
                                                        handleDemographicFiltersModule(target, index)
                                                    }
                                                >
                                                    {opModuleArr.map(({ label, key }) => {
                                                        return (
                                                            <FormControlLabel
                                                                key={key}
                                                                value={key}
                                                                control={<Radio disableRipple />}
                                                                label={label}
                                                            />
                                                        );
                                                    })}
                                                </RadioGroup>
                                            </FormGroup>
                                        </div>
                                    )}
                                    {showDemographic(filterOption, index) ? (
                                        <Fragment>
                                            {!index && showBulk && (
                                                <div className="noteInfo">
                                                    <b>{NOTE}:</b> {BULK_REPORT_NOTE}
                                                </div>
                                            )}
                                            <Demographics
                                                defaultSettings={defaultSettings}
                                                demographics={demoGraphicState[index] || []}
                                                updateDemographics={e => handleDemograhicState(e, index)}
                                                hideHeading={
                                                    ReportType === '4' || ReportType === '6' || ReportType === '7'
                                                }
                                            />
                                        </Fragment>
                                    ) : null}

                                    {ReportType === '1' && !isCfgClient && !index && (
                                        <FormControlLabel
                                            classes={{
                                                root: showBulk ? 'bulkUpload marginUp' : 'bulkUpload',
                                            }}
                                            control={
                                                <Switch
                                                    checked={showBulk}
                                                    onChange={() => handleBulkToggle(!showBulk)}
                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                />
                                            }
                                            label={BULK_REPORT}
                                            labelPlacement="start"
                                        />
                                    )}
                                    {ReportType === '4' && (
                                        <Fragment>
                                            <span className="title">{GAP_TYPE}</span>

                                            <div className={c('reportWrapper', { allReportWrap: ReportType === '4' })}>
                                                <FormGroup classes={{ root: 'radioGroup' }}>
                                                    <RadioGroup
                                                        aria-label="filterOption"
                                                        name="filterOption"
                                                        value={showAvgGap}
                                                        onChange={() => {
                                                            setAvgGapToggle(!showAvgGap);
                                                        }}
                                                    >
                                                        {inclusionGapType.map(({ label, key }, i) => {
                                                            return (
                                                                <FormControlLabel
                                                                    key={`${label}${i}`}
                                                                    value={key}
                                                                    control={<Radio disableRipple />}
                                                                    label={label}
                                                                />
                                                            );
                                                        })}
                                                    </RadioGroup>
                                                </FormGroup>
                                            </div>
                                            {!CRHohid && (
                                                <div className="overallWrapper">
                                                    <div className="overallHeading">{OVERALL_INCLUSION}</div>
                                                    <div>
                                                        <Switch
                                                            checked={overall_inclusion_added}
                                                            onClick={e => handleInclusionSwitch(e)}
                                                            name="checkedA"
                                                            color="primary"
                                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                        </Fragment>
                                    )}
                                    {showBulk && ReportType === '1' && !index && (
                                        <div className="bulkDemoContent">
                                            <h3>{CHOOSE_BULK_UNITS}</h3>
                                            <Demographics
                                                defaultSettings={defaultSettings}
                                                demographics={currentBulkDemo}
                                                updateDemographics={e => updateBulkDemo(e)}
                                                isOhiReport={ReportType === '1'}
                                                showBulk={showBulk}
                                                setIsDemoChecked={setIsDemoChecked}
                                                updateDisableDemo={updateNsizeDemo}
                                            />
                                        </div>
                                    )}
                                    {filterOption === CUSTOM && ReportType === '1' && index === 1 ? (
                                        <Demographics
                                            defaultSettings={defaultSettings}
                                            demographics={buDemoState}
                                            updateDemographics={e => setBuDemoState(e)}
                                        />
                                    ) : null}
                                    {isThomsonOhid2024 && index && (
                                        <Fragment>
                                            <div className="heading">OHI13191-2023</div>
                                            <div className="buFilterSection">
                                                <span className="title">{DEMOGRAPHIC_FILTERS}</span>
                                                <div className="buParagraph">{BU_ORGANIZATION_TEXT}</div>
                                            </div>
                                            <div
                                                className={c('reportWrapper', {
                                                    allDemoWrap: ReportType === '1',
                                                })}
                                            >
                                                <FormGroup classes={{ root: 'radioGroup' }}>
                                                    <RadioGroup
                                                        aria-label="filterOption"
                                                        name="TRresDemographics"
                                                        value={TRresDemographics}
                                                        onChange={({ target }) => handleExcludeDemoGroup(target)}
                                                    >
                                                        {thomsonResFilters.map(({ label, key }, i) => {
                                                            return (
                                                                <FormControlLabel
                                                                    key={`${key}${i}`}
                                                                    value={key}
                                                                    control={<Radio disableRipple />}
                                                                    label={label}
                                                                />
                                                            );
                                                        })}
                                                    </RadioGroup>
                                                </FormGroup>
                                            </div>
                                            {TRresDemographics === CUSTOM && (
                                                <Demographics
                                                    defaultSettings={defaultSettings}
                                                    showOption={false}
                                                    demographics={TRResDemoState}
                                                    updateDemographics={e => handleTRResDemograhicState(e, 0)}
                                                    hiddenOptions={[]}
                                                    // limitParent={10}
                                                    // limitChild={10}
                                                />
                                            )}
                                        </Fragment>
                                    )}
                                    {index === 0 && ReportType === '1' && !isThomsonOhid2024 && (
                                        <div className="orgLevelBtnSection">
                                            <FormControlLabel
                                                classes={{
                                                    root: 'orgLevelBtn',
                                                }}
                                                control={
                                                    <Switch
                                                        checked={orgLevelBu === '1'}
                                                        onChange={handleOrgLevelToggle}
                                                        inputProps={{ 'aria-label': 'controlled' }}
                                                    />
                                                }
                                                // label="Include Organisation level comparison"
                                                label={INCLUDE_TEXT_1}
                                                labelPlacement="start"
                                            />
                                            <div className="enableText">{ENABLE_TEXT}</div>
                                        </div>
                                    )}
                                </div>
                            );
                        })}
                        {ReportType === '3' && (isIngClient || isAmmegaRpt || ingReportContent) ? (
                            <Fragment>
                                {ingDemographicFilters.map(({ report_name, filterOption }, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className={c('demographicContainer', {
                                                notInclusionWrap: ReportType !== '4',
                                            })}
                                        >
                                            <div className="comparison">
                                                <h3>{COMPARISION_BU_TEXT}</h3>
                                                <span className="title">{ENTER_COMPANY_NAME}</span>
                                                <TextField
                                                    autoComplete="false"
                                                    name="report_name"
                                                    value={report_name}
                                                    onChange={({ target }) => handleIngDemoFilters(target, index)}
                                                    placeholder={COMPARISON_BU_PLACEHOLDER}
                                                    data-testid="reportName"
                                                />
                                            </div>

                                            <div className="reportWrapper">
                                                <FormGroup classes={{ root: 'radioGroup' }}>
                                                    <RadioGroup
                                                        aria-label="filterOption"
                                                        name="filterOption"
                                                        value={filterOption}
                                                        onChange={({ target }) => handleIngDemoFilters(target, index)}
                                                    >
                                                        {filterArr.map(({ label, key }, i) => {
                                                            return (
                                                                <FormControlLabel
                                                                    key={`${key}${i}`}
                                                                    value={key}
                                                                    control={<Radio disableRipple />}
                                                                    label={label}
                                                                />
                                                            );
                                                        })}
                                                    </RadioGroup>
                                                </FormGroup>
                                            </div>
                                            {filterOption === CUSTOM && index === 0 ? (
                                                <Demographics
                                                    defaultSettings={defaultSettings}
                                                    demographics={buDemoState}
                                                    updateDemographics={e => setBuDemoState(e)}
                                                />
                                            ) : null}
                                        </div>
                                    );
                                })}
                            </Fragment>
                        ) : null}
                    </div>
                    {renderScoreHeatmap()}
                    {(ReportType === '0' || ReportType === '1' || ReportType === '3') && isV4
                        ? renderOhi4Option()
                        : null}
                </div>

                {ReportType === '4' ? renderReportDemographics() : null}
                {ReportType === '5' ? (
                    <div className="rightSideFilters heatMapWrapper dataCubeWrap">
                        <div>
                            <div>
                                <span className="title">{DEMOGRAPHIC}</span>
                            </div>
                            <div className="subInfo">
                                {DATACUBE_DEMOOPTION_SUB_HEADING}
                                <span className="demoCount">
                                    {checkCompareArrLength(demoGraphicState[1])} out of {demoGraphicState[1].length}
                                </span>
                            </div>
                            <Demographics
                                defaultSettings={defaultSettings}
                                noChild
                                demographics={demoGraphicState[1]}
                                updateDemographics={e => handleDemograhicState(e, 1)}
                            />
                        </div>
                        <h3>{DATACUBE_LEVEL}</h3>
                        <div
                            // className="reportWrapper allReportWrap"
                            className={c('reportWrapper', { allReportWrap: ReportType === '4' })}
                        >
                            <FormGroup classes={{ root: 'radioGroup' }}>
                                <RadioGroup
                                    aria-label="ReportType"
                                    name="level"
                                    value={level}
                                    onChange={({ target }) => handleDemographicsData(target)}
                                >
                                    {LEVEL_TYPE.slice(0, levelDepth).map(levelText => (
                                        <FormControlLabel
                                            key={levelText}
                                            value={levelText}
                                            control={<Radio disableRipple />}
                                            label={`Level ${levelText}`}
                                        />
                                    ))}
                                </RadioGroup>
                            </FormGroup>
                        </div>
                        <Fragment>
                            <h3>{DATACUBE_VERSION}</h3>
                            <div className={c('reportWrapper', { allReportWrap: ReportType === '4' })}>
                                <FormGroup classes={{ root: 'radioGroup' }}>
                                    <RadioGroup
                                        aria-label="ReportType"
                                        name="datacube_version"
                                        value={datacube_version}
                                        onChange={({ target }) => handleDemographicsData(target)}
                                    >
                                        {['1', '2'].map(levelText => (
                                            <FormControlLabel
                                                key={levelText}
                                                value={levelText}
                                                control={<Radio disableRipple />}
                                                label={`Version ${levelText}`}
                                            />
                                        ))}
                                    </RadioGroup>
                                </FormGroup>
                            </div>
                        </Fragment>
                        {resource === 'ohi_data_cube' ? (
                            <Fragment>
                                <h3>{SCORE_DEPTH}</h3>
                                <div className={c('reportWrapper', { allReportWrap: ReportType === '4' })}>
                                    <FormGroup classes={{ root: 'radioGroup' }}>
                                        <RadioGroup
                                            aria-label="ReportType"
                                            name="score_depth"
                                            value={score_depth}
                                            onChange={({ target }) => handleDemographicsData(target)}
                                        >
                                            {[3, 5].map(levelText => (
                                                <FormControlLabel
                                                    key={levelText}
                                                    value={levelText}
                                                    control={<Radio disableRipple />}
                                                    label={levelText}
                                                />
                                            ))}
                                        </RadioGroup>
                                    </FormGroup>
                                </div>
                            </Fragment>
                        ) : null}
                    </div>
                ) : null}

                {showOhi4Demo && (ReportType === '0' || ReportType === '1' || ReportType === '3') && (
                    <div className="ohi4Options">
                        <h3>{OHI4_OPTIONS_HEAD}</h3>
                        <p>{OHI4_OPTIONS_META}</p>
                        <DemographicFilter
                            defaultSettings={defaultSettings}
                            heatMapOption={CUSTOM}
                            demoGraphicState={demoFilterArray}
                            heatmap_demographic={ohi4_incDemographics}
                            handleSelect={handleOhi4HeatMap}
                            showSelectAll={false}
                            showSelect={false}
                            unmapped_demos={unmapped_demos}
                        />
                    </div>
                )}
            </div>
            {ReportType === '8' ? (
                <Fragment>
                    <div className="dataCubeSettings">
                        <span className="title">{DOWNLOAD_FORMAT}</span>
                        <div className="reportWrapper resourceWrap">
                            <FormGroup classes={{ root: 'radioGroup' }}>
                                <RadioGroup
                                    aria-label="ReportType"
                                    name="influencer"
                                    value={downloadType}
                                    onChange={({ target }) => handleDownloadType(target)}
                                >
                                    {DOWNLOD_TYPE.map(({ label = '', checkAccess = false }) => {
                                        if (checkAccess && rr_survey_type === 99) {
                                            return null;
                                        }
                                        return (
                                            <FormControlLabel
                                                key={label}
                                                value={label}
                                                control={<Radio disableRipple />}
                                                label={label}
                                            />
                                        );
                                    })}
                                </RadioGroup>
                            </FormGroup>
                        </div>
                    </div>
                </Fragment>
            ) : null}
            {ReportType === '9' && (
                <div>
                    {NOTE}: {CARELESS_TEXT}
                </div>
            )}
            <div className="buttonWrapperReports">
                {ReportType !== '9' ? (
                    <div className="outlineBtn" onClick={goBack}>
                        {BACK}
                    </div>
                ) : (
                    <div className="outlineBtn" onClick={opBackBtn}>
                        {BACK}
                    </div>
                )}
                {isV4 ? (
                    <div className={c('blueButton', { active: isActive })} onClick={handleBulkNext}>
                        {['5', '7', '8', '9'].includes(ReportType) ||
                        isInclusionResurvey ||
                        (ReportType === '0' && isNonProfit) ||
                        isIngClient ||
                        isAmmegaRpt ||
                        isThomsonRpt ||
                        ingReportContent ||
                        (isThomsonOhid2024 && ReportType === '1') ||
                        ((ReportType === '0' || ReportType === '1') && report_type === 'percentile') ||
                        (ReportType === '3' && !isV4) ||
                        ((ReportType === '0' || ReportType === '1') &&
                            !keepReportOption(build_your_report, 'Benchmarks_Contents'))
                            ? CREATE_REPORT
                            : NEXT}
                    </div>
                ) : (
                    <div className={c('blueButton', { active: isActive })} onClick={handleBulkNext}>
                        {['5', '7', '8'].includes(ReportType) ||
                        isInclusionResurvey ||
                        (ReportType === '0' && isNonProfit) ||
                        isIngClient ||
                        ingReportContent ||
                        isAmmegaRpt ||
                        isThomsonRpt ||
                        (ReportType === '3' && isV4)
                            ? CREATE_REPORT
                            : NEXT}
                    </div>
                )}
            </div>
            {renderImageDialog()}
            {modal > 0 ? (
                <ImageModal
                    defaultSettings={defaultSettings}
                    imageArr={DEMO_IMAGES.filter(({ modalNo }) => modalNo === modal)}
                    closeModal={() => setModal(0)}
                />
            ) : null}
            {openConfirm && (
                <ConfirmationModal>
                    <div className="thresholdPopup">
                        <div className="thresholdCloseIcon" onClick={() => toggleConfirmModal(false)}>
                            <CloseIcon />
                        </div>
                        <div className="demoOptionIcon">
                            <WarningIcon />
                        </div>
                        <h2 className="changeHeading">{CHANGE_DEMOGRAPHIC_THRESHOLD}</h2>
                        <div className="content">{DEMO_OPT_NOTE(demoThreshold)}</div>
                        <div className="buttonContent">
                            <span data-testid="closeBtn" onClick={() => toggleConfirmModal(false)}>
                                {CANCEL_TEXT}
                            </span>
                            <span data-testid="delBtn" onClick={updateDemoKey}>
                                {CONFIRM_TEXT}
                            </span>
                        </div>
                    </div>
                </ConfirmationModal>
            )}

            {showBu && showBulk && ReportType === '1' && (
                <BulkPastReport
                    showBulk={showBulk}
                    showBu={showBu}
                    defaultSettings={defaultSettings}
                    submitReport={submitReport}
                    isOhiReport={ReportType === '1'}
                    currentBulkDemo={currentBulkDemo}
                    getBulkScoreData={getBulkScoreData}
                    scoreBulkData={scoreBulkData}
                    updateBulkDemo={updateBulkDemo}
                    apiParams={apiParams}
                    setShowBu={setShowBu}
                    bulkDemographics={bulkDemographics}
                    setDemoChecked={setIsDemoChecked}
                    checkDemoChecked={isDemoChecked}
                    updateBulkFilters={updateBulkFilters}
                    updateNsizeDemo={updateNsizeDemo}
                    setUpdateNsizeDemo={setUpdateNsizeDemo}
                    resetBulkAssign={resetBulkAssign}
                    scoreBulkDataFetched={scoreBulkDataFetched}
                    demoGraphicState={demoFilterArray}
                />
            )}
        </section>
    );
}

DemographicOption.propTypes = {
    isNonProfit: PropTypes.bool.isRequired,
    isCfgClient: PropTypes.bool.isRequired,
    isIngClient: PropTypes.bool.isRequired,
    isAmmegaRpt: PropTypes.bool.isRequired,
    showBulk: PropTypes.bool.isRequired,
    defaultSettings: PropTypes.object.isRequired,
    goBack: PropTypes.func.isRequired,
    demoGraphicState: PropTypes.array.isRequired,
    submitReport: PropTypes.func.isRequired,
    reportApiParams: PropTypes.object.isRequired,
    buDemoState: PropTypes.array.isRequired,
    setBuDemoState: PropTypes.func.isRequired,
    handleDemograhicState: PropTypes.func.isRequired,
    currentOhid: PropTypes.string.isRequired,
    reportIndex: PropTypes.number.isRequired,
    bulkDemographics: PropTypes.array.isRequired,
    setBulkToggle: PropTypes.func.isRequired,
    updateBulkFilters: PropTypes.func.isRequired,
    resurveyMapping: PropTypes.object.isRequired,
    resurveyDemographics: PropTypes.array.isRequired,
    showAvgGap: PropTypes.bool.isRequired,
    setAvgGapToggle: PropTypes.func.isRequired,
    currentBulkDemo: PropTypes.array.isRequired,
    updateBulkDemo: PropTypes.func.isRequired,
    getBulkScoreData: PropTypes.func.isRequired,
    scoreBulkData: PropTypes.object.isRequired,
    apiParams: PropTypes.object.isRequired,
    setLastMenu: PropTypes.func.isRequired,
    setIsDemoChecked: PropTypes.func.isRequired,
    updateNsizeDemo: PropTypes.array.isRequired,
    setUpdateNsizeDemo: PropTypes.func.isRequired,
    isDemoChecked: PropTypes.bool.isRequired,
    resetBulkAssign: PropTypes.func.isRequired,
    isThomsonRpt: PropTypes.bool.isRequired,
    handleOhi4DemograhicState: PropTypes.func.isRequired,
    ohi4DemoState: PropTypes.array.isRequired,
    setOrgLevelToggle: PropTypes.func.isRequired,
    orgLevelBu: PropTypes.string.isRequired,
    setCurrentMenu: PropTypes.func.isRequired,
    // setDemoState: PropTypes.func.isRequired,
    scoreBulkDataFetched: PropTypes.bool.isRequired,
    // new
    ingReportContent: PropTypes.bool.isRequired,
    handleTRResDemograhicState: PropTypes.func.isRequired,
    TRResDemoState: PropTypes.array.isRequired,
};

export default DemographicOption;
