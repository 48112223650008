import React from 'react';
import { Box, Button, Modal, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { getStaticText } from '../../utils/constants';

const AlertModal = ({ modal, setModal, infoText, defaultSettings }) => {
    const { lang } = defaultSettings;
    const staticText = getStaticText(lang);
    const { SITE_TEXT } = staticText;
    const { OK_TEXT } = SITE_TEXT;

    return (
        <Modal
            open={modal}
            onClose={() => setModal(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                }}
            >
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    {infoText}
                </Typography>
                <Button
                    onClick={() => setModal(false)}
                    style={{ margin: '4% 40%', background: '#002960', color: 'white' }}
                >
                    {OK_TEXT}
                </Button>
            </Box>
        </Modal>
    );
};

AlertModal.propTypes = {
    modal: PropTypes.bool.isRequired,
    setModal: PropTypes.func.isRequired,
    infoText: PropTypes.string.isRequired,
};

export default AlertModal;
