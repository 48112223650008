import React, { useEffect } from 'react';
import XLSX from 'xlsx-color';
import PropTypes from 'prop-types';

import { getStaticText } from '../../../utils/constants';
import colors from '../../../sass/colors';
import { getRandomValues } from '../../../utils/functions';

function Excel({
    header,
    items,
    selectedDemographic,
    demographics,
    apiParams,
    benchmarks,
    defaultSettings,
    isInclusion,
    isPR,
}) {
    const { quartileColors = [], lang, survey_version = '' } = defaultSettings;
    const staticText = getStaticText(lang);
    const { SITE_TEXT } = staticText;
    const { N_SIZE, DEMO_INCLUSION_LEGENDS, BENCHMARK_FILTERS, DEMO_SELECTED_OPTIONS } = SITE_TEXT || {};
    const isOhi4 = survey_version === '4' || survey_version === '3_2';
    const getExport = () => {
        const table = document.getElementById('mytable');
        if (table) {
            const wb = XLSX.utils.table_to_book(table, { sheet: 'Report' });
            Object.keys(wb.Sheets.Report).forEach(val => {
                if (typeof wb.Sheets.Report[val] === 'object') {
                    const split = wb.Sheets.Report[val].v.split('#') || [];
                    if (split.length > 0) {
                        const splitFirst = split[0];
                        const { background: bgHeat = colors.$white, color: bgCol = colors.$darkBlue100 } =
                            quartileColors[splitFirst] || [];
                        const { background: bgInc = colors.$white } =
                            DEMO_INCLUSION_LEGENDS[parseInt(splitFirst, 10)] || {};
                        const background = isInclusion ? bgInc : bgHeat;
                        const color = isInclusion ? colors.$darkBlue100 : bgCol;
                        // eslint-disable-next-line prefer-destructuring
                        wb.Sheets.Report[val].v = split[1];
                        let align = 'left';
                        const borderObj = {
                            style: 'thin',
                            color: { rgb: splitFirst === '0' && !isOhi4 ? 'FFD701' : 'FFFFFF' },
                        };
                        if (split.length === 3) {
                            align = 'center';
                        } else {
                            align = splitFirst === 'bold' ? 'left' : 'center';
                        }
                        wb.Sheets.Report[val].s = {
                            font: {
                                color: { rgb: color.replace('#', '') },
                                bold: splitFirst === 'bold',
                                sz: splitFirst === 'bold' ? 14 : 12,
                            },
                            alignment: {
                                vertical: align,
                                horizontal: align,
                            },
                            fill: {
                                patternType: 'solid',
                                fgColor: { rgb: splitFirst === '0' && isOhi4 ? 'FFA800' : background.replace('#', '') },
                            },
                            border: {
                                bottom: borderObj,
                                top: borderObj,
                                left: borderObj,
                                right: borderObj,
                            },
                        };
                    }
                }
            });
            let today = new Date();
            let dd = today.getDate();
            let mm = today.getMonth() + 1;
            const yyyy = today.getFullYear();
            if (dd < 10) {
                dd = `0${dd}`;
            }
            if (mm < 10) {
                mm = `0${mm}`;
            }
            today = `${mm}_${dd}_${yyyy}`;
            return XLSX.writeFile(wb, `demographics_${today}.xlsx`);
        }
        return null;
    };

    const getExportPr = () => {
        const table = document.getElementById('mytable');
        if (table) {
            const wb = XLSX.utils.table_to_book(table, { sheet: 'Report' });
            Object.keys(wb.Sheets.Report).forEach(val => {
                if (typeof wb.Sheets.Report[val] === 'object') {
                    const split = wb.Sheets.Report[val].v.split('#') || [];
                    if (split.length > 0) {
                        const splitFirst = split[0] === '5' ? split[1] : split[0];
                        const bgHeat = parseInt(splitFirst, 10) > items.length - 12 ? '#FF0000' : '#FFFFFF';
                        const background = parseInt(splitFirst, 10) < 13 ? '#0679C3' : bgHeat;
                        const color = background === '#FFFFFF' ? colors.$black100 : colors.$white;

                        // eslint-disable-next-line prefer-destructuring
                        wb.Sheets.Report[val].v = split[1];
                        let align = 'left';
                        const borderObj = {
                            style: 'thin',
                            color: { rgb: splitFirst === '0' && !isOhi4 ? 'FFD701' : 'FFFFFF' },
                        };
                        if (split.length === 3) {
                            align = 'center';
                        } else {
                            align = splitFirst === 'bold' ? 'left' : 'center';
                        }
                        wb.Sheets.Report[val].s = {
                            font: {
                                color: { rgb: color.replace('#', '') },
                                bold: splitFirst === 'bold',
                                sz: splitFirst === 'bold' ? 14 : 12,
                            },
                            alignment: {
                                vertical: align,
                                horizontal: align,
                            },
                            fill: {
                                patternType: 'solid',
                                fgColor: { rgb: splitFirst === '0' && isOhi4 ? 'FFA800' : background.replace('#', '') },
                            },
                            border: {
                                bottom: borderObj,
                                top: borderObj,
                                left: borderObj,
                                right: borderObj,
                            },
                        };
                    }
                }
            });
            let today = new Date();
            let dd = today.getDate();
            let mm = today.getMonth() + 1;
            const yyyy = today.getFullYear();
            if (dd < 10) {
                dd = `0${dd}`;
            }
            if (mm < 10) {
                mm = `0${mm}`;
            }
            today = `${mm}_${dd}_${yyyy}`;
            return XLSX.writeFile(wb, `demographics_${today}.xlsx`);
        }
        return null;
    };

    useEffect(() => {
        if (isPR) {
            getExportPr();
        } else {
            getExport();
        }
        // eslint-disable-next-line
    }, []);

    const renderLabel = () => {
        const index = demographics.map(item => item.code).indexOf(selectedDemographic);
        const { label = '' } = demographics[index] || -1;
        return index > -1 ? <th key={label}>#{label}</th> : null;
    };

    const renderBenchMarkFilters = () => {
        const { benchmarks: selectedBenchmark = '' } = apiParams;
        if (selectedBenchmark.includes(':')) {
            const splitBenchmark = selectedBenchmark.split(':');
            const firstPart = Number(splitBenchmark[0]);
            const secondPart = Number(splitBenchmark[1]);
            const index = benchmarks.map(item => item.code).indexOf(secondPart);
            const { label = '', options = [] } = benchmarks[index] || -1;
            if (index > -1) {
                const optionsIndex = options.map(item => item.code).indexOf(firstPart);
                const { label: optionsLabel } = options[optionsIndex] || -1;
                return optionsIndex > -1 ? (
                    <td>
                        #{label}:{optionsLabel}
                    </td>
                ) : null;
            }
        }
        return null;
    };

    const renderDemographicFilter = () => {
        const { filters = [] } = apiParams;
        const labelArr = filters.map(demographicFilter => {
            let concatLabel = '';
            if (demographicFilter.includes(':')) {
                const splitDemographic = demographicFilter.split(':');
                const firstPart = splitDemographic[0];
                const index = demographics.map(item => item.code).indexOf(firstPart);
                const { label: demographicLabel = '', options = [] } = demographics[index] || -1;
                if (index > -1) {
                    const subLabels = options.filter(({ isSelected }) => isSelected).map(({ label }) => label);
                    concatLabel = demographicLabel + ' - ' + subLabels.join(',');
                }
            }
            return concatLabel;
        });
        return labelArr.map((label, i) => {
            return <td key={`${label}${i}`}>#{label}</td>;
        });
    };

    const getHeapMapValues = () => {
        return items.map(({ items: subItem = [], title }, index) => (
            <tr key={`${title}${index}${getRandomValues()}`}>
                <td key={title}>bold#{title}</td>
                {subItem &&
                    subItem.map(({ quartile, score }, subItemKey) => {
                        return (
                            <td key={`${score}${subItemKey}${getRandomValues()}`}>
                                {(isPR ? '5' : quartile) + '#' + score}
                            </td>
                        );
                    })}
            </tr>
        ));
    };

    const getInclusionValues = () => {
        const mainKeys = Object.keys(items);
        return mainKeys.map(innerKey => {
            const { records: innerRec = [], display_name: title = '', size } = items[innerKey];
            return (
                <tr key={innerKey}>
                    <td>bold#{title}</td>
                    <td>#{size}</td>
                    {innerRec.map(({ score }, index) => {
                        const lowScore = score < 50 ? 0 : 1;
                        const quartile = score > 75 ? 2 : lowScore;
                        return <td key={`${innerKey}${index}`}>{quartile + '#' + score}</td>;
                    })}
                </tr>
            );
        });
    };

    return (
        <div>
            <div>
                <table id="mytable" className="hideTable">
                    <tbody>
                        <tr>
                            <th>bold#{DEMO_SELECTED_OPTIONS}</th>
                            {renderLabel()}
                        </tr>
                    </tbody>
                    <tbody>
                        <tr>
                            <th>bold#Filters</th>
                        </tr>
                        <tr>
                            <td>bold#Demographic-filters</td>
                            {renderDemographicFilter()}
                        </tr>
                        <tr>
                            <td>bold#{BENCHMARK_FILTERS}</td>
                            {renderBenchMarkFilters()}
                        </tr>
                    </tbody>
                    <tbody>
                        <tr key="blank">
                            <th />
                            {isInclusion && <th>{N_SIZE}</th>}
                            {header.map((val = '', i) => {
                                const headText = val.replace(/<sup>[\d]?<\/sup>/g, '');
                                return <th key={i}>bold#{headText}#center</th>;
                            })}
                        </tr>
                        {isInclusion ? getInclusionValues() : getHeapMapValues()}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

Excel.propTypes = {
    header: PropTypes.array.isRequired,
    items: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
    selectedDemographic: PropTypes.string.isRequired,
    demographics: PropTypes.array.isRequired,
    benchmarks: PropTypes.array.isRequired,
    apiParams: PropTypes.object.isRequired,
    defaultSettings: PropTypes.object.isRequired,
    isInclusion: PropTypes.bool.isRequired,
    isPR: PropTypes.bool.isRequired,
};

export default Excel;
