import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import Diagnose from './Diagnose';
import diagnose from './reducer';
import diagnoseSagas from './sagas';

import {
    getHeatMapOutcome,
    getDemographics,
    getBenchmarks,
    setSelectedDemographic,
    updateFilters,
    setTheme,
    getCohesionData,
    getLocalPractice,
} from './actions';

import {
    selectApiCount,
    selectScoreData,
    selectFilters,
    selectFiltersApplied,
    selectPracticeData,
    selectApiParams,
    selectHeatMapData,
    selectSelectedDemographic,
    selectDemographicCurrentTab,
    practiceDataFetched,
    selectScoreDataFetched,
    selectEnableCreateTheme,
    selectCurrentTheme,
    selectHeatMapDataFetched,
    selectHeatMapPractiseDataFetched,
    selectHeatMapPractiseData,
    selectCohesionScore,
    selectCohesionDataFetched,
    selectProgressBar,
    selectLocalPractice,
    selectLocalPracticeFetched,
    selectTargetBenchmarks,
    selectTargetScoreData,
} from './selector';

import { getDefaultSettings } from '../Login/selector';
import { selectReportApiCount } from '../Reports/selector';

const stateToProps = createStructuredSelector({
    apiLoadingCount: selectApiCount(),
    scoreData: selectScoreData(),
    practiceData: selectPracticeData(),
    filters: selectFilters(),
    activeResetFilter: selectFiltersApplied(),
    heatMapData: selectHeatMapData(),
    apiParams: selectApiParams(),
    selectedDemographic: selectSelectedDemographic(),
    demographicCurrentTab: selectDemographicCurrentTab(),
    practiceDataFetched: practiceDataFetched(),
    scoreDataFetched: selectScoreDataFetched(),
    enableCreateTheme: selectEnableCreateTheme(),
    themeSelected: selectCurrentTheme(),
    defaultSettings: getDefaultSettings(),
    heatMapDataFetched: selectHeatMapDataFetched(),
    heatMapPracticeFetched: selectHeatMapPractiseDataFetched(),
    heatMapPractice: selectHeatMapPractiseData(),
    cohesionScore: selectCohesionScore(),
    cohesionDataFetched: selectCohesionDataFetched(),
    reportApiCount: selectReportApiCount(),
    showProgressBar: selectProgressBar(),
    localPractice: selectLocalPractice(),
    localPracticeFetched: selectLocalPracticeFetched(),
    targetBenchmarks: selectTargetBenchmarks(),
    targetScoreData: selectTargetScoreData(),
});

const dispatchToProps = {
    getDemographics,
    getBenchmarks,
    getHeatMapOutcome,
    setSelectedDemographic,
    updateFilters,
    setTheme,
    getCohesionData,
    getLocalPractice,
};

export { diagnoseSagas, diagnose };

export default connect(stateToProps, dispatchToProps)(Diagnose);
