import React, { Fragment } from 'react';
import { Dialog } from '@material-ui/core';
import PropTypes from 'prop-types';
import { getStaticTextAnalytics } from '../constants';
import DisclaimerWarn from '../assets/DisclaimerWarning1.svg';

const WarningModal = ({ setWarningModal }) => {
    const staticTextAnalytics = getStaticTextAnalytics();
    const { SITE_TEXT } = staticTextAnalytics;
    const { LINK_FOR_BMX_USER, LINK_FOR_BMX_COMMUNITY, ADDITIONAL_DETAILS } = SITE_TEXT;

    const helpMailForBmx = 'OrgScience@Mckinsey.com';

    return (
        <Dialog
            open
            disableEscapeKeyDown
            disableBackdropClick
            maxWidth="md"
            classes={{
                root: 'feedbackSuccessDialogRoot warningDialogRoot',
            }}
        >
            <section className="dataContent dataHead bmxDataInfo">
                <div className="infoLarge bmxInfoWarn">
                    <img src={DisclaimerWarn} alt="" />
                </div>
                <h2 className="bmxDisclaimerHead">Disclaimer!</h2>
                <div className="para">
                    <div>
                        The median health scores shown are protected Firm IP and should not be published in
                        internal/external publications, nor shared with clients.
                    </div>
                    <div className="bmxDialogMainContent">
                        <div className="paragraphHead">key contacts and resources:</div>
                        <ul>
                            <li>
                                Consult the
                                <a href={LINK_FOR_BMX_USER} target="_blank" rel="noreferrer" className="bmxLink">
                                    BMX user guide
                                </a>
                                {ADDITIONAL_DETAILS}
                            </li>
                            <li>
                                Contact{' '}
                                <a href={`mailto:${helpMailForBmx}`} className="bmxLink">
                                    {helpMailForBmx}
                                </a>{' '}
                                with questions related to OHI methodology and interpretation of BMX results.
                            </li>
                            <li>
                                Engage the broader community of OHI experts available at
                                <a
                                    className="arrowIcon bmxLink"
                                    target="_blank"
                                    href={LINK_FOR_BMX_COMMUNITY}
                                    rel="noreferrer"
                                >
                                    #org-health-community
                                </a>
                                for additional support and peer advice on generating insights for clients.
                            </li>
                        </ul>
                    </div>
                </div>
            </section>

            <section className="buttonContent clearfix activeButtons">
                <Fragment>
                    <div
                        data-testid="compareButton"
                        className="compareBtn activeCompareBtn"
                        onClick={() => {
                            setWarningModal(false);
                        }}
                    >
                        Continue
                    </div>
                </Fragment>
            </section>
        </Dialog>
    );
};

WarningModal.propTypes = {
    setWarningModal: PropTypes.func.isRequired,
};

export default WarningModal;
