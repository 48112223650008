import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Paper,
    Checkbox,
    FormControlLabel,
    Button,
} from '@material-ui/core';
import { DATACUBE_REPORT, OP_REPORT } from '../../containers/Reports/constants';
import { getStaticText } from '../../utils/constants';
import './styles.scss';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
        boxShadow: 'none',
        border: 'solid 1px #e6e6e6',
        borderRadius: 10,
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
}));
const sortIcon = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
            <g clipPath="url(#clip0_2105_847)">
                <path
                    d="M12.1683 4.87402L8.00049 1.16942L3.83268 4.87402C3.62628 5.05747 3.31025 5.03888 3.12679 4.8325C2.94333 4.62611 2.96192 4.31009 3.16831 4.12663L7.66831 0.126779C7.85775 -0.0416086 8.14323 -0.0416086 8.33268 0.126779L12.8327 4.12663C13.0391 4.31009 13.0577 4.62611 12.8742 4.8325C12.6907 5.03888 12.3747 5.05747 12.1683 4.87402Z"
                    fill="#333333"
                />
                <path
                    d="M3.12679 11.1679C3.31025 10.9615 3.62628 10.9429 3.83268 11.1264L8.00049 14.831L12.1683 11.1264C12.3747 10.9429 12.6907 10.9615 12.8742 11.1679C13.0577 11.3743 13.0391 11.6903 12.8327 11.8738L8.33268 15.8736C8.14323 16.042 7.85775 16.042 7.66831 15.8736L3.16831 11.8738C2.96192 11.6903 2.94333 11.3743 3.12679 11.1679Z"
                    fill="#333333"
                />
            </g>
            <defs>
                <clipPath id="clip0_2105_847">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};
function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}
function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

function EnhancedTableHead(props) {
    const { classes, headCells, order, orderBy, onRequestSort } = props;
    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow className="reportHeader">
                <TableCell padding="checkbox">
                    {/* <Checkbox
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{ 'aria-label': 'select all desserts' }}
                    /> */}
                </TableCell>
                {headCells.map(headCell => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        width={headCell.width}
                    >
                        {headCell.label}
                        <TableSortLabel
                            style={{ display: 'none' }}
                            className="sortingth"
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                            IconComponent={sortIcon}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    headCells: PropTypes.array.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
};

export default function EnhancedTable({
    headCells = [],
    rows = [],
    checkSelectedLength,
    export_type,
    handleMultipleDwnld,
    removeUnderscore,
    defaultSettings,
    getExport,
    dwnldPerm,
    handlePutReport,
}) {
    const { lang } = defaultSettings;
    const staticText = getStaticText(lang);
    const { SITE_TEXT } = staticText;
    const { DOWNLOAD, RETRY, DOWNLOAD_PAST_REPORT, PROCESSING } = SITE_TEXT || {};
    const classes = useStyles();
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = event => {
        if (event.target.checked) {
            const newSelecteds = rows.map(n => n.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }

        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
                <TableContainer>
                    <Table
                        className={`cst-table ${classes.table}`}
                        aria-labelledby="tableTitle"
                        size="small"
                        aria-label="enhanced table"
                    >
                        <EnhancedTableHead
                            headCells={headCells}
                            classes={classes}
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                        />
                        <TableBody>
                            {/* {stableSort(rows, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    const labelId = `enhanced-table-checkbox-${index}`; */}
                            {stableSort(rows, getComparator(order, orderBy)).map((row, index) => {
                                const labelId = `enhanced-table-checkbox-${index}`;

                                return (
                                    <TableRow
                                        hover
                                        onClick={event => handleClick(event, row.id)}
                                        role="checkbox"
                                        aria-checked={row.isSelected}
                                        tabIndex={-1}
                                        key={row.id}
                                        selected={row.isSelected}
                                    >
                                        <TableCell padding="checkbox">
                                            <FormControlLabel
                                                classes={{
                                                    root: 'optionItem',
                                                }}
                                                control={
                                                    <Checkbox
                                                        icon={
                                                            <svg width="18" height="20" viewBox="0 0 18 20" fill="none">
                                                                <rect
                                                                    x="0.5"
                                                                    y="1.5"
                                                                    width="17"
                                                                    height="17"
                                                                    rx="1.5"
                                                                    stroke="#B3B3B3"
                                                                />
                                                            </svg>
                                                        }
                                                        checkedIcon={
                                                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none">
                                                                <rect width="18" height="18" rx="2" fill="#051C2C" />
                                                                <path
                                                                    d="M5 9.18576L8.03804 11.7571L13 5"
                                                                    stroke="white"
                                                                    strokeWidth="2"
                                                                />
                                                            </svg>
                                                        }
                                                        disabled={
                                                            !row.is_json_created ||
                                                            (checkSelectedLength >= 20 && !row.isSelected) ||
                                                            export_type === DATACUBE_REPORT
                                                        }
                                                        onChange={({ target }) =>
                                                            handleMultipleDwnld(target, page * rowsPerPage + index)
                                                        }
                                                        checked={row.isSelected}
                                                    />
                                                }
                                            />
                                        </TableCell>
                                        <TableCell component="th" id={labelId} scope="row">
                                            {row.export_name}
                                        </TableCell>
                                        <TableCell align="left">{removeUnderscore(row.export_type)}</TableCell>
                                        <TableCell align="left">
                                            {moment(row.created_on).format('DD-MMM-YYYY')}
                                        </TableCell>
                                        <TableCell align="left">
                                            <span className={`${row.status} status`}>
                                                {removeUnderscore(
                                                    row.status === 'raw_ppt_generating' ? PROCESSING : row.status
                                                )}
                                            </span>
                                        </TableCell>
                                        <TableCell align="left" className="action-btn">
                                            {row.is_json_created && (
                                                <Button
                                                    onClick={() =>
                                                        getExport(
                                                            row.id,
                                                            row.export_name,
                                                            row.export_type === DATACUBE_REPORT ||
                                                                row.export_type === OP_REPORT,
                                                            row.artifact
                                                        )
                                                    }
                                                    disabled={!dwnldPerm || checkSelectedLength}
                                                    data-testid="downloadBtn"
                                                    endIcon={
                                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none">
                                                            <path
                                                                d="M5.625 0.375V7.71967L2.51516 4.60984C2.36872 4.46339 2.13128 4.46339 1.98484 4.60984C1.83839 4.75628 1.83839 4.99372 1.98484 5.14016L5.73484 8.89017C5.77079 8.92612 5.81223 8.95325 5.85646 8.97155C5.90067 8.98988 5.94915 9 6 9C6.05085 9 6.09933 8.98988 6.14354 8.97155C6.18777 8.95325 6.22921 8.92612 6.26516 8.89017L10.0152 5.14016C10.1616 4.99372 10.1616 4.75628 10.0152 4.60984C9.86872 4.46339 9.63128 4.46339 9.48483 4.60984L6.375 7.71967V0.375C6.375 0.167893 6.20711 0 6 0C5.79289 0 5.625 0.167893 5.625 0.375Z"
                                                                fill="currentcolor"
                                                            />
                                                            <path
                                                                d="M0.375 11.625C0.375 11.4179 0.542893 11.25 0.75 11.25H11.25C11.4571 11.25 11.625 11.4179 11.625 11.625C11.625 11.8321 11.4571 12 11.25 12H0.75C0.542893 12 0.375 11.8321 0.375 11.625Z"
                                                                fill="currentcolor"
                                                            />
                                                        </svg>
                                                    }
                                                >
                                                    {DOWNLOAD_PAST_REPORT}
                                                </Button>
                                            )}
                                            {row.is_retry_allowed && row.retry_count <= 3 && row.status === 'failed' ? (
                                                <Button
                                                    onClick={() => handlePutReport(row.id, row.report_id)}
                                                    endIcon={
                                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none">
                                                            <path
                                                                d="M9.7508 2.69891C8.23407 1.14961 5.87274 0.666337 3.83016 1.59384C1.45172 2.67385 0.229074 5.33818 0.961253 7.84564C1.69343 10.3531 4.15768 11.9409 6.74358 11.5713C9.32947 11.2017 11.2503 8.98724 11.2508 6.37507C11.2508 6.16796 11.083 6.00004 10.8759 6C10.6688 5.99996 10.5008 6.16782 10.5008 6.37493C10.5004 8.61393 8.85395 10.512 6.63747 10.8288C4.42098 11.1456 2.30877 9.78467 1.68119 7.63542C1.05361 5.48617 2.10159 3.20246 4.14025 2.27673C5.95578 1.45233 8.0651 1.92825 9.3563 3.375H7.1258C6.91869 3.375 6.7508 3.54289 6.7508 3.75C6.7508 3.95711 6.91869 4.125 7.1258 4.125H10.1037C10.1077 4.12506 10.1116 4.12506 10.1156 4.125H10.1258C10.3329 4.125 10.5008 3.95711 10.5008 3.75V0.75C10.5008 0.542893 10.3329 0.375 10.1258 0.375C9.91869 0.375 9.7508 0.542893 9.7508 0.75V2.69891Z"
                                                                fill="currentcolor"
                                                            />
                                                        </svg>
                                                    }
                                                >
                                                    {RETRY}
                                                </Button>
                                            ) : null}
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                            {/* {emptyRows > 0 && (
                                <TableRow>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )} */}
                        </TableBody>
                    </Table>
                </TableContainer>
                {/* <TablePagination
                    rowsPerPageOptions={[10, 20, 30]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    labelRowsPerPage="Results per page"
                    labelDisplayedRows={({ from, to, count }) =>
                        ` ${Math.ceil(from / rowsPerPage)} of  ${Math.ceil(count / rowsPerPage)} ${
                            count > rowsPerPage ? 'pages' : 'page'
                        }`
                    }
                /> */}
            </Paper>
        </div>
    );
}
EnhancedTable.propTypes = {
    headCells: PropTypes.array.isRequired,
    rows: PropTypes.array.isRequired,
    checkSelectedLength: PropTypes.number.isRequired,
    export_type: PropTypes.string.isRequired,
    handleMultipleDwnld: PropTypes.func.isRequired,
    removeUnderscore: PropTypes.func.isRequired,
    getExport: PropTypes.func.isRequired,
    defaultSettings: PropTypes.object.isRequired,
    dwnldPerm: PropTypes.bool.isRequired,
    handlePutReport: PropTypes.func.isRequired,
};
